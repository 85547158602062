import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/loading/loading';
import CustomButton from '../../../components/shared/button/CustomButton';
import CustomModal from '../../../components/shared/dialog-modal/CustomModal';
import { FormInputGroup, Input } from '../../../containers/form/form';
import { LoginOTPFormWrap } from '../../../containers/signIn/stylesSignIn';
import {
  ValidateError,
  ValidateFormWrapper,
  ValidationFormHeader,
} from '../registration-validation/validateRegistrationStyles';

interface VerifyTransactionModalProps {
  show: boolean;
  isProcessing: boolean;
  onClose: () => void;
  onSubmit: (arg0: SubmittingData) => void;
}

interface SubmittingData {
  transactionAmount: { transaction: number };
}

export const VerifyTransactionModal = ({
  show,
  onClose,
  onSubmit,
  isProcessing,
}: VerifyTransactionModalProps) => {
  const { register, handleSubmit, errors } = useForm();
  const [isOpen, setIsOpen] = useState(show);

  const { t } = useTranslation();

  function closeModal() {
    onClose();
  }

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  return (
    <CustomModal
      id="verifyTransactionModal"
      open={isOpen}
      onClose={closeModal}
      maxWidth="xs"
      dialogContent={
        <LoginOTPFormWrap>
          <ValidateFormWrapper
            onSubmit={handleSubmit((data: SubmittingData) => {
              onSubmit(data);
            })}
            style={{ maxWidth: '100%' }}
          >
            <ValidationFormHeader>
              {t('enter_otp_to_link_account', {
                defaultValue:
                  'Enter the OTP Number to link your LB savings account',
              })}
            </ValidationFormHeader>
            <FormInputGroup>
              <Input
                type="text"
                name="transaction"
                placeholder="OTP"
                autoComplete="off"
                ref={register({
                  required: true,
                  pattern: /^\d{4}$/,
                })}
                maxLength={4}
              />
              {errors['transaction'] && (
                <ValidateError>Please enter the transaction OTP.</ValidateError>
              )}
            </FormInputGroup>
          </ValidateFormWrapper>
        </LoginOTPFormWrap>
      }
      dialogActions={
        isProcessing ? (
          <Loading />
        ) : (
          <CustomButton
            label={t('text_verify', { defaultValue: 'Verify' })}
            onClick={handleSubmit((data: SubmittingData) => {
              onSubmit(data);
            })}
          />
        )
      }
    />
  );
};
