import styled from 'styled-components';
import tw from 'twin.macro';

export const HeaderInfoWrapper = styled.div`
  ${tw`flex w-5 h-5 m-0 ml-2`}
  margin-left: 8px !important;
  margin-right: 0 !important;

  &:after {
    ${tw`hidden`}
  }

  img {
    max-width: 100%;
  }
`;
