import {
  RegisterFormInputGroup,
  RegisterFormWrapper,
  StepComponent,
} from '../registerStyles';
import {
  PasswordInput,
  PasswordInputWrap,
} from '../../../containers/signIn/stylesSignIn';
import { HidePassword, ShowPassword } from '../../../components/icons';
import {
  Input,
  LoginFormError,
  SubmitButton,
} from '../../../containers/form/form';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import {
  RegisterFormSection,
  RegistrationStepAction,
  RegSpinnerWrapper,
} from './registrationStrepStyles';
import Loading from '../../../components/loading/loading';
import CustomModal from '../../../components/shared/dialog-modal/CustomModal';
import CustomButton from '../../../components/shared/button/CustomButton';

export const CIMRegistration = (props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [isOpen, setIsOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealConfirmPwd, setRevealConfirmPwd] = useState(false);

  const [isAcceptTnC, setIsAcceptTnC] = useState(false);

  const { register, handleSubmit, errors, getValues, watch, control, trigger } =
    useForm({
      defaultValues: useMemo(() => {
        return props.formData;
      }, [props.formData]),
    });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <StepComponent>
      <RegisterFormWrapper
        autocomplete="off"
        onSubmit={handleSubmit((data) => {
          props.setFormData({ ...props.formData, ...data });
          props.submitData({ ...props.formData, ...data });
        })}
      >
        <p>{t('personal_info')}</p>
        <RegisterFormSection>
          <RegisterFormInputGroup>
            <label>{t('pin')}</label>
            <PasswordInputWrap>
              <PasswordInput
                type={isRevealPwd ? 'text' : 'password'}
                name="password"
                autoComplete="off"
                inputMode="decimal"
                maxLength={6}
                placeholder={t('pin')}
                onChange={async () => {
                  await trigger('repassword');
                  await trigger('password');
                }}
                ref={register({
                  required: 'PIN is required',
                  pattern: /^([0-9]{6})$/m,
                  type: { value: 'number', message: 'Invalid PIN' },
                  maxLength: { value: 6, message: 'Invalid PIN' },
                  validate: (value) =>
                    value.toUpperCase() === getValues().password.toUpperCase(),
                })}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />

              {isRevealPwd ? (
                <HidePassword
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  className="show-hide-icon"
                />
              ) : (
                <ShowPassword
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  className="show-hide-icon"
                />
              )}
            </PasswordInputWrap>
            {errors['password'] && errors.password.type === 'required' && (
              <LoginFormError className="error">
                {' '}
                {t('error_empty_pin_number')}{' '}
              </LoginFormError>
            )}
            {errors['password'] &&
              (errors.password.type === 'pattern' ||
                errors.password.type === 'maxLength') && (
                <LoginFormError className="error">
                  {' '}
                  {t('error_invalid_pin_number')}{' '}
                </LoginFormError>
              )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('confirm_pin')}</label>
            <PasswordInputWrap>
              <PasswordInput
                type={isRevealConfirmPwd ? 'text' : 'password'}
                name="repassword"
                autoComplete="off"
                inputMode="decimal"
                maxLength={6}
                placeholder={t('pin')}
                onChange={async () => {
                  await trigger('repassword');
                  await trigger('password');
                }}
                ref={register({
                  required: 'PIN is required',
                  pattern: /^([0-9]{6})$/m,
                  type: { value: 'number', message: 'Invalid PIN' },
                  maxLength: { value: 6, message: 'Invalid PIN' },
                  validate: (value) =>
                    value.toUpperCase() === getValues().password.toUpperCase(),
                })}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />

              {isRevealConfirmPwd ? (
                <HidePassword
                  onClick={() => setRevealConfirmPwd((prevState) => !prevState)}
                  className="show-hide-icon"
                />
              ) : (
                <ShowPassword
                  onClick={() => setRevealConfirmPwd((prevState) => !prevState)}
                  className="show-hide-icon"
                />
              )}
            </PasswordInputWrap>

            {errors['repassword'] && errors.repassword.type === 'required' && (
              <LoginFormError className="error">
                {' '}
                {t('error_empty_pin_number')}{' '}
              </LoginFormError>
            )}
            {errors['repassword'] &&
              (errors.repassword.type === 'pattern' ||
                errors.repassword.type === 'maxLength') && (
                <LoginFormError className="error">
                  {' '}
                  {t('error_invalid_pin_number')}{' '}
                </LoginFormError>
              )}
            {errors.repassword && errors.repassword.type === 'validate' && (
              <LoginFormError className="error">
                {t('error_confirm_pin_mismatch')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('mothers_maiden_name')}</label>
            <Input
              type="text"
              name="mothers_maiden_name"
              autoComplete="off"
              onChange={async () => {
                await trigger('mothers_maiden_name');
              }}
              ref={register({
                pattern: /^[a-zA-Z]*$/m,
                required: t('error_empty_mothers_maiden_name'),
              })}
            />
            {errors['mothers_maiden_name'] &&
              errors['mothers_maiden_name'].type === 'pattern' && (
                <LoginFormError className="error">
                  {' '}
                  {'Invalid Input'}{' '}
                </LoginFormError>
              )}

            {errors['mothers_maiden_name'] &&
              errors['mothers_maiden_name'].type !== 'pattern' && (
                <LoginFormError className="error">
                  {' '}
                  {t('error_empty_mothers_maiden_name')}{' '}
                </LoginFormError>
              )}
          </RegisterFormInputGroup>
        </RegisterFormSection>
        <RegisterFormSection>
          <RegistrationStepAction
            className={`push-right ${processing && 'processing'}`}
          >
            <p>
              <input
                type="checkbox"
                checked={isAcceptTnC}
                onChange={() => {
                  setIsAcceptTnC(!isAcceptTnC);
                }}
              />
              {t('agree_terms')}
              <span onClick={openModal} className="terms-link">
                {t('terms_and_conditions')}
              </span>
            </p>

            {processing && (
              <RegSpinnerWrapper>
                <Loading />
              </RegSpinnerWrapper>
            )}
            <SubmitButton
              disabled={!isAcceptTnC}
              colorTheme={props.colorTheme}
              type="submit"
              value={t('next')}
            />

            <CustomModal
              id="registerStepThree"
              open={isOpen}
              onClose={closeModal}
              onBackdropClick={closeModal}
              maxWidth="lg"
              dialogContent={
                <div
                  style={{ padding: 'inherit' }}
                  dangerouslySetInnerHTML={{
                    __html:
                      props.terms.body &&
                      props.terms.body
                        .replace(
                          /(<ol )/g,
                          '<ol style="padding-left: inherit" '
                        )
                        .replace(
                          /(<li)/g,
                          '<li style="padding-left: inherit" '
                        ),
                  }}
                />
              }
              dialogActions={
                <CustomButton label="Close" onClick={() => closeModal()} />
              }
            />
          </RegistrationStepAction>
        </RegisterFormSection>
      </RegisterFormWrapper>
    </StepComponent>
  );
};
