import { useMediaQuery } from '@mui/material';
import React, { ReactNode } from 'react';
import Flickity from 'react-flickity-component';
import Menu from '../../../components/Menu/Menu';
import SubHeader from '../../../components/subHeader/subHeader';
import Footer from '../../../containers/footer';
import Header from '../../../containers/header';
import { useUser } from '../../../context/user-context';
import {
  ContentAria,
  ContentWrapper,
  FooterWrapper,
  SideBar,
  SideBarRight,
  SideBarRightWrap,
  Wrapper,
} from './styles';

type Props = {
  children: ReactNode;
  showProfile: boolean;
};

const flickityOptions = {
  initialIndex: 0,
  wrapAround: true,
  cellSelector: '.sidebar-right',
  accessibility: true,
  prevNextButtons: false,
  pageDots: true,
  draggable: true,
  autoPlay: true,
  fade: true,
  static: true,
};
const BANNER_IMAGES = ['Banner_01.png', 'Banner_02.png', 'Banner_03.png'];

const BoilerPlate = ({ children, showProfile = true }: Props) => {
  const { user } = useUser();
  const matches = useMediaQuery('(min-width:768px)');
  return (
    <>
      {!user?.isMobile && <Header />}
      <Wrapper className="PageContentWrapper">
        {!user?.isMobile && <SubHeader showProfile={showProfile} />}
        <ContentWrapper>
          <SideBar>
            <Menu />
          </SideBar>
          <ContentAria>
            {children}

            {!user?.isMobile && matches && (
              <FooterWrapper className="foot">
                <Footer />
              </FooterWrapper>
            )}
          </ContentAria>
          <SideBarRightWrap>
            <Flickity
              className={'carousel'}
              options={flickityOptions}
              reloadOnUpdate
              static={true}
            >
              {BANNER_IMAGES.map((imgSrc, index) => (
                <SideBarRight
                  key={index}
                  //   @ts-ignore
                  imgSrc={imgSrc}
                  className="sidebar-right"
                />
              ))}
            </Flickity>
          </SideBarRightWrap>
        </ContentWrapper>
      </Wrapper>
    </>
  );
};

export default BoilerPlate;
