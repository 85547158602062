import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import tw from 'twin.macro';

export const MenuWrapper = styled.div`
  ${tw`w-full`}

  ${breakpoint('lg')`
    width: 290px;
  `}
`;

export const MenuContainer = styled.ul`
  ${tw``}
`;

export const MenuItem = styled.li`
  border-bottom-color: #af0007;
  ${tw`border-b h-16 lg:border-gray-200 border-solid flex items-center justify-center`}

  ${breakpoint('lg')`
    height: 89px;
  `}

  a {
    ${tw`flex items-center text-white lg:text-gray-700 lg:justify-center lg:border-r-8 w-full h-full lg:text-center text-base font-bold lg:font-normal hover:font-bold lg:hover:bg-white transition-all duration-200 ease-in-out`}

    &.active {
      background: #af0007;
      ${tw`lg:bg-white lg:border-r-8 pl-4 lg:pl-0 border-gray-200 border-solid font-bold`}

      ${breakpoint('lg')`
        border-color: ${(props) => props.theme.colorsBrand.primary};
      `}
    }
  }
`;
