import { X_LANGUAGE } from '../../utils/constants';

const API_URL = process.env.REACT_APP_API_URL;
export const API_VERSION = process.env.REACT_APP_API_VERSION;
export const REFRESH_TOKEN_IN = process.env.REACT_APP_REFRESH_TOKEN;
export const IMG_BASE_URL = process.env.REACT_APP_IMG_BASE_PATH;

export const getImgURL = (imagePath: any) => {
  return `${IMG_BASE_URL}${imagePath}`;
};
export const REFRESH_TOKEN_NAME = 'auth_refresh';

export const DEFAULT_API_CONFIG = {
  url: API_URL,
  timeout: 90000,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    [X_LANGUAGE]: localStorage.getItem('i18nextLng') || 'en',
  },
};
