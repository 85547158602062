export const Marital = [
  {
    id: 36,
    code: 'M',
    description: 'Married',
    data: 1,
  },
  {
    id: 37,
    code: 'S',
    description: 'Single',
    data: 2,
  },
  {
    id: 38,
    code: 'D',
    description: 'Divorced',
    data: 3,
  },
  {
    id: 694,
    code: 'W',
    description: 'Widowed',
    data: 4,
  },
  {
    id: 695,
    code: 'P',
    description: 'Prefer not to say',
    data: 5,
  },
];
