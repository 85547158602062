export const Nationality = [
  {
    id: 1,
    code: 'SL',
    nationality: 'Sri Lankan',
    isActive: 1,
  },
  // {
  //   id: 2,
  //   code: 'AU',
  //   nationality: 'Australian',
  //   isActive: 1,
  // },
  // {
  //   id: 3,
  //   code: 'US',
  //   nationality: 'American',
  //   isActive: 1,
  // },
  // {
  //   id: 196,
  //   code: 'A1',
  //   nationality: 'Afghan',
  //   isActive: 1,
  // },
  // {
  //   id: 197,
  //   code: 'A2',
  //   nationality: 'Albanian',
  //   isActive: 1,
  // },
  // {
  //   id: 198,
  //   code: 'A3',
  //   nationality: 'Algerian',
  //   isActive: 1,
  // },
  // {
  //   id: 199,
  //   code: 'A4',
  //   nationality: 'American',
  //   isActive: 1,
  // },
  // {
  //   id: 200,
  //   code: 'A5',
  //   nationality: 'Andorran',
  //   isActive: 1,
  // },
  // {
  //   id: 201,
  //   code: 'A6',
  //   nationality: 'Angolan',
  //   isActive: 1,
  // },
  // {
  //   id: 202,
  //   code: 'A7',
  //   nationality: 'Antiguans',
  //   isActive: 1,
  // },
  // {
  //   id: 203,
  //   code: 'A8',
  //   nationality: 'Argentinean',
  //   isActive: 1,
  // },
  // {
  //   id: 204,
  //   code: 'A9',
  //   nationality: 'Austrian',
  //   isActive: 1,
  // },
  // {
  //   id: 205,
  //   code: 'B0',
  //   nationality: 'Azerbaijani',
  //   isActive: 1,
  // },
  // {
  //   id: 206,
  //   code: 'B1',
  //   nationality: 'Bahamian',
  //   isActive: 1,
  // },
  // {
  //   id: 207,
  //   code: 'B2',
  //   nationality: 'Bahraini',
  //   isActive: 1,
  // },
  // {
  //   id: 208,
  //   code: 'B3',
  //   nationality: 'Bangladeshi',
  //   isActive: 1,
  // },
  // {
  //   id: 209,
  //   code: 'B4',
  //   nationality: 'Barbadian',
  //   isActive: 1,
  // },
  // {
  //   id: 210,
  //   code: 'B5',
  //   nationality: 'Barbudans',
  //   isActive: 1,
  // },
  // {
  //   id: 211,
  //   code: 'B6',
  //   nationality: 'Batswana',
  //   isActive: 1,
  // },
  // {
  //   id: 212,
  //   code: 'B7',
  //   nationality: 'Belarusian',
  //   isActive: 1,
  // },
  // {
  //   id: 213,
  //   code: 'B8',
  //   nationality: 'Belgian',
  //   isActive: 1,
  // },
  // {
  //   id: 214,
  //   code: 'B9',
  //   nationality: 'Belizean',
  //   isActive: 1,
  // },
  // {
  //   id: 215,
  //   code: 'C0',
  //   nationality: 'Beninese',
  //   isActive: 1,
  // },
  // {
  //   id: 216,
  //   code: 'C1',
  //   nationality: 'Bhutanese',
  //   isActive: 1,
  // },
  // {
  //   id: 217,
  //   code: 'C2',
  //   nationality: 'Bolivian',
  //   isActive: 1,
  // },
  // {
  //   id: 218,
  //   code: 'C3',
  //   nationality: 'Bosnian',
  //   isActive: 1,
  // },
  // {
  //   id: 219,
  //   code: 'C4',
  //   nationality: 'Brazilian',
  //   isActive: 1,
  // },
  // {
  //   id: 220,
  //   code: 'C5',
  //   nationality: 'British',
  //   isActive: 1,
  // },
  // {
  //   id: 221,
  //   code: 'C6',
  //   nationality: 'Bruneian',
  //   isActive: 1,
  // },
  // {
  //   id: 222,
  //   code: 'C7',
  //   nationality: 'Bulgarian',
  //   isActive: 1,
  // },
  // {
  //   id: 223,
  //   code: 'C8',
  //   nationality: 'Burkinabe',
  //   isActive: 1,
  // },
  // {
  //   id: 224,
  //   code: 'C9',
  //   nationality: 'Burmese',
  //   isActive: 1,
  // },
  // {
  //   id: 225,
  //   code: 'D0',
  //   nationality: 'Burundian',
  //   isActive: 1,
  // },
  // {
  //   id: 226,
  //   code: 'D1',
  //   nationality: 'Cambodian',
  //   isActive: 1,
  // },
  // {
  //   id: 227,
  //   code: 'D2',
  //   nationality: 'Cameroonian',
  //   isActive: 1,
  // },
  // {
  //   id: 228,
  //   code: 'D3',
  //   nationality: 'Canadian',
  //   isActive: 1,
  // },
  // {
  //   id: 229,
  //   code: 'D4',
  //   nationality: 'Cape Verdean',
  //   isActive: 1,
  // },
  // {
  //   id: 230,
  //   code: 'D5',
  //   nationality: 'Central African',
  //   isActive: 1,
  // },
  // {
  //   id: 231,
  //   code: 'D6',
  //   nationality: 'Chadian',
  //   isActive: 1,
  // },
  // {
  //   id: 232,
  //   code: 'D7',
  //   nationality: 'Chilean',
  //   isActive: 1,
  // },
  // {
  //   id: 233,
  //   code: 'D8',
  //   nationality: 'Chinese',
  //   isActive: 1,
  // },
  // {
  //   id: 234,
  //   code: 'D9',
  //   nationality: 'Colombian',
  //   isActive: 1,
  // },
  // {
  //   id: 235,
  //   code: 'E0',
  //   nationality: 'Comoran',
  //   isActive: 1,
  // },
  // {
  //   id: 236,
  //   code: 'E1',
  //   nationality: 'Congolese',
  //   isActive: 1,
  // },
  // {
  //   id: 237,
  //   code: 'E2',
  //   nationality: 'Costa Rican',
  //   isActive: 1,
  // },
  // {
  //   id: 238,
  //   code: 'E3',
  //   nationality: 'Croatian',
  //   isActive: 1,
  // },
  // {
  //   id: 239,
  //   code: 'E4',
  //   nationality: 'Cuban',
  //   isActive: 1,
  // },
  // {
  //   id: 240,
  //   code: 'E5',
  //   nationality: 'Cypriot',
  //   isActive: 1,
  // },
  // {
  //   id: 241,
  //   code: 'E6',
  //   nationality: 'Czech',
  //   isActive: 1,
  // },
  // {
  //   id: 242,
  //   code: 'E7',
  //   nationality: 'Danish',
  //   isActive: 1,
  // },
  // {
  //   id: 243,
  //   code: 'E8',
  //   nationality: 'Djibouti',
  //   isActive: 1,
  // },
  // {
  //   id: 244,
  //   code: 'E9',
  //   nationality: 'Dominican',
  //   isActive: 1,
  // },
  // {
  //   id: 245,
  //   code: 'F0',
  //   nationality: 'Dutch',
  //   isActive: 1,
  // },
  // {
  //   id: 246,
  //   code: 'F1',
  //   nationality: 'East Timorese',
  //   isActive: 1,
  // },
  // {
  //   id: 247,
  //   code: 'F2',
  //   nationality: 'Ecuadorean',
  //   isActive: 1,
  // },
  // {
  //   id: 248,
  //   code: 'F3',
  //   nationality: 'Egyptian',
  //   isActive: 1,
  // },
  // {
  //   id: 249,
  //   code: 'F4',
  //   nationality: 'Emirian',
  //   isActive: 1,
  // },
  // {
  //   id: 250,
  //   code: 'F5',
  //   nationality: 'Equatorial Guinean',
  //   isActive: 1,
  // },
  // {
  //   id: 251,
  //   code: 'F6',
  //   nationality: 'Eritrean',
  //   isActive: 1,
  // },
  // {
  //   id: 252,
  //   code: 'F7',
  //   nationality: 'Estonian',
  //   isActive: 1,
  // },
  // {
  //   id: 253,
  //   code: 'F8',
  //   nationality: 'Ethiopian',
  //   isActive: 1,
  // },
  // {
  //   id: 254,
  //   code: 'F9',
  //   nationality: 'Fijian',
  //   isActive: 1,
  // },
  // {
  //   id: 255,
  //   code: 'G0',
  //   nationality: 'Filipino',
  //   isActive: 1,
  // },
  // {
  //   id: 256,
  //   code: 'G1',
  //   nationality: 'Finnish',
  //   isActive: 1,
  // },
  // {
  //   id: 257,
  //   code: 'G2',
  //   nationality: 'French',
  //   isActive: 1,
  // },
  // {
  //   id: 258,
  //   code: 'G3',
  //   nationality: 'Gabonese',
  //   isActive: 1,
  // },
  // {
  //   id: 259,
  //   code: 'G4',
  //   nationality: 'Gambian',
  //   isActive: 1,
  // },
  // {
  //   id: 260,
  //   code: 'G5',
  //   nationality: 'Georgian',
  //   isActive: 1,
  // },
  // {
  //   id: 261,
  //   code: 'G6',
  //   nationality: 'German',
  //   isActive: 1,
  // },
  // {
  //   id: 262,
  //   code: 'G7',
  //   nationality: 'Ghanaian',
  //   isActive: 1,
  // },
  // {
  //   id: 263,
  //   code: 'G8',
  //   nationality: 'Greek',
  //   isActive: 1,
  // },
  // {
  //   id: 264,
  //   code: 'G9',
  //   nationality: 'Grenadian',
  //   isActive: 1,
  // },
  // {
  //   id: 265,
  //   code: 'H0',
  //   nationality: 'Guatemalan',
  //   isActive: 1,
  // },
  // {
  //   id: 266,
  //   code: 'H1',
  //   nationality: 'Guinea-Bissauan',
  //   isActive: 1,
  // },
  // {
  //   id: 267,
  //   code: 'H2',
  //   nationality: 'Guinean',
  //   isActive: 1,
  // },
  // {
  //   id: 268,
  //   code: 'H3',
  //   nationality: 'Guyanese',
  //   isActive: 1,
  // },
  // {
  //   id: 269,
  //   code: 'H4',
  //   nationality: 'Haitian',
  //   isActive: 1,
  // },
  // {
  //   id: 270,
  //   code: 'H5',
  //   nationality: 'Herzegovinian',
  //   isActive: 1,
  // },
  // {
  //   id: 271,
  //   code: 'H6',
  //   nationality: 'Honduran',
  //   isActive: 1,
  // },
  // {
  //   id: 272,
  //   code: 'H7',
  //   nationality: 'Hungarian',
  //   isActive: 1,
  // },
  // {
  //   id: 273,
  //   code: 'H8',
  //   nationality: 'I-Kiribati',
  //   isActive: 1,
  // },
  // {
  //   id: 274,
  //   code: 'H9',
  //   nationality: 'Icelander',
  //   isActive: 1,
  // },
  // {
  //   id: 275,
  //   code: 'I0',
  //   nationality: 'Indian',
  //   isActive: 1,
  // },
  // {
  //   id: 276,
  //   code: 'I1',
  //   nationality: 'Indonesian',
  //   isActive: 1,
  // },
  // {
  //   id: 277,
  //   code: 'I2',
  //   nationality: 'Iranian',
  //   isActive: 1,
  // },
  // {
  //   id: 278,
  //   code: 'I3',
  //   nationality: 'Iraqi',
  //   isActive: 1,
  // },
  // {
  //   id: 279,
  //   code: 'I4',
  //   nationality: 'Irish',
  //   isActive: 1,
  // },
  // {
  //   id: 280,
  //   code: 'I5',
  //   nationality: 'Israeli',
  //   isActive: 1,
  // },
  // {
  //   id: 281,
  //   code: 'I6',
  //   nationality: 'Italian',
  //   isActive: 1,
  // },
  // {
  //   id: 282,
  //   code: 'I7',
  //   nationality: 'Ivorian',
  //   isActive: 1,
  // },
  // {
  //   id: 283,
  //   code: 'I8',
  //   nationality: 'Jamaican',
  //   isActive: 1,
  // },
  // {
  //   id: 284,
  //   code: 'I9',
  //   nationality: 'Japanese',
  //   isActive: 1,
  // },
  // {
  //   id: 285,
  //   code: 'J0',
  //   nationality: 'Jordanian',
  //   isActive: 1,
  // },
  // {
  //   id: 286,
  //   code: 'J1',
  //   nationality: 'Kazakhstani',
  //   isActive: 1,
  // },
  // {
  //   id: 287,
  //   code: 'J2',
  //   nationality: 'Kenyan',
  //   isActive: 1,
  // },
  // {
  //   id: 288,
  //   code: 'J3',
  //   nationality: 'Kittian and Nevisian',
  //   isActive: 1,
  // },
  // {
  //   id: 289,
  //   code: 'J4',
  //   nationality: 'Kuwaiti',
  //   isActive: 1,
  // },
  // {
  //   id: 290,
  //   code: 'J5',
  //   nationality: 'Kyrgyz',
  //   isActive: 1,
  // },
  // {
  //   id: 291,
  //   code: 'J6',
  //   nationality: 'Laotian',
  //   isActive: 1,
  // },
  // {
  //   id: 292,
  //   code: 'J7',
  //   nationality: 'Latvian',
  //   isActive: 1,
  // },
  // {
  //   id: 293,
  //   code: 'J8',
  //   nationality: 'Lebanese',
  //   isActive: 1,
  // },
  // {
  //   id: 294,
  //   code: 'J9',
  //   nationality: 'Liberian',
  //   isActive: 1,
  // },
  // {
  //   id: 295,
  //   code: 'K0',
  //   nationality: 'Libyan',
  //   isActive: 1,
  // },
  // {
  //   id: 296,
  //   code: 'K1',
  //   nationality: 'Liechtensteiner',
  //   isActive: 1,
  // },
  // {
  //   id: 297,
  //   code: 'K2',
  //   nationality: 'Lithuanian',
  //   isActive: 1,
  // },
  // {
  //   id: 298,
  //   code: 'K3',
  //   nationality: 'Luxembourger',
  //   isActive: 1,
  // },
  // {
  //   id: 299,
  //   code: 'K4',
  //   nationality: 'Macedonian',
  //   isActive: 1,
  // },
  // {
  //   id: 300,
  //   code: 'K5',
  //   nationality: 'Malagasy',
  //   isActive: 1,
  // },
  // {
  //   id: 301,
  //   code: 'K6',
  //   nationality: 'Malawian',
  //   isActive: 1,
  // },
  // {
  //   id: 302,
  //   code: 'K7',
  //   nationality: 'Malaysian',
  //   isActive: 1,
  // },
  // {
  //   id: 303,
  //   code: 'K8',
  //   nationality: 'Maldivian',
  //   isActive: 1,
  // },
  // {
  //   id: 304,
  //   code: 'K9',
  //   nationality: 'Malian',
  //   isActive: 1,
  // },
  // {
  //   id: 305,
  //   code: 'L0',
  //   nationality: 'Maltese',
  //   isActive: 1,
  // },
  // {
  //   id: 306,
  //   code: 'L1',
  //   nationality: 'Marshallese',
  //   isActive: 1,
  // },
  // {
  //   id: 307,
  //   code: 'L2',
  //   nationality: 'Mauritanian',
  //   isActive: 1,
  // },
  // {
  //   id: 308,
  //   code: 'L3',
  //   nationality: 'Mauritian',
  //   isActive: 1,
  // },
  // {
  //   id: 309,
  //   code: 'L4',
  //   nationality: 'Mexican',
  //   isActive: 1,
  // },
  // {
  //   id: 310,
  //   code: 'L5',
  //   nationality: 'Micronesian',
  //   isActive: 1,
  // },
  // {
  //   id: 311,
  //   code: 'L6',
  //   nationality: 'Moldovan',
  //   isActive: 1,
  // },
  // {
  //   id: 312,
  //   code: 'L7',
  //   nationality: 'Monacan',
  //   isActive: 1,
  // },
  // {
  //   id: 313,
  //   code: 'L8',
  //   nationality: 'Mongolian',
  //   isActive: 1,
  // },
  // {
  //   id: 314,
  //   code: 'L9',
  //   nationality: 'Moroccan',
  //   isActive: 1,
  // },
  // {
  //   id: 315,
  //   code: 'M0',
  //   nationality: 'Mosotho',
  //   isActive: 1,
  // },
  // {
  //   id: 316,
  //   code: 'M1',
  //   nationality: 'Motswana',
  //   isActive: 1,
  // },
  // {
  //   id: 317,
  //   code: 'M2',
  //   nationality: 'Mozambican',
  //   isActive: 1,
  // },
  // {
  //   id: 318,
  //   code: 'M3',
  //   nationality: 'Namibian',
  //   isActive: 1,
  // },
  // {
  //   id: 319,
  //   code: 'M4',
  //   nationality: 'Nauruan',
  //   isActive: 1,
  // },
  // {
  //   id: 320,
  //   code: 'M5',
  //   nationality: 'Nepalese',
  //   isActive: 1,
  // },
  // {
  //   id: 321,
  //   code: 'M6',
  //   nationality: 'New Zealander',
  //   isActive: 1,
  // },
  // {
  //   id: 322,
  //   code: 'M7',
  //   nationality: 'Ni-Vanuatu',
  //   isActive: 1,
  // },
  // {
  //   id: 323,
  //   code: 'M8',
  //   nationality: 'Nicaraguan',
  //   isActive: 1,
  // },
  // {
  //   id: 324,
  //   code: 'M9',
  //   nationality: 'Nigerian',
  //   isActive: 1,
  // },
  // {
  //   id: 325,
  //   code: 'N0',
  //   nationality: 'Nigerien',
  //   isActive: 1,
  // },
  // {
  //   id: 326,
  //   code: 'N1',
  //   nationality: 'North Korean',
  //   isActive: 1,
  // },
  // {
  //   id: 327,
  //   code: 'N2',
  //   nationality: 'Northern Irish',
  //   isActive: 1,
  // },
  // {
  //   id: 328,
  //   code: 'N3',
  //   nationality: 'Norwegian',
  //   isActive: 1,
  // },
  // {
  //   id: 329,
  //   code: 'N4',
  //   nationality: 'Omani',
  //   isActive: 1,
  // },
  // {
  //   id: 330,
  //   code: 'N5',
  //   nationality: 'Pakistani',
  //   isActive: 1,
  // },
  // {
  //   id: 331,
  //   code: 'N6',
  //   nationality: 'Palauan',
  //   isActive: 1,
  // },
  // {
  //   id: 332,
  //   code: 'N7',
  //   nationality: 'Panamanian',
  //   isActive: 1,
  // },
  // {
  //   id: 333,
  //   code: 'N8',
  //   nationality: 'Papua New Guinean',
  //   isActive: 1,
  // },
  // {
  //   id: 334,
  //   code: 'N9',
  //   nationality: 'Paraguayan',
  //   isActive: 1,
  // },
  // {
  //   id: 335,
  //   code: 'O0',
  //   nationality: 'Peruvian',
  //   isActive: 1,
  // },
  // {
  //   id: 336,
  //   code: 'O1',
  //   nationality: 'Polish',
  //   isActive: 1,
  // },
  // {
  //   id: 337,
  //   code: 'O2',
  //   nationality: 'Portuguese',
  //   isActive: 1,
  // },
  // {
  //   id: 338,
  //   code: 'O3',
  //   nationality: 'Qatari',
  //   isActive: 1,
  // },
  // {
  //   id: 339,
  //   code: 'O4',
  //   nationality: 'Romanian',
  //   isActive: 1,
  // },
  // {
  //   id: 340,
  //   code: 'O5',
  //   nationality: 'Russian',
  //   isActive: 1,
  // },
  // {
  //   id: 341,
  //   code: 'O6',
  //   nationality: 'Rwandan',
  //   isActive: 1,
  // },
  // {
  //   id: 342,
  //   code: 'O7',
  //   nationality: 'Saint Lucian',
  //   isActive: 1,
  // },
  // {
  //   id: 343,
  //   code: 'O8',
  //   nationality: 'Salvadoran',
  //   isActive: 1,
  // },
  // {
  //   id: 344,
  //   code: 'O9',
  //   nationality: 'Samoan',
  //   isActive: 1,
  // },
  // {
  //   id: 345,
  //   code: 'P0',
  //   nationality: 'San Marinese',
  //   isActive: 1,
  // },
  // {
  //   id: 346,
  //   code: 'P1',
  //   nationality: 'Sao Tomean',
  //   isActive: 1,
  // },
  // {
  //   id: 347,
  //   code: 'P2',
  //   nationality: 'Saudi',
  //   isActive: 1,
  // },
  // {
  //   id: 348,
  //   code: 'P3',
  //   nationality: 'Scottish',
  //   isActive: 1,
  // },
  // {
  //   id: 349,
  //   code: 'P4',
  //   nationality: 'Senegalese',
  //   isActive: 1,
  // },
  // {
  //   id: 350,
  //   code: 'P5',
  //   nationality: 'Serbian',
  //   isActive: 1,
  // },
  // {
  //   id: 351,
  //   code: 'P6',
  //   nationality: 'Seychellois',
  //   isActive: 1,
  // },
  // {
  //   id: 352,
  //   code: 'P7',
  //   nationality: 'Sierra Leonean',
  //   isActive: 1,
  // },
  // {
  //   id: 353,
  //   code: 'P8',
  //   nationality: 'Singaporean',
  //   isActive: 1,
  // },
  // {
  //   id: 354,
  //   code: 'P9',
  //   nationality: 'Slovakian',
  //   isActive: 1,
  // },
  // {
  //   id: 355,
  //   code: 'Q0',
  //   nationality: 'Slovenian',
  //   isActive: 1,
  // },
  // {
  //   id: 356,
  //   code: 'Q1',
  //   nationality: 'Solomon Islander',
  //   isActive: 1,
  // },
  // {
  //   id: 357,
  //   code: 'Q2',
  //   nationality: 'Somali',
  //   isActive: 1,
  // },
  // {
  //   id: 358,
  //   code: 'Q3',
  //   nationality: 'South African',
  //   isActive: 1,
  // },
  // {
  //   id: 359,
  //   code: 'Q4',
  //   nationality: 'South Korean',
  //   isActive: 1,
  // },
  // {
  //   id: 360,
  //   code: 'Q5',
  //   nationality: 'Spanish',
  //   isActive: 1,
  // },
  // {
  //   id: 361,
  //   code: 'Q6',
  //   nationality: 'Sudanese',
  //   isActive: 1,
  // },
  // {
  //   id: 362,
  //   code: 'Q7',
  //   nationality: 'Surinamer',
  //   isActive: 1,
  // },
  // {
  //   id: 363,
  //   code: 'Q8',
  //   nationality: 'Swazi',
  //   isActive: 1,
  // },
  // {
  //   id: 364,
  //   code: 'Q9',
  //   nationality: 'Swedish',
  //   isActive: 1,
  // },
  // {
  //   id: 365,
  //   code: 'R0',
  //   nationality: 'Swiss',
  //   isActive: 1,
  // },
  // {
  //   id: 366,
  //   code: 'R1',
  //   nationality: 'Syrian',
  //   isActive: 1,
  // },
  // {
  //   id: 367,
  //   code: 'R2',
  //   nationality: 'Taiwanese',
  //   isActive: 1,
  // },
  // {
  //   id: 368,
  //   code: 'R3',
  //   nationality: 'Tajik',
  //   isActive: 1,
  // },
  // {
  //   id: 369,
  //   code: 'R4',
  //   nationality: 'Tanzanian',
  //   isActive: 1,
  // },
  // {
  //   id: 370,
  //   code: 'R5',
  //   nationality: 'Thai',
  //   isActive: 1,
  // },
  // {
  //   id: 371,
  //   code: 'R6',
  //   nationality: 'Togolese',
  //   isActive: 1,
  // },
  // {
  //   id: 372,
  //   code: 'R7',
  //   nationality: 'Tongan',
  //   isActive: 1,
  // },
  // {
  //   id: 373,
  //   code: 'R8',
  //   nationality: 'Trinidadian or Tobagonian',
  //   isActive: 1,
  // },
  // {
  //   id: 374,
  //   code: 'R9',
  //   nationality: 'Tunisian',
  //   isActive: 1,
  // },
  // {
  //   id: 375,
  //   code: 'S0',
  //   nationality: 'Turkish',
  //   isActive: 1,
  // },
  // {
  //   id: 376,
  //   code: 'S1',
  //   nationality: 'Tuvaluan',
  //   isActive: 1,
  // },
  // {
  //   id: 377,
  //   code: 'S2',
  //   nationality: 'Ugandan',
  //   isActive: 1,
  // },
  // {
  //   id: 378,
  //   code: 'S3',
  //   nationality: 'Ukrainian',
  //   isActive: 1,
  // },
  // {
  //   id: 379,
  //   code: 'S4',
  //   nationality: 'Uruguayan',
  //   isActive: 1,
  // },
  // {
  //   id: 380,
  //   code: 'S5',
  //   nationality: 'Uzbekistani',
  //   isActive: 1,
  // },
  // {
  //   id: 381,
  //   code: 'S6',
  //   nationality: 'Venezuelan',
  //   isActive: 1,
  // },
  // {
  //   id: 382,
  //   code: 'S7',
  //   nationality: 'Vietnamese',
  //   isActive: 1,
  // },
  // {
  //   id: 383,
  //   code: 'S8',
  //   nationality: 'Welsh',
  //   isActive: 1,
  // },
  // {
  //   id: 384,
  //   code: 'S9',
  //   nationality: 'Yemenite',
  //   isActive: 1,
  // },
  // {
  //   id: 385,
  //   code: 'T0',
  //   nationality: 'Zambian',
  //   isActive: 1,
  // },
  // {
  //   id: 386,
  //   code: 'T1',
  //   nationality: 'Zimbabwean',
  //   isActive: 1,
  // },
];
