import * as React from 'react';

export const EllipsisV = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="far"
      data-icon="ellipsis-v"
      viewBox="0 0 128 512"
      className="prefix__svg-inline--fa prefix__fa-ellipsis-v prefix__fa-w-4 prefix__fa-2x"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={props.fill ? props.fill : '#000'}
        d="M64 208a48.01 48.01 0 010 96 48.01 48.01 0 010-96zM16 104a48.01 48.01 0 0096 0 48.01 48.01 0 00-96 0zm0 304a48.01 48.01 0 0096 0 48.01 48.01 0 00-96 0z"
      />
    </svg>
  );
};

export const CheckSolid = (props) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="check"
      className="prefix__svg-inline--fa prefix__fa-check prefix__fa-w-16"
      viewBox="0 0 512 512"
      {...props}
    >
      <path
        fill={props.fill ? props.fill : '#000'}
        d="M173.9 439.4L7.5 273c-10-10-10-26.2 0-36.2l36.2-36.2c10-10 26.2-10 36.2 0L192 312.69l240.1-240.1c10-10 26.2-10 36.2 0l36.2 36.21c10 10 10 26.2 0 36.2L210.1 439.4c-10 10-26.2 10-36.2 0z"
      />
    </svg>
  );
};

export const HidePassword = (props) => {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 7C9.934 7 4.798 10.776 3 16c1.798 5.224 6.934 9 13 9s11.202-3.776 13-9c-1.798-5.224-6.934-9-13-9z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <circle
        cx={16}
        cy={16}
        fill="none"
        r={5}
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
        d="M3 3l26 26"
      />
    </svg>
  );
};

export const ShowPassword = (props) => {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16 7C9.934 7 4.798 10.776 3 16c1.798 5.224 6.934 9 13 9s11.202-3.776 13-9c-1.798-5.224-6.934-9-13-9z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <circle
        cx={16}
        cy={16}
        fill="none"
        r={5}
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
    </svg>
  );
};

// TODO: Update the assets with proper assets
export const CameraIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="svg-inline--fa fa-camera fa-w-16"
      viewBox="0 0 512 512"
    >
      <path
        fill="currentColor"
        d="M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z"
      ></path>
    </svg>
  );
};

export const NICFront = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className="svg-inline--fa fa-mobile-alt fa-w-10"
      viewBox="0 0 320 512"
    >
      <path
        fill="currentColor"
        d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm112-108c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"
      ></path>
    </svg>
  );
};

export const BackButton = (props) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="arrow-circle-left"
      className="svg-inline--fa fa-arrow-circle-left fa-w-16"
      role="img"
      width={props.width}
      height={props.height}
      viewBox="0 0 512 512"
    >
      <path
        fill={props.fill ? props.fill : '#000'}
        d="M256 504C119 504 8 393 8 256S119 8 256 8s248 111 248 248-111 248-248 248zm28.9-143.6L209.4 288H392c13.3 0 24-10.7 24-24v-16c0-13.3-10.7-24-24-24H209.4l75.5-72.4c9.7-9.3 9.9-24.8.4-34.3l-11-10.9c-9.4-9.4-24.6-9.4-33.9 0L107.7 239c-9.4 9.4-9.4 24.6 0 33.9l132.7 132.7c9.4 9.4 24.6 9.4 33.9 0l11-10.9c9.5-9.5 9.3-25-.4-34.3z"
      ></path>
    </svg>
  );
};
