export const Titles = [
  {
    id: 1,
    title: 'Mr. ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 2,
    title: 'Mrs. ',
    gender: 'F',
    isActive: true,
  },
  {
    id: 3,
    title: 'Dr. ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 4,
    title: 'Prof. ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 5,
    title: '..',
    gender: 'M',
    isActive: true,
  },
  {
    id: 6,
    title: 'Ms. ',
    gender: 'F',
    isActive: true,
  },
  {
    id: 7,
    title: 'Miss. ',
    gender: 'F',
    isActive: true,
  },
  {
    id: 8,
    title: 'Master. ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 9,
    title: 'Comm. ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 10,
    title: 'Brig. ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 11,
    title: 'Major. ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 12,
    title: 'Sister. ',
    gender: 'F',
    isActive: true,
  },
  {
    id: 13,
    title: 'DR.Mrs. ',
    gender: 'F',
    isActive: true,
  },
  {
    id: 14,
    title: 'DR.Miss. ',
    gender: 'F',
    isActive: true,
  },
  {
    id: 15,
    title: 'Ven. ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 16,
    title: 'REV.Fr ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 17,
    title: 'LT.Col ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 18,
    title: 'Col ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 19,
    title: 'Messes. ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 20,
    title: 'Capt. ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 21,
    title: 'LT.Cdr. ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 23,
    title: '.',
    gender: 'M',
    isActive: true,
  },
  {
    id: 24,
    title: 'MAJ.GEN ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 25,
    title: 'REV.BR ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 26,
    title: 'REV.Dr ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 27,
    title: 'REV.Sis ',
    gender: 'F',
    isActive: true,
  },
  {
    id: 28,
    title: 'REV ',
    gender: 'M',
    isActive: true,
  },
  {
    id: 29,
    title: 'General',
    gender: 'M',
    isActive: true,
  },
];
