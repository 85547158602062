export const SourceFunds = [
  {
    id: 861,
    code: 'FR',
    name: 'Family Remittances',
    data: 1,
  },
  {
    id: 862,
    code: 'IP',
    name: 'Investment Proceeds',
    data: 2,
  },
  {
    id: 863,
    code: 'DO',
    name: 'Donations',
    data: 3,
  },
  {
    id: 864,
    code: 'SA',
    name: 'Sale of property/assets',
    data: 4,
  },
  {
    id: 865,
    code: 'GF',
    name: 'Gift',
    data: 5,
  },
  {
    id: 866,
    code: 'BI',
    name: 'Business Income',
    data: 6,
  },
  {
    id: 867,
    code: 'SP',
    name: 'Salary/ Profit Income',
    data: 7,
  },
  {
    id: 868,
    code: 'OT',
    name: 'Other',
    data: 8,
  },
];
