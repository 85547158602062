import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { readAndCompressImage } from 'browser-image-resizer';
import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useMemo, useState } from 'react';
import { FilePicker } from 'react-file-picker';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import {
  CameraIcon,
  HidePassword,
  NICFront,
  ShowPassword,
} from '../../../components/icons';
import {
  Input,
  LoginFormError,
  SubmitButton,
} from '../../../containers/form/form';
import {
  PasswordInput,
  PasswordInputWrap,
} from '../../../containers/signIn/stylesSignIn';
import { Deposits, MonthlyIncome, SourceFunds } from '../../../dataset';
import { getBase64, getFileExtensionFromBase64 } from '../../../utils/helpers';
import {
  RegisterFormInputGroup,
  RegisterFormWrapper,
  RegistrationDropDown,
  StepComponent,
} from '../registerStyles';
import {
  CapturePhoto,
  RegisterFormSection,
  RegistrationStepAction,
} from './registrationStrepStyles';

export const RegisterStepTwo = ({
  formData,
  setFormData,
  setStep,
  userType,
  colorTheme,
  merchantCategories,
}) => {
  const AVAILABLE_FILE_TYPES = ['jpg', 'jpeg', 'png', 'pdf', 'HEIC'];
  const MAX_FILE_SIZE = 10000; // 10MB
  const FILE_RESIZE_WIDTH = 500;
  const FILE_RESIZE_HEIGHT = 500;

  const { t } = useTranslation();
  const { addToast } = useToasts();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealConfirmPwd, setRevealConfirmPwd] = useState(false);
  const purposeList = [
    { name: 'Savings', id: 1 },
    { name: 'Utility Bill Payment', id: 2 },
    { name: 'Investment Purpose', id: 3 },
    { name: 'Business Transactions', id: 4 },
    { name: 'Share Transactions', id: 5 },
    { name: 'Employment/ Professional Income', id: 6 },
    { name: 'Family Remittance', id: 7 },
    { name: 'Other', id: 9 },
  ];

  const [selfieData, setSelfie] = useState({
    data: formData.selfie || null,
    fileType: '',
  });
  const [idFront, setIdFront] = useState({
    data: formData.nic_front_image || null,
    fileType: '',
  });
  const [idBack, setIdBack] = useState({
    data: formData.nic_back_image || null,
    fileType: '',
  });

  const [lastWatch, setLastWatch] = useState({});

  const [selectedPurpose, setSelectedPurpose] = useState(
    formData?.selectedPurpose || [{ name: 'Savings', id: 1 }]
  );
  const [purpose, setPurpose] = useState(1);

  const [source_of_funds, setSourceofFunds] = useState(7);
  const [selectedSOF, setSelecteSOF] = useState(
    formData?.selectedSOF || [SourceFunds[6]]
  );

  const { register, handleSubmit, errors, getValues, watch, control, trigger } =
    useForm({
      defaultValues: useMemo(() => {
        return formData;
      }, [formData]),
    });

  //source_of_funds
  const handleSOF = async (data) => {
    setSelecteSOF(data);
    let pvalue = null;
    data.forEach((value) => {
      if (pvalue) {
        pvalue += ',' + value.data;
      } else {
        pvalue += value.data;
      }
    });
    setSourceofFunds(pvalue);
  };

  const handlePurpose = async (data) => {
    setSelectedPurpose(data);
    let pvalue = null;
    data.forEach((value) => {
      if (pvalue) {
        pvalue += ',' + value.id;
      } else {
        pvalue += value.id;
      }
    });
    setPurpose(pvalue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const generateMobile = (mobileNum) => {
    if (mobileNum && mobileNum.length === 10) {
      return `+94${mobileNum.substring(1)}`;
    } else {
      return mobileNum;
    }
  };

  const handleFileUpload = async (file, callback) => {
    if (file.type === 'application/pdf') {
      const base64String = await getBase64(file);
      const data = base64String.replace('data:application/pdf;base64,', '');
      callback({ data, fileType: 'pdf' });
    } else {
      // Resizing
      let resizedImage = await readAndCompressImage(file, {
        quality: 0.7,
        width: FILE_RESIZE_WIDTH,
        height: FILE_RESIZE_HEIGHT,
      });
      const base64String = await getBase64(resizedImage);
      const data = base64String.replace(/^data:image\/[a-z]+;base64,/, '');
      const fileType = getFileExtensionFromBase64(
        base64String,
        AVAILABLE_FILE_TYPES
      );
      callback({ data, fileType });
    }
  };

  useEffect(() => {
    const data = { ...watch() };
    data.purpose = purpose;
    data.selectedPurpose = selectedPurpose;
    data.selectedSOF = selectedSOF;
    data.source_of_funds = source_of_funds;
    data.nic_back_image = idBack.data;
    data.nic_back_image_type = idBack.fileType;
    data.nic_front_image = idFront.data;
    data.nic_front_image_type = idFront.fileType;
    data.selfie = selfieData.data;
    data.selfie_image_type = selfieData.fileType;
    if (JSON.stringify(lastWatch) !== JSON.stringify(data)) {
      setLastWatch({ ...data });
      setFormData({
        ...formData,
        ...data,
        mobile_no: generateMobile(formData.mobile_no),
      });
    }
  }, [
    formData,
    idBack,
    idFront,
    lastWatch,
    purpose,
    selectedPurpose,
    selectedSOF,
    selfieData,
    setFormData,
    source_of_funds,
    watch,
  ]);
  const [currentSelected, setCurrentSelected] = useState();
  return (
    <StepComponent>
      <RegisterFormWrapper
        onSubmit={handleSubmit((data) => {
          data.purpose = purpose;
          data.selectedPurpose = selectedPurpose;
          data.selectedSOF = selectedSOF;
          data.source_of_funds = source_of_funds;
          data.nic_back_image = idBack.data;
          data.nic_back_image_type = idBack.fileType;
          data.nic_front_image = idFront.data;
          data.nic_front_image_type = idFront.fileType;
          data.selfie = selfieData.data;
          data.selfie_image_type = selfieData.fileType;

          if (!data.nic_back_image) {
            addToast('Please upload a NIC back image', {
              appearance: 'error',
              autoDismiss: true,
            });
          }

          if (!data.nic_front_image) {
            addToast('Please upload a NIC front image', {
              appearance: 'error',
              autoDismiss: true,
            });
          }

          if (!data.selfie) {
            addToast('Please upload a selfie image', {
              appearance: 'error',
              autoDismiss: true,
            });
          }

          /*validate NIC*/
          if (!data.nic_front_image || !data.nic_back_image || !data.selfie)
            return;

          setFormData({ ...formData, ...data });
          setStep(2);
        })}
      >
        <p>{t('personal_info')}</p>
        <RegisterFormSection>
          <RegisterFormInputGroup className="image-capture">
            <FilePicker
              name="selfie"
              register={register({
                required: t('error_empty_profile_image'),
              })}
              extensions={AVAILABLE_FILE_TYPES}
              maxSize={MAX_FILE_SIZE}
              onChange={(file) => handleFileUpload(file, setSelfie)}
              onError={(error) => {
                addToast(error, {
                  appearance: 'error',
                  autoDismiss: true,
                });
                setSelfie({ data: '', fileType: '' });
              }}
            >
              <CapturePhoto>
                <label style={{ fontSize: '13px' }}>
                  {t('capture_your_selfie')}*
                </label>
                <CameraIcon width="20px" height="20px" />
                {selfieData && selfieData.data && (
                  <CheckCircleIcon color="success" fontSize="small" />
                )}
              </CapturePhoto>
            </FilePicker>
            {errors['selfie'] && (
              <LoginFormError className="error">
                {t('error_empty_profile_image')}{' '}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup className="nic-capture">
            <FilePicker
              name="nic_front_image"
              extensions={AVAILABLE_FILE_TYPES}
              maxSize={MAX_FILE_SIZE}
              onChange={(file) => handleFileUpload(file, setIdFront)}
              onError={(error) => {
                addToast(error, {
                  appearance: 'error',
                  autoDismiss: true,
                });
                setIdFront({ data: '', fileType: '' });
              }}
            >
              <CapturePhoto className="nic-front" onClick={() => {}}>
                <label style={{ fontSize: '13px' }}>{t('nic1')}* </label>
                <NICFront width="20px" height="20px" />
                {idFront && idFront.data && (
                  <CheckCircleIcon color="success" fontSize="small" />
                )}
              </CapturePhoto>
            </FilePicker>

            <FilePicker
              className="nic-back"
              name="nic_back_image"
              extensions={AVAILABLE_FILE_TYPES}
              maxSize={MAX_FILE_SIZE}
              onChange={(file) => handleFileUpload(file, setIdBack)}
              onError={(error) => {
                addToast(error, {
                  appearance: 'error',
                  autoDismiss: true,
                });
                setIdBack({ data: '', fileType: '' });
              }}
            >
              <CapturePhoto className="nic-back" onClick={() => {}}>
                <label style={{ fontSize: '13px' }}>{t('nic2')}*</label>
                <NICFront width="20px" height="20px" />
                {idBack && idBack.data && (
                  <CheckCircleIcon color="success" fontSize="small" />
                )}
              </CapturePhoto>
            </FilePicker>
            {(errors['nic_front_image'] || errors['nic_back_image']) && (
              <LoginFormError className="error">
                {t('error_empty_nic_image')}{' '}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
        </RegisterFormSection>
        <p>{t('financial_info')}</p>
        <RegisterFormSection>
          <RegisterFormInputGroup colorTheme={colorTheme}>
            <label>{t('purpose')}</label>
            <Controller
              name="purpose"
              control={control}
              register={register({
                required: t('error_empty_nic_image'),
              })}
              setValue={selectedPurpose || formData.selectedPurpose}
              as={
                <Multiselect
                  options={purposeList} // Options to display in the dropdown
                  selectedValues={
                    formData.purpose
                      ? formData.selectedPurpose
                      : [purposeList[0]]
                  } // Preselected value to persist in dropdown
                  onSelect={handlePurpose} // Function will trigger on select event
                  onRemove={handlePurpose} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  style={{
                    chips: {
                      background:
                        parseInt(userType) === 19 ||
                        parseInt(userType) === 20 ||
                        parseInt(userType) === 21
                          ? '#3db5e7'
                          : '#E62C7B',
                    },
                  }}
                />
              }
            />
            {errors['purpose'] && (
              <LoginFormError className="error">
                {t('error_empty_purpose')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('monthly_turnover')}</label>
            <RegistrationDropDown
              name="exp_turn_over"
              ref={register({
                required: t('error_empty_monthly_turnover'),
              })}
              onChange={(event) => setCurrentSelected(event.target.value)}
              value={currentSelected}
            >
              {/* <option value="" /> */}
              {Deposits.map((deposit) =>
                parseInt(deposit.id) === 1 ? (
                  <option key={deposit.id} value={deposit.code}>
                    {deposit.description}
                  </option>
                ) : (
                  <option key={deposit.id} value={deposit.code}>
                    {deposit.description}
                  </option>
                )
              )}
            </RegistrationDropDown>
            {errors['monthly_turnover'] && (
              <LoginFormError className="error">
                {t('error_empty_monthly_turnover')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup colorTheme={colorTheme}>
            <label>{t('source_of_funds')}</label>

            <Controller
              name="source_of_funds"
              control={control}
              register={register({
                required: t('error_empty_source_of_fund'),
              })}
              setValue={selectedSOF || formData.source_of_funds}
              as={
                <Multiselect
                  options={SourceFunds} // Options to display in the dropdown
                  selectedValues={
                    formData.source_of_funds
                      ? formData.selectedSOF
                      : [SourceFunds[6]]
                  }
                  //selectedValues={[SourceFunds[6]]} // Preselected value to persist in dropdown
                  onSelect={handleSOF} // Function will trigger on select event
                  onRemove={handleSOF} // Function will trigger on remove event
                  displayValue="name" // Property name to display in the dropdown options
                  style={{
                    chips: {
                      background:
                        parseInt(userType) === 19 ||
                        parseInt(userType) === 20 ||
                        parseInt(userType) === 21
                          ? '#3db5e7'
                          : '#E62C7B',
                    },
                  }}
                />
              }
            />
            {errors['source_of_funds'] && (
              <LoginFormError className="error">
                {t('error_empty_source_of_fund')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('monthly_income')}</label>
            <RegistrationDropDown
              name="monthly_income"
              ref={register({
                required: t('error_empty_monthly_income'),
              })}
            >
              <option value="" />
              {MonthlyIncome.map((income) => (
                <option key={income.code} value={income.id}>
                  {income.description}
                </option>
              ))}
            </RegistrationDropDown>
            {errors['monthly_income'] && (
              <LoginFormError className="error">
                {t('error_empty_monthly_income')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegistrationStepAction className="push-right">
            <SubmitButton
              colorTheme={colorTheme}
              type="submit"
              value={t('next')}
            />
          </RegistrationStepAction>
        </RegisterFormSection>
      </RegisterFormWrapper>
    </StepComponent>
  );
};
