export const setThousandSeparator = (value: any) => {
  if (!value) {
    return '0.00';
  }
  value = String(value).replace(/,/g, '');
  let result = value;
  try {
    if (value > 0) {
      result = (Math.round(value * 100) / 100).toFixed(2);
      result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      result = '0.00';
    }
  } catch (error) {}
  return result;
};

export const setThousandSeparatorWithoutDecimal = (value: any) => {
  if (!value) {
    return '0.00';
  }
  value = String(value).replace(/,/g, '');
  let result = value;
  try {
    if (value > 0) {
      result = (Math.round(value * 100) / 100);
      result = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      result = '0.00';
    }
  } catch (error) {}
  return result;
};

export const setDotAndSpaceToString = (value: string) => {
  return value.split(' ').join('.');
};

export const addZeroes = (num: any) => {
  let value = num;
  try {
    if (typeof value === 'number') {
      value = Number(value);
      const res = num.split('.');
      if (res.length === 1 || res[1].length < 3) {
        value = value.toFixed(2);
      }
    } else {
      value = num.replace(/,/g, '');
      value = parseFloat(value).toFixed(2);
    }
  } catch (error) {}
  return value;
};

export const formatMoney = (amount: any) => {
  return parseFloat(amount).toFixed(2) + ' LKR';
};

export const maskAccount = (n: any) => {
  const masked_account = String(n).replace(
    /\b[\dX][-. \dX]+(\d{4})\b/g,
    function (match, capture) {
      return (
        Array(12)
          .join('*')
          .split('')
          .map((digit, index) => {
            return index % 4 === 3 ? 'X ' : 'X';
          })
          .join('')
          .trim() +
        ' ' +
        capture
      );
    }
  );
  return masked_account;
};

export const generateMobileNumberForBackend = (mobileNumber: string) => {
  if (!mobileNumber) return '';

  const regex = new RegExp(/^\+947\d{8}$/m);
  if (regex.test(mobileNumber)) {
    return mobileNumber;
  } else if (mobileNumber.startsWith('0') && mobileNumber.length === 10) {
    return `+94${mobileNumber.substring(1)}`;
  } else {
    return '';
  }
};

export const generateMobileNumberForFrontend = (mobileNumber: string) => {
  if (!mobileNumber) return '';

  const regex = new RegExp(/^\+947\d{8}$/m);
  if (regex.test(mobileNumber)) {
    return `0${mobileNumber.substring(3)}`;
  } else if (mobileNumber.startsWith('0') && mobileNumber.length === 10) {
    return mobileNumber;
  } else {
    return '';
  }
};

export const getFileExtensionFromBase64 = (
  base64Data: string,
  availableFileTypes: string[]
) => {
  if (!base64Data) return '';

  const fileType = base64Data.split(';')[0].split('/')[1];

  if (availableFileTypes) {
    return availableFileTypes.includes(fileType) ? fileType : '';
  } else {
    return fileType;
  }
};

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (loadedFIle: any) => resolve(loadedFIle.target.result);
    reader.onerror = (error) => reject(error);
  });
};
