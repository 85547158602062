import React, { useCallback, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../components/loading/loading';
import CustomRadio from '../../../components/shared/radio/CustomRadio';
import {
  Input,
  LoginFormError,
  SubmitButton,
  Text,
} from '../../../containers/form/form';
import { DristrictList, Nationality, Titles } from '../../../dataset';
import RegistrationApiService from '../../../services/api/registration-api';
import {
  RadioContainer,
  RegisterFormInputGroup,
  RegisterFormWrapper,
  RegistrationDropDown,
  StepComponent,
} from '../registerStyles';
import {
  RegisterFormSection,
  RegistrationStepAction,
} from './registrationStrepStyles';

export const RegisterStepOne = (props) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();
  const firstTime = useRef(true);

  const { formData, setFormData, setStep, registrationType, userType } = props;

  const [district, setDistrict] = useState();
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [isResidential, setIsResidential] = useState(true);

  const [residentialDistrict, setResidentialDistrict] = useState();
  const [isResidentialCitiesLoading, setIsResidentialCitiesLoading] =
    useState(false);
  const [residentialCities, setResidentialCities] = useState();

  const [currentLat, setCurrentLat] = useState(0);
  const [currentLng, setCurrentLng] = useState(0);
  const [isLocationErrorShown, setIsLocationErrorShown] = useState(false);

  let maximumDate = new Date();
  maximumDate = new Date(
    maximumDate.getFullYear() -
      18 +
      '-' +
      (maximumDate.getMonth() + 1) +
      '-' +
      maximumDate.getDate()
  );

  const reConvertMobile = (mobileNum) => {
    if (mobileNum && mobileNum.length === 12) {
      return `0${mobileNum.slice(3)}`;
    } else {
      return mobileNum;
    }
  };

  const { register, handleSubmit, errors, reset, control } = useForm({
    mode: 'all',
    reValidateMode: 'all',
  });

  const retrieveCities = useCallback(
    async (districtId) => {
      let response;

      try {
        response = await RegistrationApiService.getCitiesForDistrict(
          districtId
        );

        if (response.kind === 'ok') {
          setIsCitiesLoading(false);
          setIsResidentialCitiesLoading(false);
          return response.data.cities;
        } else if (response.kind === 'rejected') {
          setIsCitiesLoading(false);
          setIsResidentialCitiesLoading(false);
          addToast(response.data.data.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          return [];
        }
      } catch (error) {
        setIsCitiesLoading(false);
        setIsResidentialCitiesLoading(false);
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
    [addToast]
  );

  /**
   * Util function to add +94 to any mobile number.
   *
   * @param {string} mobileNum mobile number string
   * @returns generated mobile number
   */
  const generateMobile = (mobileNum) => {
    if (mobileNum && mobileNum.length === 10) {
      return `+94${mobileNum.substring(1)}`;
    } else {
      return mobileNum;
    }
  };

  const formatDob = (dob) => {
    dob = new Date(dob);
    if (dob) {
      return (
        dob.getFullYear() +
        '-' +
        (dob.getMonth() + 1 < 10
          ? '0' + (dob.getMonth() + 1)
          : dob.getMonth() + 1) +
        '-' +
        (dob.getDate() < 10 ? '0' + dob.getDate() : dob.getDate())
      );
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentLat(position.coords.latitude);
        setCurrentLng(position.coords.longitude);
      },
      (error) => {
        if (!isLocationErrorShown) {
          setIsLocationErrorShown(true);
        }
      }
    );
  }, [addToast, isLocationErrorShown]);

  useEffect(() => {
    var isMounted = true;
    const setDefaultData = async () => {
      const tempForm = { ...formData };
      if (tempForm?.livingCity) {
        if (isMounted) {
          setResidentialDistrict(tempForm.living_district);
        }

        const res = await retrieveCities(tempForm.living_district);
        if (isMounted) {
          setResidentialCities(res);
          setIsResidential(false);
        }
      }
      if (tempForm?.mobile_no) {
        tempForm.mobile_no = reConvertMobile(formData.mobile_no);
      }
      if (tempForm?.district) {
        const res = await retrieveCities(tempForm.district);
        setCities(res);
        // if (isMounted) {
        //   setCities(res);
        // }
      }
      setFormData(tempForm);
      reset(tempForm);
    };

    if (firstTime.current && Object.keys(formData).length > 0) {
      firstTime.current = false;
      setDefaultData();
    }
    return () => {
      isMounted = false;
    };
  }, [formData, props.formData, reset, retrieveCities, setFormData]);

  /**
   * fetch cities for district
   */
  useEffect(() => {
    if (!district) return;
    const getCities = async () => {
      return await retrieveCities(district);
    };
    getCities().then((response) => {
      console.log(response);
      setCities(response);
    });
  }, [district, retrieveCities]);

  /**
   * Fetch residential cities
   */
  useEffect(() => {
    if (!residentialDistrict) return;
    const getCities = async () => {
      return await retrieveCities(residentialDistrict);
    };
    getCities().then((response) => {
      setResidentialCities(response);
    });
  }, [residentialDistrict, retrieveCities]);

  return (
    <StepComponent>
      <RegisterFormWrapper
        autocomplete="off"
        onSubmit={handleSubmit((data) => {
          const temp = { ...data };
          if (
            parseInt(userType) === 19 ||
            parseInt(userType) === 20 ||
            parseInt(userType) === 21
          ) {
            temp.lat = currentLat;
            temp.lng = currentLng;
          }
          temp.mobile_no = generateMobile(temp?.mobile_no);
          temp.dob = formatDob(temp.dob);
          if (
            parseInt(registrationType) === 15 ||
            parseInt(registrationType) === 21
          ) {
            Object.keys(temp).forEach((value) =>
              temp[value] === undefined ? delete temp[value] : {}
            );
            formData.streetRoad = `${formData.streetRoad}`.replace(/,/g, '%');
          } else {
            temp.streetRoad = `${temp.streetRoad}`.replace(/,/g, '%');
          }
          setFormData({ ...formData, ...temp });

          setStep(1);
        })}
      >
        <p>Personal Information</p>
        <RegisterFormSection>
          <RegisterFormInputGroup>
            <label>{t('title')}</label>

            <RegistrationDropDown
              name="title"
              ref={register({
                required: true,
              })}
            >
              <option value="" />
              {Titles.map((titleObj) => (
                <option key={titleObj.id} value={titleObj.id}>
                  {titleObj.title}
                </option>
              ))}
            </RegistrationDropDown>
            {errors.title && errors.title.type === 'required' && (
              <LoginFormError className="error">
                {t('error_empty_title')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('full_name')}</label>
            <Input
              type="text"
              name="full_name"
              disabled={
                parseInt(registrationType) === 15 ||
                parseInt(registrationType) === 21
              }
              autoComplete="off"
              ref={register({
                required: true,
                minLength: 5,
                maxLength: 100,
                pattern: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/m,
              })}
            />
            {errors.full_name && errors.full_name.type === 'required' && (
              <LoginFormError className="error">
                {t('error_fullname_empty')}
              </LoginFormError>
            )}
            {errors.full_name && errors.full_name.type === 'minLength' && (
              <LoginFormError className="error">
                {t('error_fullname_minLength')}
              </LoginFormError>
            )}
            {errors.full_name && errors.full_name.type === 'maxLength' && (
              <LoginFormError className="error">
                {t('error_fullname_maxLength')}
              </LoginFormError>
            )}
            {errors.full_name && errors.full_name.type === 'pattern' && (
              <LoginFormError className="error">
                {t('error_fullname_invalidChar')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('nick_name')}</label>
            <Input
              type="text"
              name="nick_name"
              autoComplete="off"
              ref={register({
                minLength: 2,
                maxLength: 20,
              })}
            />
            {errors.nick_name && errors.nick_name.type === 'minLength' && (
              <LoginFormError className="error">
                {t('error_nick_name_minLength')}
              </LoginFormError>
            )}
            {errors.nick_name && errors.nick_name.type === 'maxLength' && (
              <LoginFormError className="error">
                {t('error_nick_name_maxLength')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('dob')}</label>
            <Controller
              name={'dob'}
              control={control}
              defaultValue={null}
              rules={{
                required: true,
                validate: (value) => {
                  value = new Date(value);
                  const year = value.getFullYear();
                  const month = value.getMonth();
                  const day = value.getDate();

                  return new Date(year + 18, month, day) <= new Date();
                },
              }}
              render={({ onChange, value }) => {
                return (
                  <DatePicker
                    openToDate={new Date('2003-01-01')}
                    yearDropdownItemNumber={30}
                    maxDate={maximumDate}
                    dropdownMode={'scroll'}
                    showYearDropdown={true}
                    showMonthDropdown={true}
                    scrollableYearDropdown={true}
                    onChange={onChange}
                    selected={value ? new Date(value) : null}
                    peekNextMonth
                    placeholderText="Enter your birth date"
                    dateFormat="yyyy-MM-dd"
                  />
                );
              }}
            />
            {errors.dob && errors.dob.type === 'required' && (
              <LoginFormError className="error">
                {t('error_empty_dob')}
              </LoginFormError>
            )}
            {errors.dob && errors.dob.type === 'validate' && (
              <LoginFormError className="error">
                {t('error_18_or_more_dob')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup className={`house-num `}>
            <label style={{ fontSize: '14px' }}>{t('house_number')}</label>
            <Text
              className={`hint ${errors['address_home'] ? 'hint-upper' : ''}`}
            >
              {t('address_hint')}
            </Text>
            <Input
              type="text"
              name="address_home"
              autoComplete="off"
              disabled={
                parseInt(registrationType) === 15 ||
                parseInt(registrationType) === 21
              }
              ref={register({
                required: true,
              })}
            />
            {errors.address_home && errors.address_home.type === 'required' && (
              <LoginFormError className="error">
                {t('error_empty_house_no')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup className="street">
            <label>{t('street')}</label>
            <Input
              type="text"
              name="street"
              disabled={
                parseInt(registrationType) === 15 ||
                parseInt(registrationType) === 21
              }
              autoComplete="off"
              ref={register({
                required: true,
              })}
            />
            {errors.street && errors.street.type === 'required' && (
              <LoginFormError className="error">
                {t('error_empty_street')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup
            onChange={(event) => {
              setIsCitiesLoading(true);
              setDistrict(event.target.value);
            }}
          >
            <label>{t('district')}</label>
            <RegistrationDropDown
              name="district"
              disabled={
                parseInt(registrationType) === 15 ||
                parseInt(registrationType) === 21
              }
              ref={register({
                required: t('error_empty_district'),
              })}
            >
              <option value="" />
              {DristrictList.map((districtObj) => (
                <option key={districtObj.id} value={districtObj.id}>
                  {districtObj.name}
                </option>
              ))}
            </RegistrationDropDown>
            {errors.district && errors.district.type === 'required' && (
              <LoginFormError className="error">
                {t('error_empty_district')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('city')}</label>
            <RegistrationDropDown
              name="city"
              ref={register({
                required: t('error_empty_city'),
              })}
              disabled={
                isCitiesLoading ||
                parseInt(userType) === 15 ||
                parseInt(userType) === 21
              }
            >
              <option value="" />
              {cities.map((cityObj) => (
                <option key={cityObj.id} value={cityObj.id}>
                  {cityObj.name}
                </option>
              ))}
            </RegistrationDropDown>
            {isCitiesLoading && (
              <Loading
                className={`loader ${
                  Object.entries(errors).length > 0 ? 'error-loader' : ''
                }`}
              />
            )}
            {errors.city && errors.city.type === 'required' && (
              <LoginFormError className="error">
                {t('error_empty_city')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('is_above_your_address')}</label>
            <RadioContainer>
              <CustomRadio
                checked={isResidential}
                label={t('yes')}
                name="isresidential"
                onChange={() => {
                  setIsResidential(true);
                }}
              />
              <CustomRadio
                checked={!isResidential}
                label={t('no')}
                name="isresidential"
                onChange={() => {
                  setIsResidential(false);
                }}
              />
            </RadioContainer>
          </RegisterFormInputGroup>
          {!isResidential && (
            <>
              <RegisterFormInputGroup
                onChange={(event) => {
                  setIsResidentialCitiesLoading(true);
                  setResidentialDistrict(event.target.value);
                }}
              >
                <label>{t('district')}</label>
                <RegistrationDropDown
                  name="living_district"
                  ref={register({ required: isResidential })}
                >
                  <option value="" />
                  {DristrictList?.map((districtObj) => (
                    <option key={districtObj.code} value={districtObj.id}>
                      {districtObj.name}
                    </option>
                  ))}
                </RegistrationDropDown>
              </RegisterFormInputGroup>
              <RegisterFormInputGroup>
                <label>{t('city')}</label>
                <RegistrationDropDown
                  name="livingCity"
                  ref={register({ required: isResidential })}
                  disabled={isCitiesLoading}
                >
                  <option value="" />
                  {residentialCities?.map((cityObj) => (
                    <option key={cityObj.id} value={cityObj.id + ''}>
                      {cityObj.name}
                    </option>
                  ))}
                </RegistrationDropDown>
                {isResidentialCitiesLoading && <Loading className="loader" />}
              </RegisterFormInputGroup>
            </>
          )}
          <RegisterFormInputGroup>
            <label>{t('mobile_number')}</label>
            <Input
              type="text"
              name="mobile_no"
              autoComplete="off"
              placeholder="07XXXXXXXX"
              ref={register({
                required: true,
                pattern: /^(07|0000|\+94)\d{8}$/m,
              })}
            />
            {errors.mobile_no && errors.mobile_no.type === 'required' && (
              <LoginFormError className="error">
                {t('error_empty_mobile_no')}
              </LoginFormError>
            )}
            {errors.mobile_no && errors.mobile_no.type === 'pattern' && (
              <LoginFormError className="error">
                {t('error_invalid_mobile_no')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('emailaddress')}</label>
            <Input
              type="text"
              name="email"
              autoComplete="off"
              ref={register({
                pattern: /\S+@\S+\.\S+/,
              })}
            />
            {errors.email && errors.email.type === 'pattern' && (
              <LoginFormError className="error">
                {t('error_invalid_email_address')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('nationality')}</label>
            <RegistrationDropDown
              name="nationality"
              ref={register({
                required: true,
              })}
            >
              {Nationality.map((nationalityObj) => (
                <option key={nationalityObj.id} value={nationalityObj.code}>
                  {nationalityObj.nationality}
                </option>
              ))}
            </RegistrationDropDown>
            {errors.nationality && errors.nationality.type === 'pattern' && (
              <LoginFormError className="error">
                {t('error_empty_nationality')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegistrationStepAction
            className={`${registrationType === 14 && 'existing-customer'}`}
          >
            <SubmitButton
              colorTheme={props.colorTheme}
              type="submit"
              value={t('next')}
            />
          </RegistrationStepAction>
        </RegisterFormSection>
      </RegisterFormWrapper>
    </StepComponent>
  );
};
