import React, { useState } from 'react';
import {
  LanguageDropdownWrapper,
  LanguageDropdownButton,
  LanguageDropdownList,
  LanguageDropdownItem,
  Selected,
} from './languageDropdownStyles';

import i18n from '../../../i18n';
import { withTranslation } from 'react-i18next';

import ApiService from '../../../services/api/api';
import RegService from '../../../services/api/registration-api';

const LanguageDropdown = (props) => {
  // const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const languageLabel = { en: 'English', si: 'සිංහල', ta: 'தமிழ்' };

  const changeLanguage = (lng) => {
    ApiService.setLanguage(lng);
    RegService.setLanguage(lng);
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  // useEffect(() => {
  //   changeLanguage(language);
  // }, [i18n, language, props]);

  // useEffect(() => {
  //   i18n.changeLanguage(language);
  // }, [i18n, language, props]);

  return (
    <LanguageDropdownWrapper>
      <LanguageDropdownButton>
        <Selected>{languageLabel[language] || languageLabel.en}</Selected>
        <LanguageDropdownList>
          <LanguageDropdownItem
            value="en"
            label="English"
            onClick={() => changeLanguage('en')}
          >
            English
          </LanguageDropdownItem>
          <LanguageDropdownItem
            value="si"
            label="සිංහල"
            onClick={() => changeLanguage('si')}
          >
            සිංහල
          </LanguageDropdownItem>
          <LanguageDropdownItem
            value="ta"
            label="தமிழ் "
            onClick={() => changeLanguage('ta')}
          >
            தமிழ்
          </LanguageDropdownItem>
        </LanguageDropdownList>
      </LanguageDropdownButton>
    </LanguageDropdownWrapper>
  );
};

export default withTranslation()(LanguageDropdown);
