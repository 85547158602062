import styled from 'styled-components';

export const FooterContent = styled.div`
  color: ${(props) => props.theme.gray_700};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
`;

export const FooterText = styled.div``;
