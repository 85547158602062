import { PropTypes } from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router';
import BackButton from '../../assets/images/back-btn.png';
import {
  BackButtonCol,
  ContentHeaderWrap,
  SearchInput,
  SearchInputCol,
} from './contentHeaderStyles';

const ContentHeader = (props) => {
  const history = useHistory();
  return (
    <ContentHeaderWrap>
      {props.disableArrow ? (
        ''
      ) : (
        <BackButtonCol>
          <div onClick={() => history.goBack()}>
            <img src={BackButton} alt="back button" />
          </div>
        </BackButtonCol>
      )}
      {props.disableSearch ? (
        ''
      ) : (
        <SearchInputCol>
          <form>
            <SearchInput placeholder="Search" type="text" name="search" />
          </form>
        </SearchInputCol>
      )}
    </ContentHeaderWrap>
  );
};

ContentHeader.propTypes = {
  disableSearch: PropTypes.bool,
  disableArrow: PropTypes.bool,
  history: PropTypes.object,
};

ContentHeader.defaultProps = {
  disableSearch: true,
  disableArrow: false,
};

export default ContentHeader;
