import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import tw from 'twin.macro';
import { NavLink } from 'react-router-dom';
import theme from '../../styles/theme';
import PropTypes from 'prop-types';

export const CardXl = styled.div`
  ${tw`w-full text-black bg-white flex flex-wrap justify-start items-start cursor-pointer p-8 lg:p-20 mb-12 transition duration-200 ease-in-out mx-auto`}
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.14);
  max-width: 1070px;

  ${breakpoint('lg')`
    max-width: 100%;
    padding: 1rem 4rem 3rem 4rem;
  `}
  form {
    ${tw`w-full`}
  }

  .left {
    ${tw`w-full xl:w-1/2`};
  }

  .right {
    ${tw`w-full xl:w-1/2 flex justify-center xl:justify-end xl:pl-8 xl:pt-0`}
    padding-top: 34px;

    ${breakpoint('lg')`
      max-width: 100%;
      margin-left: 0%;
      margin-right: 0%;
      padding-left: 4rem;
    `}
  }
`;

export const LeftFormContainer = styled.div``;

export const Input = styled.input`
  width: 100%;
  border-radius: 0;
  border: 1px solid #ccc;
  background-color: ${(props) =>
    props.disabled || props.readOnly ? '#f7f7f7' : '#fff'};
  border: 1px solid ${(props) => props.theme.gray_300};
  -webkit-appearance: none;
  margin-bottom: 18px;
  display: block;
  height: 40px;
  padding: 8px;
  padding-left: 15px !important;
  ${tw`overflow-hidden whitespace-no-wrap`}
  text-overflow: ellipsis;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 0 #fff inset;
    background: #666;
  }

  &.error {
    border: 1px solid ${(props) => props.theme.colorsBrand.primary};
  }

  ${breakpoint('sm')``}
  ${breakpoint('md')`
    height: 60px;
    padding: 8px 20px;
  `}
  ${breakpoint('lg')`
    margin-bottom: 0px;
  `}
  ${CardXl} & {
    ${tw`text-lg text-right`}
  }

  &[type='number'] {
    /* -webkit-text-security: disc; */

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }
`;

export const Select = styled.select`
  width: 100%;
  border-radius: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.gray_300};
  -webkit-appearance: none;
  margin-bottom: 18px;
  display: block;
  height: 40px;
  padding: 8px;
  ${tw`overflow-hidden whitespace-no-wrap`}
  text-overflow: ellipsis;

  &.error {
    border: 1px solid ${(props) => props.theme.colorsBrand.primary};
  }

  ${breakpoint('sm')``}
  ${breakpoint('md')`
    height: 60px;
    padding: 8px 20px;
  `}
  ${breakpoint('lg')``}
  ${CardXl} & {
    ${tw`text-lg text-right`}
  }
`;

export const SelectDropdown = styled.select`
  width: 100%;
  border-radius: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.gray_300};
  -webkit-appearance: none;
  margin-bottom: 18px;
  display: block;
  height: 40px;
  padding: 8px 40px;
  direction: rtl;
  background: transparent;
  background-image: url('data:image/svg+xml,%3Csvg aria-hidden=%27true%27 data-prefix=%27fal%27 data-icon=%27angle-down%27 xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 256 512%27 class=%27svg-inline--fa fa-angle-down fa-w-8%27%3E%3Cpath fill=%27%23808080%27 d=%27M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z%27/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 50%;
  background-size: 15px;
  font-size: 1.125rem;

  ${breakpoint('sm')`
      padding: 6px!important;
  `}
  ${breakpoint('md')`
    height: 60px;
    padding: 8px 50px;
  `}
  ${breakpoint('lg')`
      padding: 8px 50px 0 10px;
      text-overflow: ellipsis;
      white-space: normal;
      margin-bottom: 0px;
    `}
  &.move-left {
    direction: ltr;
  }
`;

export const SelectDropdownInline = styled.select`
  width: 50%;
  border-radius: 0;
  border: 1px solid #ccc;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.gray_300};
  -webkit-appearance: none;
  margin-bottom: 18px;
  display: block;
  height: 40px;
  background: transparent;
  background-image: ${(props) =>
    props.theme.activeTheme !== 'themeSecondary'
      ? `url('data:image/svg+xml,%3Csvg aria-hidden=%27true%27 data-prefix=%27fal%27 data-icon=%27angle-down%27 xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 256 512%27 class=%27svg-inline--fa fa-angle-down fa-w-8%27%3E%3Cpath fill=%27%23E62C7B%27 d=%27M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z%27/%3E%3C/svg%3E')`
      : `url('data:image/svg+xml,%3Csvg aria-hidden=%27true%27 data-prefix=%27fal%27 data-icon=%27angle-down%27 xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 256 512%27 class=%27svg-inline--fa fa-angle-down fa-w-8%27%3E%3Cpath fill=%27%233db5e7%27 d=%27M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z%27/%3E%3C/svg%3E')`};

  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 50%;
  background-size: 15px;
  font-size: 1.125rem;
  padding: 0 24px 0 10px;

  &.in-valid {
    border-color: #da2129;
  }

  ${breakpoint('sm')`
    font-size: 1.0rem;
     padding:0px 24px 0 10px;
  `}
  ${breakpoint('md')`
    height: 40px;
     padding:0px 24px 0 10px;
  `}
  ${breakpoint('lg')`
      padding:0px 24px 0 10px;
      text-overflow: ellipsis;
      white-space: normal;
      margin-bottom: 0px;
  `}
  &.move-left {
    direction: ltr;
  }
`;

export const Form = styled.form`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
`;

export const Button = styled.button`
  ${tw`transition-all duration-200 ease-in-out`}
  width: 300px;
  height: 35px;
  background-color: ${(props) =>
    props.colorTheme
      ? props.theme[props.colorTheme].primary
      : props.theme[props.theme.activeTheme].primary};
  -webkit-appearance: none;
  color: #fff;
  border-radius: 300px;
  overflow: hidden;
  font-size: 22px;
  cursor: pointer;

  :hover {
    background-color: ${(props) =>
      props.colorTheme
        ? props.theme[props.colorTheme].primary_hover
        : props.theme[props.theme.activeTheme].primary_hover};
  }
`;

export const SubmitButton = styled.input`
  ${tw`transition-all duration-200 ease-in-out`}
  min-height: 50px;
  //opacity: ${(props) => (props.processing ? 0.8 : 1)};
  pointer-events: ${(props) => (props.processing ? 'none' : 'auto')};
  background-color: ${(props) =>
    props.colorTheme
      ? props.theme[props.colorTheme].primary
      : props.theme[props.theme.activeTheme].primary};
  color: #fff;
  border-radius: 300px;
  -webkit-appearance: none;
  min-width: 166px;
  overflow: hidden;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
  font-size: 22px;
  cursor: pointer;
  padding: 10px 10px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;

  :hover {
    ${tw`bg-pink-100`}
    background-color: ${(props) =>
      props.colorTheme
        ? props.theme[props.colorTheme].primary_hover
        : props.theme[props.theme.activeTheme].primary_hover};
  }

  ${breakpoint('sm')``}
  ${breakpoint('md')`height: 60px;`}
  ${breakpoint('lg')``}
`;

export const AgreementSubmitButton = styled.input`
  ${tw`transition-all duration-200 ease-in-out`}
  min-height: 30px;
  //opacity: ${(props) => (props.processing ? 0.8 : 1)};
  pointer-events: ${(props) => (props.processing ? 'none' : 'auto')};
  background-color: ${(props) =>
    props.colorTheme
      ? props.theme[props.colorTheme].primary
      : props.theme[props.theme.activeTheme].primary};
  color: #fff;
  border-radius: 300px;
  -webkit-appearance: none;
  min-width: 200px;
  overflow: hidden;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
  font-size: 16px;
  cursor: pointer;
  margin: 3px;
  padding: 15px 15px;
  cursor: pointer;

  :hover {
    ${tw`bg-pink-100`}
    background-color: ${(props) =>
      props.colorTheme
        ? props.theme[props.colorTheme].primary_hover
        : props.theme[props.theme.activeTheme].primary_hover};
  }

  ${breakpoint('sm')``}

  ${breakpoint('md')`height: 60px;`}

  ${breakpoint('lg')``}
`;

export const BackButtonInput = styled.input`
  ${tw`transition-all duration-200 ease-in-out`}
  min-height: 50px;
  //opacity: ${(props) => (props.processing ? 0.8 : 1)};
  pointer-events: ${(props) => (props.processing ? 'none' : 'auto')};
  background-color: #fff;
  color: #000;
  border-radius: 300px;
  border: 1px solid ${(props) => props.theme[props.theme.activeTheme].primary};
  -webkit-appearance: none;
  min-width: 240px;
  overflow: hidden;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
  font-size: 22px;
  cursor: pointer;
  padding: 10px 10px;
  cursor: pointer;

  ${breakpoint('sm')``}

  ${breakpoint('md')`height: 60px;`}

  ${breakpoint('lg')``}
`;

export const OutlinedButton = styled(NavLink)`
  min-height: 35px;
  color: ${(props) =>
    props.colorTheme
      ? props.theme[props.colorTheme].primary
      : props.theme[props.theme.activeTheme].primary};
  border-radius: 300px;
  -webkit-appearance: none;
  min-width: 240px;
  overflow: hidden;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.colorTheme
        ? props.theme[props.colorTheme].primary
        : props.theme[props.theme.activeTheme].primary};
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoint('sm')``}

  ${breakpoint('md')`height: 60px;`}

  ${breakpoint('lg')``}
`;
export const LimitButton = styled(NavLink)`
  min-height: 35px;
  border-radius: 300px;
  color: #fff;
  -webkit-appearance: none;
  min-width: 240px;
  overflow: hidden;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.colorTheme
        ? props.theme[props.colorTheme].primary
        : props.theme[props.theme.activeTheme].primary};
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.colorTheme
      ? props.theme[props.colorTheme].primary
      : props.theme[props.theme.activeTheme].primary};

  ${breakpoint('sm')``}

  ${breakpoint('md')`height: 60px;`}

  ${breakpoint('lg')``}
`;

export const RequestButton = styled(NavLink)`
  min-height: 40px;
  border-radius: 20px;
  color: #fff;
  -webkit-appearance: none;
  min-width: 300px;
  overflow: hidden;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.colorTheme
        ? props.theme[props.colorTheme].primary
        : props.theme[props.theme.activeTheme].primary};
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.colorTheme
      ? props.theme[props.colorTheme].primary
      : props.theme[props.theme.activeTheme].primary};

  ${breakpoint('sm')``}

  ${breakpoint('md')`height: 60px;`}

  ${breakpoint('lg')``}
`;

export const CheckBox = styled.div`
  ${tw`flex mb-4 relative`}
  > input {
    /* -webkit-appearance: none; */

    ${tw`absolute w-0 opacity-0`}
    &:checked {
      + label {
        &:before {
          background-color: ${(props) =>
            props.colorTheme
              ? props.theme[props.colorTheme].primary
              : props.theme[props.theme.activeTheme].primary};
          border-color: ${(props) =>
            props.colorTheme
              ? props.theme[props.colorTheme].primary
              : props.theme[props.theme.activeTheme].primary};
        }
      }
    }
  }

  > label {
    ${tw`flex items-start justify-start text-base font-normal text-gray-400`}
    display: flex !important;

    span {
      ${tw` font-bold text-gray-300`}
      padding-right:5px;
    }
    span.link {
      ${tw`font-normal underline uppercase text-blue-500 cursor-pointer`}
    }

    &:before {
      ${tw`flex border-gray-200 border border-solid mr-2 transition duration-200 ease-in-out`}
      width: 19px;
      height: 19px;
      content: '';
    }

    svg {
      ${tw`absolute left-0 ml-1 w-3 transition duration-200 ease-in-out`}
      top: 4px;
    }
  }
`;

// Text

export const Title = styled.h1`
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: #4d4d4d;
  font-size: 2.2em;
`;

export const Title2 = styled.h2`
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  color: #4d4d4d;
  font-size: 1.8em;
`;

export const Text = styled.p`
  font-family: 'Raleway', sans-serif;
  color: ${(props) => props.color || '#4d4d4d'};
`;

export const FormAction = styled.div`
  ${tw`flex items-center justify-center`}
  ${breakpoint('md')`
    padding: 1rem 3rem 0rem 3rem;
  `}
`;

export const FormActionRequestLoan = styled.div`
  ${tw`flex items-center justify-center md:justify-start flex-col md:flex-row`}

  ${breakpoint('md')`
    padding: 1rem 3rem 0rem 3rem;
    padding-left: 300px;
  `}
`;
export const FormActionContainer = styled.div`
  ${tw`flex items-center justify-center md:justify-center flex-col md:flex-row`}
`;

// Form layout

export const FormRow = styled.div`
  ${tw`flex justify-between flex-col`}

  ${breakpoint('md')`
    flex-direction: row;
  `}
`;

export const FormGroup = styled.div`
  ${tw`mb-4`}
  ${breakpoint('md')`
    width: 43%;
  `}
  label {
    ${tw`block text-base text-gray-400 mb-3`}
    line-height: 22px;
  }
`;

export const LoanFormInputGroup = styled.div`
  ${tw`mb-8`}
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;

  label {
    ${tw`block text-base text-gray-400 mb-3`}
    line-height: 22px;
    width: 40%;
  }

  input {
    width: 60%;
    border-radius: 0;
    border: 1px solid #ccc;
    background-color: ${(props) => (props.disabled ? '#f7f7f7' : '#fff')};
    border: 1px solid ${(props) => props.theme.gray_300};
    -webkit-appearance: none;
    margin-bottom: 18px;

    height: 40px;
    padding: 8px;
  }
`;

export const FormInputGroup = styled.div`
  ${tw`mb-4`}
  label {
    ${tw`block text-base text-gray-400 mb-3`}
    line-height: 22px;
  }

  ${breakpoint('lg')`
    width: 100%;
  `}
  ${CardXl} & {
    ${tw`mb-8`}
    ${breakpoint('lg')`
    display: inline-block;
  `}
    .card-last-digit-form-error {
      margin-top: 0;
    }
  }

  input {
    width: 100%;
    border-radius: 0;
    border: 1px solid #ccc;
    background-color: ${(props) => (props.disabled ? '#f7f7f7' : '#fff')};
    border: 1px solid ${(props) => props.theme.gray_300};
    -webkit-appearance: none;
    margin-bottom: 18px;
    display: block;
    height: 40px;
    padding: 8px;
    ${tw`overflow-hidden whitespace-no-wrap`}
    text-overflow: ellipsis;

    &.error {
      border: 1px solid ${(props) => props.theme.colorsBrand.primary};
    }

    ${breakpoint('md')`
      height: 60px;
      padding: 8px 20px;
    `}
    ${breakpoint('lg')`
      margin-bottom: 0px;
    `}
    ${CardXl} & {
      ${tw`text-lg text-right`}
    }
  }

  .from-account {
    direction: ltr;
    display: inline-flex;
    white-space: nowrap;
  }

  .move-left {
    direction: ltr;
    display: inline-flex;
    white-space: nowrap;
  }
`;

export const FormError = styled.p`
  ${tw`text-center text-white font-bold text-xs p-2`}
  background-color: ${theme.main};
  border-radius: 0 0 5px 5px;
`;

export const LoginFormError = styled.p`
  ${tw`text-center text-white font-bold text-xs p-2`}
  background-color: ${theme.main};
  border-radius: 5px;
  margin-top: 5px;
`;

export const IconTitleWrap = styled.div`
  ${tw`flex items-center mb-12`}
  h3 {
    ${tw`flex justify-center text-lg font-medium ml-3`}
    flex: 1 1;
  }
`;

export const ConfirmTransaction = styled.div`
  ${tw`p-2`}
  .top-content {
    ${tw`pb-6`}
    h3 {
      ${tw`pb-5`}
    }

    p {
      ${tw`pb-3 flex justify-between`}// span {
                      //   ${tw`italic`}
              // }
    }

    .billpayment-note {
      ${tw`pt-3 text-xs text-red-600`}
    }

    .process-message {
      color: #af0b0b;
    }

    .no-italics {
      font-style: normal;
    }
  }

  .btns-wrap {
    ${tw`flex justify-between items-center`}
    button {
      ${tw`p-3 text-black bg-transparent uppercase font-bold hover:underline`}
      font-size: 13px;
      border-radius: 15px;
      min-width: 89px;
      border: 2px solid
        ${(props) => props.theme[props.theme.activeTheme].primary};
    }
  }
`;

// Form messages

export const FormMessageBoxSuccess = styled.div`
  ${tw`bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4`}
  max-width: 328px;
  margin-top: 8px;
`;

export const VerticalAlignedInputs = styled.div`
  ${tw`flex justify-between items-center`}
  ${Input} {
    ${tw`w-1/2`}
    &:first-child {
      ${tw`mr-2`}
    }

    :last-child {
      ${tw`ml-2`}
    }
  }
`;

export const RequestLoanFormInputGroup = styled.div`
  ${tw`mb-8`}
  display: flex;
  flex-flow: row wrap;
  //align-items: center;
  width: 100%;

  .flex {
    display: flex !important;
    & > * + * {
      margin-left: 1rem;
    }
  }

  & > label {
    ${tw`block text-base `} //line-height: 22px;
    margin-bottom: 1.0rem;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    max-width: 300px;

    ${breakpoint('md')` 
    width: 40%; 
    padding-right: 1rem;
    color: #595959;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    `}
    sup {
      ${tw`text-base text-gray-400`}
    }

    small {
      color: #a19f9f;
      display: block;
    }

    .red {
      color: #da2129;
    }
  }

  & > select {
    width: 100%;
    ${breakpoint('md')` width: 60%;`}
  }

  & > div {
    width: 100%;

    ${breakpoint('md')` width: 60%;`}
    & > input {
      width: 100%;
      border-radius: 0;
      border: 1px solid #ccc;
      background-color: ${(props) => (props.disabled ? '#f7f7f7' : '#fff')};
      color: ${(props) =>
        props.disabled
          ? props.theme.gray_700
          : props.theme.gray_800} !important;
      border: 1px solid ${(props) => props.theme.gray_300};
      -webkit-appearance: none;
      margin-bottom: 18px;

      height: 35px;
      padding: 8px;

      ${breakpoint('md')` margin-bottom: 0.5rem;`}
    }

    select {
      width: 100%;
      ${breakpoint('md')` margin-bottom: 0.5rem;`}
    }
  }

  h6 {
    display: block;
    font-weight: normal;
    padding-top: 0.5rem;
    font-size: 14px;
    color: #da2129;
  }

  .hintTxt {
    color: #afafaf !important;
  }

  & > input {
    width: 100%;
    border-radius: 0;
    border: 1px solid #ccc;
    background-color: ${(props) => (props.disabled ? '#f7f7f7' : '#fff')};
    color: ${(props) =>
      props.disabled ? props.theme.gray_400 : props.theme.gray_800};
    border: 1px solid ${(props) => props.theme.gray_300};
    -webkit-appearance: none;

    height: 35px;
    padding: 8px;

    ${breakpoint('md')` width: 60%;`}
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    background-image: ${(props) =>
      props.theme.activeTheme !== 'themeSecondary'
        ? `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24">
    <path fill="%23E62C7B" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')`
        : `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24">
    <path fill="%233db5e7" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>')`};
  }

  .error-text {
    border-color: #da2129 !important;
    font-size: 12px;
  }

  .in-valid {
    border-color: #da2129 !important;
  }

  .radio-button-group {
    margin-top: -1%;
  }

  .mat-check-box .MuiFormControlLabel-label {
    color: #da2129;
  }

  .custom.MuiFormControlLabel-root {
    align-items: flex-start !important;
    margin-top: 15px;

    & > .MuiButtonBase-root {
      padding-top: 0px !important;
    }
  }

  > * {
    &:last-child {
      ${breakpoint('md')` max-width: 500px !important;`}
    }
  }

  div[role='radiogroup'] {
    .MuiFormControlLabel-root {
      display: block;
    }
  }
`;

RequestLoanFormInputGroup.propTypes = {
  disabled: PropTypes.bool,
};
