import styled from 'styled-components';
import tw from 'twin.macro';

export const LanguageDropdownWrapper = styled.div`
  ${tw`flex items-center justify-center`};
  margin-right: 0 !important;

  &:after {
    ${tw`hidden`};
  }
`;

export const Selected = styled.div`
  ${tw`flex w-12`};
`;

export const LanguageDropdownButton = styled.div`
  ${tw`flex text-white cursor-pointer relative lg:mr-4`};

  &:after {
    ${tw`block w-4 h-4 ml-2 transition duration-200 ease-in-out`};
    content: '';
    background: url('../../../assets/images/dropdown-icon.png') no-repeat center;
  }
  &:hover {
    &:after {
      transform: rotateZ(180deg);
    }
  }
`;

export const LanguageDropdownList = styled.ul`
  ${tw`absolute bg-white  border-r-4 rounded opacity-0`};
  box-shadow: 0px 2px 3px 0 rgb(0 0 0 / 30%);
  overflow: hidden;
  top: 100%;

  ${LanguageDropdownButton}:hover & {
    ${tw`opacity-100`};
  }
`;

export const LanguageDropdownItem = styled.li`
  ${tw`text-gray-400 transition-all duration-200 ease-in-out py-2 px-5 cursor-pointer hover:bg-blue-100 hover:text-gray-600`};
`;
