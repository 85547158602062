import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import tw from 'twin.macro';
import { Input } from '../../containers/form/form';

export const TabButton = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  border-right: 1px solid ${(props) => props.theme.gray_300};
  align-items: center;
  min-height: 58px;
  font-size: 16px;
  min-width: 150px;
  font-weight: 200;
  cursor: pointer;
  border-bottom: 3px solid ${(props) => props.theme.white};
  ${tw`w-6/12`}

  ${breakpoint('sm')``}

  ${breakpoint('md')`
    border-bottom: 5px solid ${(props) => props.theme.white};
    min-height: 70px;
    max-width: 150px;
  `}

  ${breakpoint('lg')`
    flex-direction: row; border-bottom: 7px solid ${(props) =>
      props.theme.white};
      
  `}

  &.active {
    border-bottom: 3px solid ${(props) => props.theme.main};
    font-weight: bold;

    ${breakpoint('sm')`flex-direction: column`}

    ${breakpoint('md')`border-bottom: 5px solid ${(props) =>
      props.theme.main};`}

    ${breakpoint('lg')`border-bottom: 7px solid ${(props) =>
      props.theme.main};`}
  }
`;

export const TabNavWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.gray_300};

  ${breakpoint('sm')``}

  ${breakpoint('lg')`flex-direction: row`}

  .tab-list {
    .tab {
      font-size: 16px;
      min-width: 150px;
    }
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  .panel-list {
    width: 100%;
  }
`;

export const FormWrapper = styled.form`
  max-width: 490px;
  margin: 0 auto;
  width: 100%;
  padding: 60px 20px;

  ${breakpoint('sm')`
  `}

  ${breakpoint('md')`
    padding: 60px 40px;
  `}

  ${breakpoint('lg')`
    padding: 60px 60px;
  `}
`;

export const FormHeader = styled.div`
  ${tw`flex items-center justify-center pt-12 lg:pt-16`}

  ${
    '' /* img {
    max-width: ${(props) => (props.isMerchant ? '100%' : '100px')};
  } */
  }

  .customer {
    width: 115px !important;
  }
  .business {
    width: 100px !important;
  }
`;

export const FormFooter = styled.div`
  ${tw`flex items-center justify-center flex-wrap`}

  h5 {
    ${tw`text-base underline cursor-pointer`}
    color: #107BBF;
  }

  p {
    ${tw`text-lg text-center mt-4 text-pink`}
    color: ${(props) =>
      props.colorTheme
        ? props.theme[props.colorTheme].primary
        : props.theme[props.theme.activeTheme].primary};
  }
`;

export const FormAction = styled.div`
  ${tw`flex items-center justify-center p-8`}

  > svg {
    margin-left: -50px !important;
  }
`;

export const UserInput = styled(Input)`
  /* background-image: url('../assets/images/input-user.png') !important;
  background: no-repeat 18px center; */
  padding-left: 55px !important;

  &[type='number'] {
    -webkit-text-security: disc;

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
  }

  + p {
    margin-bottom: 8px;
  }
`;

export const UserInputWrapper = styled.div`
  ${tw`relative`};

  margin-bottom: 24px;

  &:before {
    content: '';
    top: 6px;
    left: 10px;
    ${tw`absolute bg-no-repeat bg-center block w-8 h-8`};
    background-image: url('../assets/images/input-user.png') !important;

    ${breakpoint('lg')`
      top: 14px;
    `}
  }
`;

export const PasswordInput = styled(Input)`
  //background-image: url('../assets/images/input-password.png') !important;
  background: no-repeat 18px center;
  padding-left: 55px !important;

  ::-ms-reveal {
    display: none;
  }
`;

export const PasswordInputWrap = styled.div`
  ${tw`relative`}

  margin-bottom: 24px;

  &:before {
    content: '';
    top: 6px;
    left: 10px;
    ${tw`absolute bg-no-repeat bg-center block w-8 h-8`};
    background-image: url('../assets/images/input-password.png') !important;

    ${breakpoint('lg')`
      top: 14px;
    `}
  }

  .show-hide-icon {
    ${tw`absolute w-4`}
    right: 10px;
    top: 12px;

    ${breakpoint('md')`
      top: 20px;
    `}
  }
`;
export const NicInputWrap = styled.div`
  ${tw`relative`}

  margin-bottom: 24px;
  &:before {
    content: '';
    top: 6px;
    left: 10px;
    background-image: url('../assets/images/input-password.png') !important;

    ${breakpoint('lg')`
      top: 14px;
    `}
  }

  .show-hide-icon {
    ${tw`absolute w-4`}
    right: 10px;
    top: 12px;

    ${breakpoint('md')`
      top: 20px;
    `}
  }
`;

export const RegistrationText = styled.div`
  ${tw`text-center flex justify-center`}
  padding: 30px 0 30px;

  a {
    ${tw`underline italic text-black`};
    margin-left: 3px;
    margin-right: 3px;
    max-width: 180px;

    img {
      max-width: 180px;
    }
  }
`;

export const LoginOTPFormWrap = styled.div`
  ${tw`mb-8 mt-5`}
  position:relative;
  .select-opt-option-title {
    ${tw`mb-8 mt-5`}
  }

  .otp-option-item {
    background-color: #efefef;
    ${tw`mb-6 p-3`}

    :last-child {
      ${tw`mb-0`}
    }

    label {
      ${tw`flex items-center cursor-pointer w-full`}
    }

    input[type='radio'] {
      ${tw`mr-2 p-0`}
    }
  }

  input {
    ${tw`p-0`}
  }

  .otp-inputs-wrap {
    ${tw`justify-between my-4`}

    input {
      margin-right: 0 !important;
    }
  }

  .resend-otp {
    button {
      background-color: transparent;
      border-bottom: 1px solid #efefef;

      :disabled {
        ${tw`cursor-not-allowed`}
      }
    }
  }
`;
