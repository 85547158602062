import tw from 'twin.macro';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const RegistrationStepAction = styled.div`
  ${tw`flex items-center justify-center`}
  width: 100%;
  padding: 0px 0px;
  margin-top: 30px;
  flex: 0 0 99%;

  p {
    margin-top: 0 !important;

    span {
      &.terms-link {
        margin-left: 5;
        color: #1d75e0;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  ${breakpoint('md')`
    flex: 0 0 49%;
    margin-top: 15px;

    &.existing-customer {
      flex: 0 0 99% !important;
    }
  `}

  &.push-right {
    flex: 0 0 99%;

    //svg {
    //  margin-right: 0 !important;
    //}

    &.processing input {
      ${breakpoint('md')`
        margin-left: 0;
      `}
    }
  }

  input {
    margin-left: 0 1em;

    ${breakpoint('md')`
      margin-left: auto;
    `}

    ${breakpoint('md')`
      margin-left: auto;
    `}
  }

  > svg {
    margin-left: auto !important;
  }
`;

export const RegisterFormSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 79px;
  max-width: 948px;
  margin: 0 auto;
  width: 100%;
  padding: 3em 1em 1em 1em;

  ${breakpoint('md')`
    padding: 3em 1em 1em 1em;
  `}

  ${breakpoint('lg')`
    padding: 3em 1em 1em 1em;
  `}
`;

export const CapturePhoto = styled.div`
  height: 60px;
  border: 1px solid #d1d1d1;
  margin-bottom: 18px;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  padding: 22px;
  display: flex;
  cursor: pointer;

  label {
    font-weight: 600;
    font-size: 18px;
    flex-grow: 0 !important;
    margin: 0px 15px 0px 0px;
  }
`;
export const RegSpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  display: flex;
`;

export const RegCIMDialog = styled.div`
   {
    ${tw`shadow`}
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    width: 100%;
    background: white;
    border-radius: 5px;
    padding: 2rem;

    h6 {
      text-align: justify;
      margin-bottom: 2rem;
    }

    .dialog-actions {
      margin-top: 2rem;
      display: flex;
      gap: 0.5rem;
      justify-content: center;
      align-items: center;
    }
  }
`;
