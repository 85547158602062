import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Footer from '../../../containers/footer';
import Header from '../../../containers/header';
import { useUser } from '../../../context/user-context';
import { FooterWrapper, LoginContent, Wrapper } from './styles';

const AuthLayout = ({ children }) => {
  const { user } = useUser();

  useEffect(() => {
    const body = document.querySelector('#root');

    body.scrollIntoView(
      {
        behavior: 'smooth',
      },
      500
    );
  }, []);
  return (
    <LoginContent>
      {!user?.isMobile && <Header />}
      <Wrapper>{children}</Wrapper>
      {!user?.isMobile && (
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      )}
    </LoginContent>
  );
};

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AuthLayout;
