import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { useToasts } from 'react-toast-notifications';
import HeaderHelp from '../../components/header/headerHelp/headerHelp';
import LanguageDropdown from '../../components/header/languageDropdown/languageDropdown';
import SocialIcons from '../../components/header/socialIcons/socialIcons';
import { EllipsisV } from '../../components/icons';
import Menu from '../../components/Menu/Menu';
import CustomButton from '../../components/shared/button/CustomButton';
import CustomModal from '../../components/shared/dialog-modal/CustomModal';
import { useUser } from '../../context/user-context';
import { ValidateRegistrationModal } from '../../pages/Register';
import ApiService from '../../services/api/api';
import {
  HeaderRight,
  HeaderWrapper,
  Hotline,
  Logo,
  Logout,
  MobileHeaderDropdown,
  MobileMenu,
  MobileMenuBottom,
  RegiterLink,
} from './headerStyles';

const Header = () => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [showValidateModal, setShowValidateModal] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const { user, setUser } = useUser();
  const { t } = useTranslation();
  const { addToast } = useToasts();
  let signedIn = user?.otp ? true : false;

  const logOut = async (showToast = false) => {
    try {
      const response = await ApiService.logout();
      if (response.kind === 'ok') {
        // redirect to login page
        if (showToast) {
          addToast('Session Expired, Please Login Again', {
            appearance: 'info',
          });
        }

        setUser(null);
        signedIn = false;
        window.location.href = '/';
      } else {
      }
    } catch (error) {}
  };

  const handleOnIdle = () => {
    if (user?.otp) {
      logOut(true);
    }
  };

  useIdleTimer({
    timeout: 300000,
    onIdle: handleOnIdle,
  });

  return (
    <HeaderWrapper
      // @ts-ignore
      MenuActive={mobileMenuActive}
      className={mobileMenuActive ? 'active' : undefined}
    >
      <Logo>
        <img src="/../assets/images/lb-finance-logo.svg" alt="" />
      </Logo>
      <HeaderRight>
        {signedIn ? (
          <>
            <SocialIcons />
            <Hotline>
              {t('hotline')} <a href="tel:0112200500"> : 0112 200 500</a>
            </Hotline>
            <Logout onClick={() => setShowLogoutConfirmation(true)} to="#">
              {t('logout_title')}
            </Logout>
          </>
        ) : (
          <>
            <SocialIcons />
            <RegiterLink
              onClick={() => {
                if (window.location.pathname !== '/register') {
                  setShowValidateModal(true);
                }
              }}
            >
              {t('register_title')}
            </RegiterLink>
            <LanguageDropdown />
          </>
        )}
        <ValidateRegistrationModal
          onClose={() => {
            setShowValidateModal(false);
          }}
          show={showValidateModal}
        />
      </HeaderRight>

      <MobileHeaderDropdown>
        {signedIn ? (
          <>
            <Menu onClick={() => setMobileMenuActive(!mobileMenuActive)} />
            <MobileMenuBottom>
              <Logout onClick={() => logOut()} to="#">
                {t('logout_title')}
              </Logout>
              <Hotline>
                {t('hotline')}
                <a href="tel:0112200200"> : 0112 200 200</a>
              </Hotline>
              <SocialIcons />
            </MobileMenuBottom>
          </>
        ) : (
          <>
            <MobileMenuBottom>
              <RegiterLink
                onClick={() => {
                  setMobileMenuActive(!mobileMenuActive);
                  setShowValidateModal(true);
                }}
              >
                {t('register_title')}
              </RegiterLink>
              <LanguageDropdown />

              <HeaderHelp />
              <SocialIcons />
            </MobileMenuBottom>
          </>
        )}
      </MobileHeaderDropdown>

      <MobileMenu
        onClick={() => setMobileMenuActive(!mobileMenuActive)}
        className={mobileMenuActive ? 'active' : undefined}
      >
        <EllipsisV fill="white" />
      </MobileMenu>

      {/* Logout confirmation dialog modal */}
      <CustomModal
        id="logoutConfirmation"
        open={showLogoutConfirmation}
        onClose={() => setShowLogoutConfirmation(false)}
        maxWidth="xs"
        dialogContent={t('logout')}
        dialogActions={
          <>
            <CustomButton
              label={t('cancel')}
              onClick={() => setShowLogoutConfirmation(false)}
              color="gray"
            />
            <CustomButton
              label={t('logout_title')}
              onClick={() => {
                setShowLogoutConfirmation(false);
                logOut();
              }}
            />
          </>
        }
      />
    </HeaderWrapper>
  );
};

export default Header;
