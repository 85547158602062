import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../context/user-context';
import { MenuWrapper, MenuContainer, MenuItem } from './MenuStyle';

const Menu = (props) => {
  const user = useUser();
  const { t } = useTranslation();

  const userRoles = user?.user?.roles ?? [];
  const myLBContractsPermitted =
    userRoles.length === 0
      ? false
      : userRoles.includes('SAVING') ||
        userRoles.includes('LEASING' || userRoles.includes('GOLD_LOAN'));

  const navigateScrollerToTop = () => {
    window.scrollTo(0, 0);
    props && props.onClick && props.onClick();
  };
  return (
    <MenuWrapper>
      <MenuContainer>
        <MenuItem>
          <NavLink onClick={() => navigateScrollerToTop()} to="/dashboard">
            {t('home')}
          </NavLink>
        </MenuItem>

        {user?.user?.roles?.includes(
          'TRANSFER_WALLET' ||
            'TRANSFER_NONWALLET' ||
            'TRASFER_LBACCOUNT' ||
            'TRANSFER_OTHERACCOUNT'
        ) && (
          <MenuItem>
            <NavLink
              onClick={() => navigateScrollerToTop()}
              to="/fund-transfer"
            >
              {t('fund_transfer')}
            </NavLink>
          </MenuItem>
        )}

        {user?.user?.roles?.includes('BILLPAY') && (
          <MenuItem>
            <NavLink onClick={() => navigateScrollerToTop()} to="/bill-payment">
              {t('text_online_bill_payments')}
            </NavLink>
          </MenuItem>
        )}

        {/*TODO: CHANGE THE ROLE*/}

        {/* // TODO : REMOVE LATER */}
        {/* <MenuItem>
          <NavLink onClick={() => navigateScrollerToTop()} to="/loan-types">
            {t('request_loan')}
          </NavLink>
        </MenuItem> */}
        <MenuItem>
          <NavLink onClick={() => navigateScrollerToTop()} to="/loans">
            {t('request_loan')}
          </NavLink>
        </MenuItem>

        <MenuItem>
          <NavLink
            onClick={() => navigateScrollerToTop()}
            to="/new-fixed-deposits"
          >
            {t('deposits_with_lb')}
          </NavLink>
        </MenuItem>

        {/*<MenuItem>*/}
        {/*  <NavLink onClick={() => navigateScrollerToTop()} to="/e-remittances">*/}
        {/*    {t('e_rem')}*/}
        {/*  </NavLink>*/}
        {/*</MenuItem>*/}
        {/* My LB Contracts */}
        {myLBContractsPermitted && (
          <MenuItem>
            <NavLink onClick={() => navigateScrollerToTop()} to="/my-contracts">
              {t('my_lb_contracts')}
            </NavLink>
          </MenuItem>
        )}

        {/* {user?.user?.roles?.includes('LEASING') && (
          <MenuItem>
            <NavLink
              onClick={() => navigateScrollerToTop()}
              to="/apply_loan"
            >
              {t('apply_loan')}
            </NavLink>
          </MenuItem>
        )}   */}

        <MenuItem>
          <NavLink onClick={() => navigateScrollerToTop()} to="/profile">
            {t('profile')}
          </NavLink>
        </MenuItem>
      </MenuContainer>
    </MenuWrapper>
  );
};

export default Menu;
