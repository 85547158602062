import React from 'react';
import { HeaderInfoWrapper } from './headerHelpStyles';

const HeaderHelp = () => {
  return (
    <HeaderInfoWrapper>
      <img src="../assets/images/header-info.png" alt="" />
    </HeaderInfoWrapper>
  );
};

export default HeaderHelp;
