import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { IUser } from '../interfaces/IUser';
import Cookies from 'js-cookie';
import ApiService from '../services/api/api';
import { REFRESH_TOKEN_NAME } from '../services/api/api.config';
import Loading from '../components/loading/loading';
import { ICustomWindow } from '../interfaces/ICustomWindow';

export type UserContextType = {
  user: IUser | null;
  setUser: (userData: any) => void;
};

const UserContext = createContext<UserContextType>({
  user: null,
  setUser: (userData: any) => {},
});

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const firstTime = useRef(true);
  let history = useHistory();

  const [user, setUser] = useState<IUser>({ isMobile: true});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // check session
    if (firstTime.current) {
      firstTime.current = false;
      setLoading(false);

      intervalRef.current = setInterval(() => {
        getRefreshToken();
      }, 360000);

      const getRefreshToken = async () => {
        const customWindow: ICustomWindow = window;
        const refreshToken = Cookies.get(REFRESH_TOKEN_NAME);
        if (refreshToken) {
          // check if it is invalid
          if (!ApiService.verifyRefreshToken(refreshToken)) {
            // refresh token expired return home
            Cookies.remove(REFRESH_TOKEN_NAME);
            intervalRef?.current && clearInterval(intervalRef.current);

            if (
              user?.isMobile &&
              (customWindow?.easyLoanWebEvent ||
                customWindow?.webkit?.messageHandlers?.easyLoanWebEvent)
            ) {
              (
                customWindow?.easyLoanWebEvent ||
                customWindow?.webkit?.messageHandlers?.easyLoanWebEvent
              )?.postMessage(
                JSON.stringify({
                  source: 'easyLoan',
                  event: 'expired',
                  data: {},
                })
              );
            } else {
              window.location.href = '/';
            }
            return;
          }

          try {
            // request new refreshToken
            const response = await ApiService.refreshToken();
            if (response.kind === 'ok') {
            } else {
            }
          } catch (error) {}
        }
      };

      getRefreshToken();
    }
  }, [history, user]);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {loading ? <Loading /> : children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
