import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { IPaletteOptions } from '../../../interfaces/ITheme';

interface ModalProps {
  id: string;
  dialogContent: ReactNode;
  open: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  title?: string | ReactNode;
  dialogActions?: ReactNode;
  scroll?: 'paper' | 'body';
  onBackdropClick?: () => void;
  onClose?: () => void;
}

const CustomModal = ({
  id,
  dialogContent,
  open,
  maxWidth,
  title,
  dialogActions,
  scroll,
  onBackdropClick,
  onClose,
}: ModalProps) => {
  const modalWidth = maxWidth ? maxWidth : 'sm';

  const activeTheme = useTheme();

  const activePalette = activeTheme.palette as unknown as IPaletteOptions;

  const titleSectionStyles = {
    '&.MuiDialogTitle-root': {
      margin: 0,
      padding: 2,
    },
  };

  const titleTextStyles = {
    display: 'block',
    width: '95%',
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: 'bolder',
  };

  const dialogContentStyles = {
    '&.MuiDialogContent-root': {
      margin: 0,
      padding: 2,
    },
  };

  const dialogActionStyles = {
    '&.MuiDialogActions-root': {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      padding: 2,
    },
  };

  return (
    <Dialog
      open={open}
      aria-labelledby={id}
      fullWidth
      maxWidth={modalWidth}
      scroll={scroll}
      onBackdropClick={onBackdropClick}
      sx={{
        zIndex: 4,
        mt: 7,
      }}
    >
      {title || onClose ? (
        <DialogTitle id={id} sx={titleSectionStyles}>
          <div style={titleTextStyles}>{title ?? ''}</div>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                width: '40px',
                right: 8,
                top: 8,
                clear: 'both',
                color: `${activePalette.primary_default}`,
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      ) : null}
      <DialogContent sx={dialogContentStyles}>{dialogContent}</DialogContent>
      {dialogActions ? (
        <DialogActions sx={dialogActionStyles}>{dialogActions}</DialogActions>
      ) : null}
    </Dialog>
  );
};

export default CustomModal;
