import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TiledPage from '../../components/shared/tiled-page/TiledPage';
import { useUser } from '../../context/user-context';
import ICardsPageConfig, {
  ICardConfig,
} from '../../interfaces/ICardsPageConfigs';
import { ICustomWindow } from '../../interfaces/ICustomWindow';
import ApiService from '../../services/api/api';
import {
    NEW_LOAN_DESC,
    TOP_UP_DESC,
} from '../../utils/navigation-path';

interface TopUpTypesProps {
  title: string;
}

const TopUpTypes = ({ title }: TopUpTypesProps) => {
  const firstTime = useRef(true);
  const { setUser, user } = useUser();
  const accountNumber = user?.walletAccountNo;
  const { t } = useTranslation();

  const translatedTitle = t('rql_loan_types_title');

  const [pageConfig, setPageConfig] = useState<ICardsPageConfig>({
    docTitle: translatedTitle,
    sectionTitle: translatedTitle,
    backArrow: false,
    loading: false,
    cards: [],
  });

  useEffect(() => {
    const listFavorites = async () => {
      try {
        setPageConfig((prev) => ({ ...prev, loading: true }));

        const easyLoanMasterData = await ApiService.getEasyLoanProducts();
        const loanTypesData = await ApiService.getAllLoanTypesWithDistricts();

        if (easyLoanMasterData.kind === 'ok' || loanTypesData.kind === 'ok') {
          let cards: ICardConfig[] = [];

            // easyLoanMasterData.data.push({
            //     productEnum: 33,
            //     productDescription: 'CIM PayDay Loan'
            // })

          if (easyLoanMasterData.data) {
            easyLoanMasterData.data.filter((loan: any) => loan?.productEnum === 29).forEach((loan: any) =>
              cards.push({
                id: `${loan?.productEnum}`,
                key: loan?.productEnum,
                title: "CIM Personal Loan",
                //title: loan?.productDescription,
                url: TOP_UP_DESC,
                minMax: {
                  min: Number(loan?.minValue ?? '0'),
                  max: Number(loan?.maxValue ?? '0'),
                },
              })
            );
          } else {
            if (user?.isMobile) {
              const customWindow: ICustomWindow = window;
              (
                customWindow?.easyLoanWebEvent ||
                customWindow?.webkit?.messageHandlers?.easyLoanWebEvent
              ).postMessage(
                JSON.stringify({
                  source: 'easyLoan',
                  event: 'cancelled',
                  data: {},
                })
              );
            } else if (loanTypesData.kind !== 'ok') {
              setUser(null);
            }
          }

          if (loanTypesData?.data?.loanTypes) {
            loanTypesData.data.loanTypes.filter((loan: any) => loan?.productEnum === 29).forEach((loan: any) =>
              cards.push({
                id: `${loan?.productId}`,
                key: loan?.productId,
                title: "CIM Personal Loan",
                //title: loan?.productDescription,
                url: TOP_UP_DESC,
                minMax: {
                  min: Number(loan?.minAmount ?? '0'),
                  max: Number(loan?.maxAmount ?? '0'),
                },
                extraData: {
                  optLabel: loan?.subProductDescription,
                  optItems: loan?.loanSubTypeDtos ?? [],
                },
              })
            );
          }
          setPageConfig((prev) => ({ ...prev, loading: false, cards }));
        }
      } catch (error) {}
    };

    if (firstTime.current) {
      firstTime.current = false;
      listFavorites();
    }
  }, [accountNumber, firstTime, pageConfig.cards, setUser, t, user, title]);

  return <TiledPage config={pageConfig} />;
};

export default TopUpTypes;
