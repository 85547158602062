import styled from 'styled-components';
import tw from 'twin.macro';

export const UserProfileWrapper = styled.div`
  ${tw`flex w-full justify-end items-center h-20 ml-5 sm:ml-0`};

  @media (max-width: 767px) {
    flex: 60% !important;
  }

  .profile-name {
    @media (max-width: 499px) {
      width: 75%;
    }
  }
`;

export const Avatar = styled.div`
  ${tw`flex items-center rounded-full bg-gray-100 justify-center ml-3 border-solid border-2 border-white shadow-xs`};
  width: 48px;
  height: 48px;

  img {
    ${tw`w-full rounded-full`};
  }
`;
