import { DefaultTheme } from 'styled-components';

interface IStyledTheme extends DefaultTheme {
  activeTheme: string;
}

const theme = {
  breakpoints: {
    xs: 0,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
  },

  main: '#DA2128',
  white: '#fff',
  gray_100: '#fbfbfb',
  gray_200: '#f4f4f4',
  gray_300: '#d1d1d1',
  gray_400: '#919191',
  gray_500: '#595959',
  gray_600: '#555555',
  gray_700: '#4d4d4d',
  gray_800: '#231f20',

  colorsBrand: {
    primary: ' #DA2128',
  },

  themePrimary: {
    primary: '#E62C7B',
    primary_hover: '#dc2171',
    primary_dark: '#5C1231',
    stepper_border: '#F7B9D7',
    stepper_inside: '#FDECF4',
  },

  themeSecondary: {
    primary: '#3db5e7',
    primary_hover: '#33a2d0',
    primary_dark: '#18485C',
    stepper_border: '#c5e9f8',
    stepper_inside: '#ecf8fd',
  },

  header: {
    heightXs: '50px',
    heightSm: '50px',
    heightMd: '60px',
    heightLg: '80px',
  },
  activeTheme: '',
};

export default theme;
