import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import tw from 'twin.macro';
import { Input } from '../../containers/form/form';

export const ContentHeaderWrap = styled.div`
  ${tw`flex justify-between mb-8`}
`;

export const BackButtonCol = styled.div`
  ${tw`mr-auto`}

  img {
    ${tw`cursor-pointer`}
  }
`;

export const SearchInputCol = styled.div`
  ${tw`ml-auto`}
`;

export const SearchInput = styled(Input)`
  background-image: url('/../assets/images/search-icon.png') !important;
  background: no-repeat 18px center;
  padding-left: 55px !important;
  border-radius: 5px;
  ${tw`text-base`}

  ${breakpoint('lg')`
    min-width: 340px;
  `}
`;
