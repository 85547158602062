import { ThemeProvider } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { useAppThemeContext } from '../context/appThemeContext';
import { getMuiTheme } from './themeConfigs';

interface IMuiThemeProvider {
  children: ReactNode;
}

const MuiThemeProvider = ({ children }: IMuiThemeProvider) => {
  const { theme } = useAppThemeContext();

  const activeTheme = getMuiTheme(theme);

  return (
    <ThemeProvider theme={activeTheme}>
      {/* <CssBaseline /> */}
      {children}
    </ThemeProvider>
  );
};

export default MuiThemeProvider;
