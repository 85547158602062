import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import tw from 'twin.macro';
import { breakpoint } from 'styled-components-breakpoint';

export const CardWrapper = styled.div`
  ${tw`w-full flex justify-center lg:justify-start flex-wrap`}
`;

export const CardContent = styled(NavLink)`
  ${tw`w-full text-black border border-gray-200 border-solid bg-white flex justify-start flex-wrap lg:w-5/12 max-w-xs items-center cursor-pointer mx-6 xl:mx-5 mb-12 transition duration-200 ease-in-out`}
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  min-height: 210px;
  padding: 27px;
  min-width: 266px;

  &:hover {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.14);
  }

  .card-content-row {
    ${tw`w-full`}
    img {
      ${tw`float-right w-16`}
    }
    span.sub-header {
      font-weight: 300;
      font-size: 18px;
    }
  }
`;

export const LoanCardContent = styled(NavLink)`
  ${tw`w-full text-black border border-gray-200 border-solid bg-white flex justify-start flex-wrap lg:w-5/12 max-w-xs items-center cursor-pointer mx-6 xl:mx-5 mb-12 transition duration-200 ease-in-out`}
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  min-height: 210px;
  padding: 27px;
  min-width: 266px;

  &:hover {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.14);
  }

  .card-content-row {
    ${tw`w-full`}
    img {
      ${tw`float-right w-16`}
    }
  }
`;

export const NoRedirectCardContent = styled.div`
  ${tw`w-full text-black border border-gray-200 border-solid bg-white flex justify-start max-w-xs items-center cursor-pointer mx-6 mb-12 transition duration-200 ease-in-out`}
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  min-height: 210px;
  padding: 27px;
  min-width: 266px;

  &:hover {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.14);
  }
`;

export const CardButtonWrap = styled.div`
  ${tw`bg-transparent`}
`;
export const BButton = styled.div`
  font-size: 14px;
`;

export const ActionCardWrap = styled.div`
  ${tw`relative lg:w-5/12 max-w-xs mx-6 mb-12`}
  min-width: 266px;
`;

export const ActionCardContent = styled(NavLink)`
  ${tw`w-full text-black border border-gray-200 border-solid bg-white flex justify-between items-start cursor-pointer flex-col  transition duration-200 ease-in-out`}
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  min-height: 210px;
  padding: 27px;

  &:hover {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.14);
  }
`;

export const AlternateActionCardContent = styled(ActionCardContent)`
  ${tw`relative lg:w-5/12 max-w-xs mx-6 mb-12`}
  max-width: 350px;
`;

export const ActionCardBottom = styled.div`
  ${tw`w-full flex justify-start items-start cursor-pointer pt-4`}
`;

export const ActionCardTop = styled.div`
  ${tw`w-full relative flex justify-between`}
`;

export const Left = styled.div`
  ${tw`w-full pr-4`}
  h4 {
    ${tw`max-w-full text-base`}
    font-weight: 400;
  }

  span {
    ${tw`max-w-full text-xs`}
    color: #6C6C6C;
  }
`;

export const Right = styled.div`
  ${tw`w-24 flex items-center`}
  min-height: 84px;
  margin-right: 5%;

  img {
    ${tw`max-w-full w-16`}
  }

  .logo-enlarger {
    max-width: 150% !important;
    width: auto !important;
  }
`;

export const CardH2 = styled.h2`
  ${tw`text-2xl flex justify-start items-center border-l-2 border-solid pl-3 pr-5`}
  border-color: ${(props) => props.theme.colorsBrand.primary};
  line-height: 24px;
  font-weight: 400;
  min-height: 50px;
`;

export const CardH3 = styled.h3`
  ${tw`text-lg flex justify-start items-center border-l-2 border-solid pl-3 pr-5 mb-5`}
  border-color: ${(props) => props.theme.colorsBrand.primary};
  line-height: 20px;
  font-weight: 400;
  min-height: 25px;
`;

export const LoanCardH3 = styled.h3`
  ${tw`text-lg flex justify-start items-center border-l-2 border-solid pl-3 pr-5 mb-5`}
  border-color: ${(props) => props.theme.colorsBrand.primary};
  line-height: 20px;
  font-weight: 400;
  min-height: 25px;
`;

export const AlternateCardDetails = styled.div`
  p {
    ${tw`text-base mb-1`}
    :last-child {
      ${tw`mb-0`}
      justify-content: space-between;
      align-items: center;
    }
  }

  span {
    ${tw`mr-2`}
  }
`;

export const AvailableBalance = styled.div`
  ${tw`text-pink mt-10`}
  color: ${(props) =>
    props.colorTheme
      ? props.theme[props.colorTheme].primary_hover
      : props.theme[props.theme.activeTheme].primary_hover};

  p {
    ${tw`text-sm flex flex-wrap w-full`}
  }

  span {
    ${tw`text-base font-bold flex w-full `}
  }

  .last-row-capital-span {
    width: auto;
    padding-left: 32px;
  }

  .last-row-p {
    jusity-content: space-between;
    align-items: center;
  }
`;

export const PaymentFormCardHeader = styled.div`
  ${tw`mb-12`}
  ${CardH3} {
    ${tw`m-0 p-0 border-0`}
  }

  ${AlternateCardDetails} {
    ${tw`mt-8`}
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

export const OptionDropdown = styled.ul`
  background-color: #fdfdfd;
  max-width: 150px;
  min-width: 100px;
  text-align: center;
  padding: 20px 10px;
  opacity: 0;
  position: absolute;
  z-index: -1;
  left: -12px;
  box-shadow: 1px 7px 11px 3px #e4e4e4;
  transition: 0.1s ease-in all;

  li {
    text-transform: uppercase;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    cursor: pointer;
  }
`;

export const OptionDots = styled.div`
  ${tw`absolute w-1 h-1 rounded-full bg-gray-300`}
  &:before,
  &:after {
    content: '';
    ${tw` absolute w-1 h-1 rounded-full bg-gray-300`}
  }

  :after {
    right: -10px;
  }

  :before {
    left: -10px;
  }
`;

export const Options = styled.div`
  ${tw`absolute`}
  right: 30px;
  top: 30px;
  z-index: 1;

  .option-dots:hover {
    ul {
      opacity: 1;
      z-index: 99;
    }
  }
`;

export const WUDetailCardContent = styled.div`
  ${tw`transition-all duration-200 ease-in-out w-full text-black border border-gray-200 border-solid bg-white flex justify-between lg:w-5/12 max-w-xs items-center cursor-pointer mx-6 xl:mx-5 mb-2 transition duration-200 ease-in-out`}
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  padding: 15px;
  min-width: 266px;
  font-size: 14px;
  border-radius: 5px;

  ${breakpoint('md')` border-radius: 0px;`}

  svg {
    margin-left: 1rem;
    align-self: start;
    fill: ${(props) => props.theme[props.theme.activeTheme].primary};

    ${breakpoint('md')` display: none;`}
  }

  &:hover {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.14);
  }

  table {
    width: 100%;
    line-height: 25px;
  }

  .bold {
    font-weight: 600;
  }

  .right {
    text-align: right;
    text-transform: capitalize;

    .PENDING {
      color: darkorange;
    }
    .FAILED {
      color: #da2129;
    }
    .SUCCESS {
      color: green;
    }

    small {
      font-size: 10px;
      color: #000;
    }
  }
`;

export const WUCardContent = styled(NavLink)`
  ${tw`w-full text-black border border-gray-200 border-solid bg-white flex justify-start flex-wrap lg:w-5/12 max-w-xs items-center cursor-pointer mx-6 xl:mx-5 mb-12 transition duration-200 ease-in-out`}
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  min-height: 210px;
  padding: 27px;
  min-width: 266px;
  border-radius: 5px;

  ${breakpoint('md')` border-radius: 0px;`}

  h5 {
    ${tw`text-2xl flex justify-start items-center pl-3 pr-5`}
    line-height: 24px;
    font-weight: 400;
    min-height: 50px;
    border-color: ${(props) => props.theme.colorsBrand.primary};

    ${breakpoint('md')`${tw`border-l-2 border-solid`}`}
  }

  &:hover {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.14);
  }

  .card-content-row {
    ${tw`w-full`}
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      margin-right: 1rem;
      display: block;
      ${breakpoint('md')`      
      display: none;
      `}
    }
  }
`;

export const DottedGrayCardContent = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  border: 2px dotted #999; /* Change the border color to your desired shade of gray */
`;

export const GrayCardContent = styled.div`
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const GrayCardTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
`;

export const GrayCardText = styled.p`
  font-size: 14px;
`;

export const GrayCardSubText = styled.p`
  font-size: 12px;
`;

export const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 7px; /* Adjust the margin between groups */
  & > ${GrayCardText} {
    margin-bottom: -7px; /* Adjust the margin between GrayCardText and GrayCardSubText */
  }
`;

export const GrayCardRadioInputGroup = styled.div`
  ${tw`mb-8`}
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; /* Align text and radio buttons to the top */
  width: 100%;
  margin-top: 10px;

  & > label {
    ${tw`block text-base`}
    margin-top: 10px;
    margin-right: 10px;
    font-weight: 600;
  }

  .radio-button-group {
    display: flex;
    flex-direction: column; /* Display radio buttons in a column */
    margin-top: 10px; /* Add some space between text and radio buttons */
    width: 100%;
  }
`;
