import { Alert, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ICardsPageConfig, {
  ICardConfig,
} from '../../../interfaces/ICardsPageConfigs';
import Card from '../../cards/card';
import { CardWrapper, ContentWrap, LoanCardH3 } from '../../cards/cardStyle';
import ContentHeader from '../../contentHeader/contentHeader';
import Loading from '../../loading/loading';

interface ITiledPageProps {
  config: ICardsPageConfig;
}

const TiledPage = ({ config }: ITiledPageProps) => {
  const [pageConfigs, setPageConfigs] = useState<ICardsPageConfig | null>(null);

  useEffect(() => {
    if (config) {
      setPageConfigs(config);
    }
  }, [config]);

  return (
    <>
      {pageConfigs && (
        <ContentWrap>
          <Helmet>
            <title>{pageConfigs.docTitle}</title>
          </Helmet>
          <ContentHeader disableArrow={!pageConfigs.backArrow} />
          <div className="pl-8">
            <LoanCardH3>{pageConfigs.sectionTitle}</LoanCardH3>
          </div>
          {pageConfigs.loading ? (
            <Loading />
          ) : (
            <CardWrapper>
              {pageConfigs.cards.length > 0
                ? pageConfigs.cards.map(
                    ({
                      id,
                      key,
                      minMax,
                      title,
                      subTitle,
                      url,
                      extraData,
                    }: ICardConfig) => (
                      <Card
                        key={key}
                        title={title}
                        subTitle={subTitle}
                        url={url}
                        id={id}
                        minMax={minMax}
                        extraData={extraData}
                      />
                    )
                  )
                : pageConfigs.info && (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                      <Alert severity="info">{pageConfigs.infoMessage}</Alert>
                    </Stack>
                  )}
            </CardWrapper>
          )}
        </ContentWrap>
      )}
    </>
  );
};

export default TiledPage;
