import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useTheme } from '@mui/material/styles';
import { IPaletteOptions } from '../../interfaces/ITheme';

import {
  CardH3,
  AlternateCardDetails,
  AvailableBalance,
  BButton,
} from './cardStyle';
import { setThousandSeparator } from '../../utils/helpers';
import { Box, Button, Card } from '@mui/material';
import { Link } from 'react-router-dom';

const FixedDepositActionCard = ({
  title,
  facilityNo,
  vehicleNo,
  dueDate,
  url,
  acNo,
  acOpenedBranch,
  acOpenedDate,
  availableBalance,
  ticketNo,
  capital,
  grantDate,
  additionalContent,
  dueAmount,
  branch,
  depositStartDate,
  topUpAmount,
  loanOutstandingAmount,
  availableLoanAmount,
  interestRate,
  capitalAmount,
  cardConfig,
}) => {
  const data = {
    title: title,
    ticketNo: ticketNo,
    capital: capital,
    grantDate: grantDate,
    acNo: acNo,
    acOpenedBranch: acOpenedBranch,
    acOpenedDate: acOpenedDate,
    additionalContent: additionalContent,
    facilityNo: facilityNo,
    bspCode: additionalContent?.bspCode,
    maturityDate: additionalContent?.maturityDate,
    dueAmount: additionalContent?.dueAmount,
    vehicleNumbers: additionalContent?.vehicleNumber,
    facilityAmount: additionalContent?.facilityAmount,
    rentalAmount: additionalContent?.rentalAmount,
    cardConfig: cardConfig,
  };

  const { t } = useTranslation();
  const [isShowButtonClicked, setIsShowButtonClicked] = useState(false);
  const activePalette = useTheme().palette;
  const styles = {
    longButton: {
      borderRadius: 50,
      width: 303,
      height: 31,
      margin: 5,
      color: 'black',
      borderWidth: 2,
      borderColor: activePalette.primary_default,
    },
    shortButton: {
      borderRadius: 50,
      width: 100,
      height: 31,
      color: 'black',
      borderWidth: 2,
      borderColor: activePalette.primary_default,
    },
  };

  return (
    <>
      <Card variant="outlined" style={{ margin: 5, width: 350, padding: 5 }}>
        {title && <CardH3>{title}</CardH3>}
        <AlternateCardDetails>
          {facilityNo && (
            <p>
              {t('lb_service_lbl_facilityno')} : {facilityNo}
            </p>
          )}
          {vehicleNo && (
            <p>
              {t('lb_service_lbl_vehicle_no')} : {vehicleNo}
            </p>
          )}
          {branch && (
            <p>
              {t('branch')} : {branch}
            </p>
          )}
          {dueDate && (
            <p>
              {t('lb_service_lbl_due_date')} : {dueDate}
            </p>
          )}
          {acNo && (
            <p>
              {t('account_number')} : {acNo}
            </p>
          )}
          {acOpenedBranch && (
            <p>
              {t('lb_services_account_opened_branch')} : {acOpenedBranch}
            </p>
          )}
          {acOpenedDate && (
            <p>
              {t('Account Opened Date')} : {acOpenedDate}
            </p>
          )}
          {ticketNo && (
            <p>
              {t('facility_no')} : {ticketNo}
            </p>
          )}
          {capital && (
            <p>
              {t('capital')} :{' '}
              {`${t('input_rupee')} ${setThousandSeparator(capital)}`}
            </p>
          )}
          {grantDate && (
            <p>
              {t('grant_date')} : {grantDate}
            </p>
          )}
          {dueAmount >= 0 && (
            <p>
              {t('due_amount_text')} : {setThousandSeparator(dueAmount)}
            </p>
          )}
          {depositStartDate && (
            <p>
              {t('deposite_start_date')} : {depositStartDate}
            </p>
          )}
          {interestRate && (
            <p>
              {t('interest_rate')} : {interestRate}%
            </p>
          )}
          {availableBalance && (
            <AvailableBalance>
              <p>
                {t('available_balance')}:
                <span className="last-row-capital-span">
                  {`${t('input_rupee')} ${setThousandSeparator(
                    availableBalance
                  )}`}
                </span>
              </p>
            </AvailableBalance>
          )}
          {capitalAmount && (
            <AvailableBalance>
              <p className="last-row-p">
                {t('capital_amount')}:
                <span className="last-row-capital-span">
                  {t('rupee')} {setThousandSeparator(capitalAmount)}
                </span>
              </p>
            </AvailableBalance>
          )}
          {topUpAmount && (
            <div
              style={{
                flexFlow: 'row',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                paddingRight: '2%',
              }}
            >
              <div style={{ color: 'gray', fontSize: 12, textAlign: 'left' }}>
                {t('top-up-amount')}
              </div>
              <div style={{ color: 'gray', fontSize: 12, textAlign: 'center' }}>
                :
              </div>
              <div style={{ color: 'gray', fontSize: 12, textAlign: 'right' }}>
                {topUpAmount}
              </div>
            </div>
          )}
          {cardConfig?.requestByWallet && availableLoanAmount > 0 && (
            <div
              style={{
                flexFlow: 'row',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                paddingRight: '2%',
              }}
            >
              <div style={{ color: 'gray', fontSize: 12, textAlign: 'left' }}>
                {t('available_loan_amount')}
              </div>
              <div style={{ color: 'gray', fontSize: 12, textAlign: 'center' }}>
                :
              </div>
              <div style={{ color: 'gray', fontSize: 12, textAlign: 'right' }}>
                {t('input_rupee') + setThousandSeparator(availableLoanAmount)}
              </div>
            </div>
          )}
          <Box textAlign="center">
            <table style={{ width: '100%' }}>
              <tbody>
                {!isShowButtonClicked && (
                  <tr>
                    <td>
                      <Button
                        variant="outlined"
                        size="medium"
                        style={styles.shortButton}
                        endIcon={<KeyboardArrowDownIcon />}
                        onClick={() => setIsShowButtonClicked(true)}
                      >
                        {t('more')}
                      </Button>
                    </td>
                  </tr>
                )}

                {isShowButtonClicked && (
                  <>
                    <tr>
                      <td>
                        <Link
                          to={{
                            pathname: '/fixed-deposit-details',
                            state: data,
                          }}
                        >
                          <Button
                            variant="outlined"
                            size="medium"
                            style={styles.longButton}
                          >
                            <BButton>{t('fd_view')}</BButton>
                          </Button>
                        </Link>
                      </td>
                    </tr>
                    {cardConfig?.requestByWallet && (
                      <>
                        <tr>
                          <td>
                            <Link
                              to={{
                                pathname: '/fixed-deposits-withdrawal',
                                state: { cardConfig, additionalContent },
                              }}
                            >
                              <Button
                                variant="outlined"
                                size="medium"
                                style={styles.longButton}
                              >
                                <BButton>{t('withdraw')}</BButton>
                              </Button>
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link
                              to={{
                                pathname: '/fixed-deposits-topup',
                                state: { cardConfig, additionalContent },
                              }}
                            >
                              <Button
                                variant="outlined"
                                size="medium"
                                style={styles.longButton}
                              >
                                <BButton>{t('top-up')}</BButton>
                              </Button>
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Link
                              to={{
                                pathname: '/edit-fixed-deposits',
                                state: {
                                  cardConfig,
                                  additionalContent,
                                  thisCycle: false,
                                },
                              }}
                            >
                              <Button
                                variant="outlined"
                                size="medium"
                                style={styles.longButton}
                              >
                                <BButton>{t('edit_instructions')}</BButton>
                              </Button>
                            </Link>
                          </td>
                        </tr>
                        {availableLoanAmount > 0 && (
                          <tr>
                            <td>
                              <Link
                                to={{
                                  pathname: '/fixed-deposits-loan',
                                  state: {
                                    cardConfig,
                                    additionalContent,
                                    thisCycle: false,
                                  },
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  size="medium"
                                  style={styles.longButton}
                                >
                                  <BButton>{t('request_fd_loan')}</BButton>
                                </Button>
                              </Link>
                            </td>
                          </tr>
                        )}
                      </>
                    )}

                    <tr>
                      <td>
                        <Button
                          variant="outlined"
                          size="medium"
                          style={styles.shortButton}
                          endIcon={<KeyboardArrowUpIcon />}
                          onClick={() => setIsShowButtonClicked(false)}
                        >
                          <BButton>{t('hide')}</BButton>
                        </Button>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </Box>
        </AlternateCardDetails>
      </Card>
    </>
  );
};

FixedDepositActionCard.propTypes = {
  title: PropTypes.any,
  url: PropTypes.string,
  facilityNo: PropTypes.string,
  vehicleNo: PropTypes.array,
  dueDate: PropTypes.string,
  acNo: PropTypes.string,
  acOpenedBranch: PropTypes.string,
  acOpenedDate: PropTypes.string,
  availableBalance: PropTypes.string,
  ticketNo: PropTypes.string,
  capital: PropTypes.string,
  grantDate: PropTypes.string,
  bspCode: PropTypes.string,
  branch: PropTypes.string,
  depositStartDate: PropTypes.string,
  interestRate: PropTypes.number,
  capitalAmount: PropTypes.number,
  dueAmount: PropTypes.any,
  additionalContent: PropTypes.any,
  cardConfig: PropTypes.any,
};

export default FixedDepositActionCard;
