import tw from 'twin.macro';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { FormInputGroup, SelectDropdown } from '../../containers/form/form';

export const RegisterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

export const RegistrationFormHeader = styled.div`
  display: flex;
`;

export const RegisterFormContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 23px 25px;

  ${breakpoint('md')`
    height: 92%;
    padding: 23px 40px;
  `}

  ${breakpoint('lg')`
    height: 92%;
    padding: 23px 69px 100px 69px;
  `}

  .rs-steps-horizontal {
    width: 462px;
  }
}
`;

export const RegisterFormTitle = styled.div`
  padding-left: 1em;
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: ${(props) =>
    props.colorTheme
      ? props.theme[props.colorTheme].primary
      : props.theme[props.theme.activeTheme].primary};
  height: 25px;
  margin-left: 28px;

  ${breakpoint('md')`
    margin-left: 70px;
  `}

  ${breakpoint('lg')`
    margin-left: 98px;
  `}

  h1 {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;

    ${breakpoint('md')`
      font-size: 20px;
    `}

    ${breakpoint('lg')`
      font-size: 20px;
    `}
  }
`;

export const RegistrationFormBack = styled.a`
  cursor: pointer;
`;

export const RegisterSubHeader = styled.div`
  ${tw`flex w-full justify-between items-center border-b border-gray-200 border-solid`};

  color: #4a4a4a;
  padding: 8px 18px;
  height: 50px;

  ${breakpoint('md')`
      height: 60px;
      padding: 8px 32px;
  `}

  ${breakpoint('lg')`
      height: 88px;
      padding: 8px 64px;
  `}

  > div {
    flex: 1;

    img {
      max-height: 20px;
      max-width: 80px;

      ${breakpoint('md')`
        max-height: 26px;
        max-width: 80px;
      `}

      ${breakpoint('lg')`
        max-height: 34px;
        max-width: 80px;
      `}
    }
  }
`;

export const RegisterSubHeaderMidContent = styled.div`
  ${tw`text-center`};

  @media (max-width: 767px) {
    flex: 30% !important;
  }
`;

export const RegistrationFormContent = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 39px;
`;

export const RegistrationStepTitle = styled.div``;

export const StepperComponent = styled.div`
  .rs-steps-horizontal {
    overflow: hidden;
    padding: 0 50px;

    ${breakpoint('xs')`
      width: 312px;
    `}

    ${breakpoint('md')`
      width: 462px;
    `}

    ${breakpoint('lg')`
      width: 462px;
    `}

    .rs-steps-item-content {
      border-color: ${(props) =>
        props.colorTheme
          ? props.theme[props.colorTheme].primary
          : props.theme[props.theme.activeTheme].primary};
      color: ${(props) =>
        props.colorTheme
          ? props.theme[props.colorTheme].primary
          : props.theme[props.theme.activeTheme].primary};
    },
    .rs-steps-item-status-process,
    .rs-steps-item-status-finish {
      .rs-steps-item-icon-wrapper:not(.rs-steps-item-custom-icon) {
        border-color: ${(props) =>
          props.colorTheme
            ? props.theme[props.colorTheme].primary
            : props.theme[props.theme.activeTheme].primary};
        background-color: ${(props) =>
          props.colorTheme
            ? props.theme[props.colorTheme].primary
            : props.theme[props.theme.activeTheme].primary};
        color: #fff;
      }

      .rs-steps-item-icon-wait {
        color: ${(props) =>
          props.colorTheme
            ? props.theme[props.colorTheme].primary
            : props.theme[props.theme.activeTheme].primary};
      }

      .rs-steps-item-title::after {
        border-color: ${(props) =>
          props.colorTheme
            ? props.theme[props.colorTheme].primary
            : props.theme[props.theme.activeTheme].primary};
      }
    }

    .rs-steps-item-status-finish {
      .rs-steps-item-icon::before {
        content: '\f00c';
        color: #ffffff;
      }
    }

    .rs-steps-item-title::after {
      content: '';
      top: 15px;
      left: 100%;
      width: 150px;
      border-top-width: 1px;
      border-top-style: solid;
    }

    .rs-steps-item-status-process {
      .rs-steps-item-title {
        border-top-color: ${(props) =>
          props.colorTheme
            ? props.theme[props.colorTheme].primary
            : props.theme[props.theme.activeTheme].primary};!important;
      }
    }

    .rs-steps-item {
      padding-left: 40px;
      position: relative;
      overflow: visible;

      :last-child {
        .rs-steps-item-title {
          border-top-width: 1px;
          border-top-style: solid;
          border-top-color: ${(props) =>
            props.colorTheme
              ? props.theme[props.colorTheme].primary
              : props.theme[props.theme.activeTheme].primary};
          top: 15px;
          left: 25%;
          width: 250px;
        }

        > .rs-steps-item-status-process {
          .rs-steps-item-title {
            border-top-color: ${(props) =>
              props.colorTheme
                ? props.theme[props.colorTheme].primary
                : props.theme[props.theme.activeTheme].primary}; !important;
          }
        }
      }

      .rs-steps-item-icon-wrapper {
        z-index: 1;
        background-color: #fff;
      }

      .rs-steps-item-content {
        height: 30px;
        position: absolute;
        left: -80px;

        ${breakpoint('md')`
          left: -131px;
        `}

        ${breakpoint('lg')`
          left: -131px;
        `}
      }
    }
  }
`;

export const StepComponent = styled.div``;

export const FormWrapper = styled.form`
  display: flex;
  flex-wrap: wrap;
  max-width: 390px;
  margin: 0 auto;
  width: 100%;
  padding: 3em 1em 1em 1em;

  ${breakpoint('md')`
    padding: 3em 1em 1em 1em;
  `}

  ${breakpoint('lg')`
    padding: 3em 1em 1em 1em;
  `}
`;

export const RegisterFormWrapper = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  margin-top: -15px;

  p:not(.error) {
    margin-top: 3em;
    margin-left: 1.7em;
    font-size: 18px;
    font-weight: bold;

    ${breakpoint('md')`
      font-size: 18px;
      font-weight: bold;
    `}

    ${breakpoint('xl')`
      margin-left: 7.6em;
    `}
  }
`;

export const RegisterFormInputGroup = styled(FormInputGroup)`
  flex: 0 0 100%;
  padding: 10px 10px 0px 20px;
  position: relative;

  .optionContainer {
    .highlight,
    .highlightOption {
      background-color: ${(props) =>
        props.colorTheme
          ? props.theme[props.colorTheme].primary
          : props.theme[props.theme.activeTheme].primary};
    }
  }

  .multiSelectContainer li:hover {
    background: ${(props) =>
      props.colorTheme
        ? props.theme[props.colorTheme].primary
        : props.theme[props.theme.activeTheme].primary};
  }

  ${breakpoint('md')`
    flex: 0 0 50%;
  `}

  ${breakpoint('lg')`
    flex: 0 0 50%;
  `}

  .react-datepicker-wrapper {
    display: flex !important;
  }

  &.house-num {
    flex: 0 0 35%;
    position: relative;

    ${breakpoint('md')`
      flex: 0 0 18%;
    `}

    ${breakpoint('lg')`
      flex: 0 0 15%;
    `}

    p.hint {
      top: 10px;
      left: 0;
      margin: 1.6em;
      white-space: nowrap;
      overflow: auto;
      position: absolute;
      font-size: 12px;
      color: #989898;
      font-weight: 100;

      ${breakpoint('md')`
        margin: 1.6em;
        left: 0;
      `}

      ${breakpoint('lg')`
        margin: 0.5em;
        left: 8.4em;
      `}
    }

    .hint-upper {
      top: 10% !important;
      margin: 1em 1.6em !important;

      ${breakpoint('md')`
        margin: 0 0.4em !important;
      `}
    }
  }

  &.image-capture {
    display: flex;
    flex-direction: column;

    label {
      flex-grow: 1;
    }
  }

  &.nic-capture {
    display: flex;
    flex-wrap: wrap;

    > div {
      flex: 0 0 50%;
    }

    .nic-front {
      flex: 0 0 48%;
      margin-right: 10px;
    }

    .nic-back {
      flex: 0 0 47%;
      margin-left: 0px;
    }
  }

  > svg.loader {
    margin: auto;
    background: transparent;
    position: absolute;
    top: 41%;

    &.error-loader {
      top: 35% !important;
    }
  }

  &.street {
    flex: 0 0 65%;

    ${breakpoint('md')`
      flex: 0 0 32%;
    `}

    ${breakpoint('lg')`
      flex: 0 0 35%;
    `}

    label {
      text-align: right;
    }
  }

  &.residential-check {
    align-items: center;
    justify-content: center;
    padding: 1em 1.5em 0px;

    ${breakpoint('lg')`
      padding: 3em 1.5em 0px;
    `}

    label {
      margin-bottom: 0.5em;
    }
  }
`;

export const RadioContainer = styled.div`
  padding-left: 50px;
  display: flex;
  justify-content: space-around;

  .register-radio {
    margin-right: 80px;

    ${breakpoint('lg')`
      margin-right: 130px;
    `}
  }
`;

export const RegistrationDropDown = styled(SelectDropdown)`
  direction: ltr;
  padding: 0px 50px 0 10px !important;
`;

export const FormAction = styled.div`
  ${tw`flex items-center justify-center p-6`}

  > svg {
    margin-left: auto !important;
  }
`;
