export const LOAN_REQUEST_LANDING = '/loans';
export const NEW_LOAN_LANDING = `${LOAN_REQUEST_LANDING}/loan-types`;
export const NEW_LOAN_DIGITAL_ROLLER_STATUS = `${LOAN_REQUEST_LANDING}/loan-status`;
export const NEW_LOAN_DIGITAL_ROLLER_DESC = `${LOAN_REQUEST_LANDING}/loan-description`;
export const NEW_LOAN_DESC = `${NEW_LOAN_LANDING}/description`;
export const NEW_LOAN_DIGITAL_ROLLER_REQUEST = `${LOAN_REQUEST_LANDING}/request-loan`;
export const NEW_LOAN_REQUEST = (segment: string = '*') =>
  `${NEW_LOAN_LANDING}/${segment}/request`;
export const TOP_UP_LOAN_LANDING = `${LOAN_REQUEST_LANDING}/top-up-loan-types`;
export const TOP_UP_DESC = `${LOAN_REQUEST_LANDING}/top-up-description`;
export const TOP_UP_LOAN_REQUEST = `${LOAN_REQUEST_LANDING}/top-up-loan-request`;
export const TOP_UP_REQUEST = `${LOAN_REQUEST_LANDING}/top-up-request`;
export const FIXED_DEPOSIT_PAGE = '/new-fixed-deposits';
export const FIXED_DEPOSIT_LISTING_PAGE = '/fixed-deposits';
export const CUSTOMER_LIMIT_REQUEST_PAGE = '/request-transaction-timit';
export const EDIT_FIXED_DEPOSIT_PAGE = '/edit-fixed-deposits';
export const FIXED_DEPOSIT_WITHDRAWAL_PAGE = '/fixed-deposits-withdrawal';
export const FIXED_DEPOSIT_TOPUP_PAGE = '/fixed-deposits-topup';
export const FIXED_DEPOSIT_LOAN_PAGE = '/fixed-deposits-loan';
export const FIXED_DEPOSIT_DETAILS = '/fixed-deposit-details';
