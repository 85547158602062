import React, { useEffect, useRef, useState } from 'react';
import { useUser } from '../../context/user-context';
import ApiService from '../../services/api/api';
import { Avatar, UserProfileWrapper } from './headerUserProfileStyles';

interface IUserProfileProps {
  fetchProfile: boolean;
}
const UserProfile = ({ fetchProfile }: IUserProfileProps) => {
  const [profilePicture, setProfilePicture] = useState<any>(null);
  const { user } = useUser();
  const accountNumber = user?.walletAccountNo;
  const firstTime = useRef(true);

  useEffect(() => {
    const profileInfo = async () => {
      try {
        const picture = await ApiService.getProfilePicture(accountNumber);
        if (picture.kind === 'ok') {
          setProfilePicture(picture.data);
        } else {
          setProfilePicture(null);
        }
      } catch (error) {}
    };
    if (firstTime.current && accountNumber && fetchProfile) {
      firstTime.current = false;
      profileInfo();
    }
  }, [firstTime, accountNumber, fetchProfile]);

  return (
    <UserProfileWrapper>
      <div className="profile-name">{user?.nickName || user?.holderName}</div>
      <Avatar>
        {profilePicture ? (
          <img
            src={`data:image/png;base64, ${profilePicture?.base64Image}`}
            alt="Profile"
          />
        ) : (
          <div></div>
        )}
      </Avatar>
    </UserProfileWrapper>
  );
};

export default UserProfile;
