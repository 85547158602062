import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { DEFAULT_THEME } from '../utils/constants';
import { ThemeTypes } from '../utils/enums';

export type AppThemeContextType = {
  theme: ThemeTypes;
  setTheme: Dispatch<SetStateAction<ThemeTypes>> | null;
};

const AppThemeContext = createContext<AppThemeContextType>({
  theme: DEFAULT_THEME,
  setTheme: null,
});

export const AppThemeContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [theme, setTheme] = useState<ThemeTypes>(DEFAULT_THEME);
  return (
    <AppThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </AppThemeContext.Provider>
  );
};

export const useAppThemeContext = () => useContext(AppThemeContext);
