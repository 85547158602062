import PropTypes from 'prop-types';
import React from 'react';
import Helmet from 'react-helmet';
import BoilerPlate from './BoilerPlate';

const DefaultLayout = ({ children, path }) => {
  return (
    <>
      <Helmet>
        {path && (
          <body
            className={`${path === '/' ? 'home' : path.replace('/', '')}`}
          />
        )}
      </Helmet>
      <BoilerPlate showProfile={true}>{children}</BoilerPlate>
    </>
  );
};

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired,
};

DefaultLayout.defaultProps = {
  path: '',
};

export default DefaultLayout;
