export const DristrictList = [
  {
    id: 29,
    code: '0019',
    name: 'AMPARA',
    typeEnum: 2,
    parentId: 7,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 20,
    code: '0024',
    name: 'ANURADHAPURA',
    typeEnum: 2,
    parentId: 4,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 30,
    code: '0004',
    name: 'BADULLA',
    typeEnum: 2,
    parentId: 8,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 28,
    code: '0021',
    name: 'BATTICALOA',
    typeEnum: 2,
    parentId: 7,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 18,
    code: '0002',
    name: 'COLOMBO',
    typeEnum: 2,
    parentId: 3,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 34,
    code: '0011',
    name: 'GALLE',
    typeEnum: 2,
    parentId: 9,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 17,
    code: '0001',
    name: 'GAMPAHA',
    typeEnum: 2,
    parentId: 3,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 32,
    code: '0009',
    name: 'HAMBANTOTA',
    typeEnum: 2,
    parentId: 9,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 10,
    code: '0014',
    name: 'JAFFNA',
    typeEnum: 2,
    parentId: 1,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 19,
    code: '0003',
    name: 'KALUTARA',
    typeEnum: 2,
    parentId: 3,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 23,
    code: '0006',
    name: 'KANDY',
    typeEnum: 2,
    parentId: 5,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 25,
    code: '0013',
    name: 'KEGALLE',
    typeEnum: 2,
    parentId: 6,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 11,
    code: '0018',
    name: 'KILINOCHCHI',
    typeEnum: 2,
    parentId: 1,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 16,
    code: '0022',
    name: 'KURUNEGALA',
    typeEnum: 2,
    parentId: 2,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 12,
    code: '0015',
    name: 'MANNAR',
    typeEnum: 2,
    parentId: 1,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 22,
    code: '0008',
    name: 'MATALE',
    typeEnum: 2,
    parentId: 5,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 33,
    code: '0010',
    name: 'MATARA',
    typeEnum: 2,
    parentId: 9,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 31,
    code: '0005',
    name: 'MONARAGALA',
    typeEnum: 2,
    parentId: 8,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 13,
    code: '0017',
    name: 'MULLAITIVU',
    typeEnum: 2,
    parentId: 1,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 24,
    code: '0007',
    name: 'NUWARA ELIYA',
    typeEnum: 2,
    parentId: 5,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 21,
    code: '0025',
    name: 'POLONNARUWA',
    typeEnum: 2,
    parentId: 4,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 15,
    code: '0023',
    name: 'PUTTALAM',
    typeEnum: 2,
    parentId: 2,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 26,
    code: '0012',
    name: 'RATNAPURA',
    typeEnum: 2,
    parentId: 6,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 27,
    code: '0020',
    name: 'TRINCOMALEE',
    typeEnum: 2,
    parentId: 7,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
  {
    id: 14,
    code: '0016',
    name: 'VAVUNIYA',
    typeEnum: 2,
    parentId: 1,
    effectiveDateTime: '2018-01-01T00:00:00',
    expiryDateTime: '2079-06-06T00:00:00',
  },
];
