import { createTheme } from '@mui/material/styles';
import { IPaletteOptions } from '../interfaces/ITheme';
import { ThemeTypes } from '../utils/enums';

const masterColors = {
  red: '#DA2128',
  black: '#575757',
  gray: '#616161',
  gray_hover: '#9e9e9e',
  secondary_text: '#595959',
  secondary_default: '#d1d1d1',
  primary_text: '#ffffff',
  link_color: '#60a5fa',
  link_color_hover: '#2563eb',
};

const customerThemePalette: IPaletteOptions = {
  ...masterColors,
  primary: {
    main: '#E62C7B'
  },
  primary_default: '#E62C7B',
  primary_hover: '#dc2171',
  primary_dark: '#5C1231',
  stepper_border: '#F7B9D7',
  stepper_inside: '#FDECF4',
};
const merchantThemePalette: IPaletteOptions = {
  ...masterColors,
  primary_default: '#3db5e7',
  primary_hover: '#33a2d0',
  primary_dark: '#18485C',
  stepper_border: '#c5e9f8',
  stepper_inside: '#ecf8fd',
};

export const getMuiTheme = (themeType: ThemeTypes = ThemeTypes.CustomerTheme) =>
  createTheme({
    palette:
      themeType === ThemeTypes.CustomerTheme
        ? customerThemePalette
        : merchantThemePalette,
  });
