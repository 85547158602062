import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  CardH3,
  AlternateCardDetails,
  AvailableBalance,
  AlternateActionCardContent,
} from './cardStyle';
import { setThousandSeparator } from '../../utils/helpers';
import { Button } from '@mui/material';

const AlternateActionCard = ({
  title,
  facilityNo,
  vehicleNo,
  dueDate,
  url,
  acNo,
  acOpenedBranch,
  acOpenedDate,
  availableBalance,
  ticketNo,
  capital,
  grantDate,
  additionalContent,
  dueAmount,
  branch,
  depositStartDate,
  interestRate,
  capitalAmount,
  cardConfig,
}) => {
  const data = {
    title: title,
    ticketNo: ticketNo,
    capital: capital,
    grantDate: grantDate,
    acNo: acNo,
    acOpenedBranch: acOpenedBranch,
    acOpenedDate: acOpenedDate,
    additionalContent: additionalContent,
    facilityNo: facilityNo,
    bspCode: additionalContent?.bspCode,
    maturityDate: additionalContent?.maturityDate,
    dueAmount: additionalContent?.dueAmount,
    vehicleNumbers: additionalContent?.vehicleNumber,
    facilityAmount: additionalContent?.facilityAmount,
    rentalAmount: additionalContent?.rentalAmount,
    cardConfig: cardConfig,
  };

  const { t } = useTranslation();

  return (
    <AlternateActionCardContent
      to={{
        pathname: url,
        state: data,
      }}
    >
      {title && <CardH3>{title}</CardH3>}
      <AlternateCardDetails>
        {facilityNo && (
          <p>
            {t('lb_service_lbl_facilityno')} : {facilityNo}
          </p>
        )}
        {vehicleNo && (
          <p>
            {t('lb_service_lbl_vehicle_no')} : {vehicleNo}
          </p>
        )}
        {branch && (
          <p>
            {t('branch')} : {branch}
          </p>
        )}
        {dueDate && (
          <p>
            {t('lb_service_lbl_due_date')} : {dueDate}
          </p>
        )}
        {acNo && (
          <p>
            {t('account_number')} : {acNo}
          </p>
        )}
        {acOpenedBranch && (
          <p>
            {t('lb_services_account_opened_branch')} : {acOpenedBranch}
          </p>
        )}
        {acOpenedDate && (
          <p>
            {t('Account Opened Date')} : {acOpenedDate}
          </p>
        )}
        {ticketNo && (
          <p>
            {t('facility_no')} : {ticketNo}
          </p>
        )}
        {capital && (
          <p>
            {t('capital')} :{' '}
            {`${t('input_rupee')} ${setThousandSeparator(capital)}`}
          </p>
        )}
        {grantDate && (
          <p>
            {t('grant_date')} : {grantDate}
          </p>
        )}
        {dueAmount >= 0 && (
          <p>
            {t('due_amount_text')} : {setThousandSeparator(dueAmount)}
          </p>
        )}
        {depositStartDate && (
          <p>
            {t('deposite_start_date')} : {depositStartDate}
          </p>
        )}
        {interestRate && (
          <p>
            {t('interest_rate')} : {interestRate}%
          </p>
        )}
        {availableBalance && (
          <AvailableBalance>
            <p>
              {t('available_balance')}:
              <span className="last-row-capital-span">
                {`${t('input_rupee')} ${setThousandSeparator(
                  availableBalance
                )}`}
              </span>
            </p>
          </AvailableBalance>
        )}
        {capitalAmount && (
          <AvailableBalance>
            <p className="last-row-p">
              {t('capital_amount')}:
              <span className="last-row-capital-span">
                {t('rupee')} {setThousandSeparator(capitalAmount)}
              </span>
            </p>
          </AvailableBalance>
        )}
        {cardConfig?.cardType == 'FIXED_DEPOSIT' && (
          <Button variant="outlined" size="medium">
            Withdraw
          </Button>
        )}
      </AlternateCardDetails>
    </AlternateActionCardContent>
  );
};

AlternateActionCard.propTypes = {
  title: PropTypes.any,
  url: PropTypes.string,
  facilityNo: PropTypes.string,
  vehicleNo: PropTypes.array,
  dueDate: PropTypes.string,
  acNo: PropTypes.string,
  acOpenedBranch: PropTypes.string,
  acOpenedDate: PropTypes.string,
  availableBalance: PropTypes.string,
  ticketNo: PropTypes.string,
  capital: PropTypes.string,
  grantDate: PropTypes.string,
  bspCode: PropTypes.string,
  branch: PropTypes.string,
  depositStartDate: PropTypes.string,
  interestRate: PropTypes.number,
  capitalAmount: PropTypes.number,
  dueAmount: PropTypes.any,
  additionalContent: PropTypes.any,
  cardConfig: PropTypes.any,
};

export default AlternateActionCard;
