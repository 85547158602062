import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router-dom';
import ApiService from '../../services/api/api';
import AlternateActionCard from '../../components/cards/AlternateActionCard';
import { CardWrapper, ContentWrap } from '../../components/cards/cardStyle';
import ContentHeader from '../../components/contentHeader/contentHeader';
import { useUser } from '../../context/user-context';
import Loading from '../../components/loading/loading';
import FixedDepositActionCard from '../../components/cards/FixedDepositActionCard';
import { setThousandSeparator } from '../../utils/helpers';

const Error = () => {
  const [fixedDeposits, setFixedDeposits] = useState();
  const [isNetWorkError, setIsNetworkError] = useState(false);
  const firstTime = useRef(true);
  const { user } = useUser();
  const accountNumber = user.walletAccountNo;
  const fixedDepositFacilityID = 4;
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(true);
  const { message } = useParams();
  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>{t('fixed_deposit')}</title>
      </Helmet>
      <ContentWrap>
        <ContentHeader disableArrow={true} />
        {message}
      </ContentWrap>
    </>
  );
};

export default Error;
