import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Wrapper = styled.div`
  /* height: 100%; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => props.theme.header.heightXs};

  ${LoginContent} {
    flex-grow: 1;
  }

  ${breakpoint('sm')`
    padding-top: ${(props) => props.theme.header.heightSm};
  `}

  ${breakpoint('md')`
      padding-top: ${(props) => props.theme.header.heightMd};
     
      flex: 1;
  `}

  ${breakpoint('lg')`
      padding-top: ${(props) => props.theme.header.heightLg};
  `}
`;

export const FooterWrapper = styled.div`
  background: ${(props) => props.theme.gray_200};
  box-shadow: inset 0px 2px 3px 0 rgb(0 0 0 / 20%);
  width: 100%;
  display: flex;
  align-items: center;
  display: none;

  ${breakpoint('sm')`padding: 20px 18px`}

  ${breakpoint('md')`
    padding: 26px 32px;
    display: flex;
  `}

  ${breakpoint('lg')`padding: 5px 72px 5px`}

  > div > div:first-child {
    max-width: 100%;

    ${breakpoint('sm')`max-width: 100%`}

    ${breakpoint('md')`max-width: 75%`}

    ${breakpoint('lg')`max-width: 45%`}
  }
`;
