import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import DefaultLayout from '../pages/_layouts/default';
import AuthLayout from '../pages/_layouts/auth';

import { useUser } from '../context/user-context';

interface RouteWrapperProps extends RouteProps {
  isPrivate?: boolean;
}

const RouteWrapper = ({
  component: Component,
  isPrivate = false,
  ...rest
}: RouteWrapperProps) => {
  const { user } = useUser();
  const signed = user?.otp ? true : false;
  const passwordExpired =
    user && user.authStatus === 'PASSWORD_EXPIRED' ? true : false;

  /**
   * Redirect user to change password screen if user password is expired
   */
  if (
    isPrivate &&
    signed &&
    passwordExpired &&
    rest.path !== '/change-password'
  ) {
    return <Redirect to="/change-password" />;
  }

  /**
   * Redirect user to SignIn page if he tries to access a private route
   * without authentication.
   */
  if (isPrivate && !signed) {
    return <Redirect to="/" />;
    // return (window.location.href = '/');
  }

  /**
   * Redirect user to Main page if he tries to access a non private route
   * (SignIn or SignUp) after being authenticated.
   */
  if (!isPrivate && signed) {
    return <Redirect to="/dashboard" />;
  }

  const Layout = signed ? DefaultLayout : AuthLayout;

  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  if (!Component) return <></>;
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout path={props.location.pathname}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouteWrapper;
