import React from 'react';

import { IconWrapper, SocialIcon } from './socialIconsStyle';

const icons = [
  {
    icon: 'fb-icon.svg',
    link: 'https://www.facebook.com/LBFinancePLC/',
    class: 'facebook',
  },
  {
    icon: 'youtube-icon.svg',
    link: 'https://www.youtube.com/channel/UCbzCHQ5od07bTFB3kMpXxPA',
    class: 'youtube',
  },
  {
    icon: 'twitter-icon.svg',
    link: 'https://twitter.com/LBFinancePLC',
    class: 'twitter',
  },
  {
    icon: 'instagram-icon.svg',
    link: 'https://www.instagram.com/lbfinanceplc/?hl=en',
    class: 'instagram',
  },
];

const socialIconsList = (iconList) => {
  const socialLinks = iconList.map((icon, index) => (
    <SocialIcon key={index}>
      <a
        href={icon.link}
        target="_blank"
        rel="noopener noreferrer"
        className={icon.class}
      >
        <img src={'/assets/images/' + icon.icon} alt="" />
      </a>
    </SocialIcon>
  ));

  return socialLinks;
};

const SocialIcons = () => {
  return <IconWrapper>{socialIconsList(icons)}</IconWrapper>;
};

export default SocialIcons;
