export const MonthlyIncome = [
  {
    id: 1,
    code: '00',
    description: 'Less than Rs. 50,000',
    data: 1,
  },
  {
    id: 2,
    code: '05',
    description: 'Rs. 50,001 to Rs. 100,000',
    data: 2,
  },
  {
    id: 3,
    code: '10',
    description: 'Rs. 100,001 to Rs. 250,000',
    data: 3,
  },
  {
    id: 4,
    code: '25',
    description: 'Rs. 250,001 to Rs. 500,000',
    data: 4,
  },
  {
    id: 5,
    code: '50',
    description: 'Rs. 500,001 to Rs. 1,000,000',
    data: 5,
  },
  {
    id: 6,
    code: '11',
    description: 'More than Rs. 1,000,001',
    data: 6,
  },
];
