import styled from 'styled-components';
import tw from 'twin.macro';

export const IconWrapper = styled.div`
  ${tw`flex items-center justify-between w-40`};
`;

export const SocialIcon = styled.div`
  ${tw`flex items-center px-4`};

  .facebook {
    width: 12px;
  }

  .youtube {
    width: 20px;
  }

  .twitter {
    width: 15px;
  }

  .instagram {
    width: 15px;
  }

  a {
    img {
      ${tw`p-0`};
    }
  }
`;
