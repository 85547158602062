import React, { FC, useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastProvider, DefaultToast } from 'react-toast-notifications';
import { AppThemeContextProvider } from './context/appThemeContext';
import { UserContextProvider } from './context/user-context';
import i18n from './i18n';
import Routes from './routes';
import GlobalStyles from './styles/global';
import MuiThemeProvider from './theme/MuiThemeProvider';
import StyledComponentThemeProvider from './theme/StyledComponentThemeProvider';
const App = () => (
  <I18nextProvider i18n={i18n}>
    <AppThemeContextProvider>
      <UserContextProvider>
        <Router>
          <ToastProvider placement="bottom-left">
            <StyledComponentThemeProvider>
              <MuiThemeProvider>
                <Routes />
                <GlobalStyles />
              </MuiThemeProvider>
            </StyledComponentThemeProvider>
          </ToastProvider>
        </Router>
      </UserContextProvider>
    </AppThemeContextProvider>
  </I18nextProvider>
);

export default App;
