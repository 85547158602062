import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './i18n/locales/en.json';
import translationSI from './i18n/locales/si.json';
import translationTA from './i18n/locales/ta.json';

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    en: {
      translation: translationEN,
    },
    si: {
      translation: translationSI,
    },
    ta: {
      translation: translationTA,
    },
  },
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'development',

  // have a common namespace used around the full app
  ns: ['translation'],
  defaultNS: 'translation',

  keySeparator: false, // we use content as keys

  saveMissing: true,

  defaultValue: 'default',

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    useSuspense: false,
  },
});

export default i18n;
