import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { HidePassword, ShowPassword } from '../../../components/icons';
import Loading from '../../../components/loading/loading';
import CustomButton from '../../../components/shared/button/CustomButton';
import CustomModal from '../../../components/shared/dialog-modal/CustomModal';
import CustomRadio from '../../../components/shared/radio/CustomRadio';
import {
  Input,
  LoginFormError,
  SubmitButton,
} from '../../../containers/form/form';
import {
  PasswordInput,
  PasswordInputWrap,
} from '../../../containers/signIn/stylesSignIn';
import { DristrictList } from '../../../dataset';
import RegistrationApiService from '../../../services/api/registration-api';
import { generateMobileNumberForBackend } from '../../../utils/helpers';
import {
  RadioContainer,
  RegisterFormInputGroup,
  RegisterFormWrapper,
  RegistrationDropDown,
  StepComponent,
} from '../registerStyles';
import {
  RegisterFormSection,
  RegistrationStepAction,
  RegSpinnerWrapper,
} from './registrationStrepStyles';

export const RegisterExistingCustomer = (props) => {
  const { t } = useTranslation();
  const { register, handleSubmit, errors, reset, getValues, trigger } = useForm(
    {
      defaultValues: useMemo(() => {
        return props.formData;
      }, [props]),
    }
  );
  const { addToast } = useToasts();

  const {
    formData,
    setFormData,
    submitData,
    processing,
    userType,
    merchantCategories,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [district, setDistrict] = useState();
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [officerList, setOfficerList] = useState();
  const [isAcceptTnC, setIsAcceptTnC] = useState(false);

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealConfirmPwd, setRevealConfirmPwd] = useState(false);

  const [residentialDistrict, setResidentialDistrict] = useState();
  const [isResidentialCitiesLoading, setIsResidentialCitiesLoading] =
    useState(false);
  const [residentialCities, setResidentialCities] = useState();
  const [merchant_category, setMerchantCategory] = useState(
    formData.merchant_category_code
  );

  const [currentLat, setCurrentLat] = useState(0);
  const [currentLng, setCurrentLng] = useState(0);
  const [isLocationErrorShown, setIsLocationErrorShown] = useState(false);

  const [introduceCode, setIntroduceCode] = useState(0);

  const [isResidential, setIsResidential] = useState(true);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  navigator.geolocation.getCurrentPosition(
    (position) => {
      setCurrentLat(position.coords.latitude);
      setCurrentLng(position.coords.longitude);
    },
    (error) => {
      if (!isLocationErrorShown) {
        setIsLocationErrorShown(true);
      }
    }
  );

  const getAllBranches = useCallback(async () => {
    let response;
    try {
      response = await RegistrationApiService.getBranches();

      if (response.kind === 'ok') {
        setBranches(response.data.lbBranchDtoList);
      }
    } catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const selectedBranchDetail = branches.filter(
      (branch) => branch.branchCode === selectedBranch
    );

    if (selectedBranchDetail.length > 0) {
      setOfficerList(selectedBranchDetail[0].lbMarketingOfficerList);
    }
  }, [branches, selectedBranch]);

  useEffect(() => {
    if (branches.length > 0) return;

    getAllBranches();
  }, [branches, getAllBranches]);

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  const retrieveCities = useCallback(
    async (districtId) => {
      let response;

      try {
        response = await RegistrationApiService.getCitiesForDistrict(
          districtId
        );

        if (response.kind === 'ok') {
          setIsCitiesLoading(false);
          setIsResidentialCitiesLoading(false);
          return response.data.cities;
        } else if (response.kind === 'rejected') {
          setIsCitiesLoading(false);
          setIsResidentialCitiesLoading(false);
          addToast(response.data.data.message, {
            appearance: 'error',
            autoDismiss: true,
          });
          return [];
        }
      } catch (error) {
        setIsCitiesLoading(false);
        setIsResidentialCitiesLoading(false);
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
    [addToast]
  );

  /**
   * fetch cities for district
   */
  useEffect(() => {
    if (!district) return;
    const getCities = async () => {
      return await retrieveCities(district);
    };
    getCities().then((response) => {
      setCities(response);
    });
  }, [district, retrieveCities]);

  /**
   * Fetch residential cities
   */
  useEffect(() => {
    if (!residentialDistrict) return;
    const getCities = async () => {
      return await retrieveCities(residentialDistrict);
    };
    getCities().then((response) => {
      setResidentialCities(response);
    });
  }, [residentialDistrict, retrieveCities]);

  return (
    <StepComponent>
      <RegisterFormWrapper
        autoComplete="off"
        onSubmit={handleSubmit((data) => {
          if ([19, 20, 21].includes(Number(userType))) {
            data.lat = currentLat;
            data.lng = currentLng;
          }

          const frontEndForm = {
            ...formData,
            ...data,
          };
          const backEndForm = {
            ...formData,
            ...data,
            mobile_no: generateMobileNumberForBackend(data.mobile_no),
            livingCity: isResidential ? data.city : data.livingCity,
            introducerCode:
              parseInt(introduceCode) === 1
                ? generateMobileNumberForBackend(data.introducerCode)
                : data.introducerCode,
          };

          setFormData(frontEndForm);
          submitData(backEndForm);
        })}
      >
        <p>Personal Information</p>
        <RegisterFormSection>
          <RegisterFormInputGroup>
            <label>{t('nick_name')}</label>
            <Input
              type="text"
              name="nick_name"
              autoComplete="off"
              ref={register()}
            />
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('emailaddress')}</label>
            <Input
              type="text"
              name="email"
              autoComplete="off"
              onChange={async () => await trigger('email')}
              ref={register({
                pattern: /\S+@\S+\.\S+/,
              })}
            />
            {errors.email && errors.email.type === 'pattern' && (
              <LoginFormError className="error">
                {t('error_invalid_email_address')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>

          <RegisterFormInputGroup>
            <label>{t('pin')}</label>
            <PasswordInputWrap>
              <PasswordInput
                type={isRevealPwd ? 'text' : 'password'}
                name="password"
                inputMode="decimal"
                placeholder="PIN *"
                onChange={async () => {
                  await trigger('repassword');
                  await trigger('password');
                }}
                ref={register({
                  required: 'PIN is required',
                  pattern: /^([0-9]{6})$/m,
                  type: { value: 'number', message: 'Invalid PIN' },
                  maxLength: { value: 6, message: 'Invalid PIN' },
                  validate: (value) =>
                    value.toUpperCase() === getValues().password.toUpperCase(),
                })}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                autoComplete="new-password"
              />

              {isRevealPwd ? (
                <HidePassword
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  className="show-hide-icon"
                />
              ) : (
                <ShowPassword
                  onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  className="show-hide-icon"
                />
              )}
            </PasswordInputWrap>
            {errors['password'] && errors.password.type === 'required' && (
              <LoginFormError className="error">
                {' '}
                {t('error_empty_pin_number')}{' '}
              </LoginFormError>
            )}
            {errors['password'] &&
              (errors.password.type === 'pattern' ||
                errors.password.type === 'maxLength') && (
                <LoginFormError className="error">
                  {' '}
                  {t('error_invalid_pin_number')}{' '}
                </LoginFormError>
              )}
          </RegisterFormInputGroup>

          <RegisterFormInputGroup>
            <label>{t('confirm_pin')}</label>
            <PasswordInputWrap>
              <PasswordInput
                type={isRevealConfirmPwd ? 'text' : 'password'}
                name="repassword"
                onChange={async () => await trigger('repassword')}
                inputMode="decimal"
                placeholder="PIN *"
                ref={register({
                  required: 'PIN is required',
                  type: { value: 'number', message: 'Invalid PIN' },
                  validate: (value) =>
                    value.toUpperCase() === getValues().password.toUpperCase(),
                })}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                autoComplete="new-password"
              />

              {isRevealConfirmPwd ? (
                <HidePassword
                  onClick={() => setRevealConfirmPwd((prevState) => !prevState)}
                  className="show-hide-icon"
                />
              ) : (
                <ShowPassword
                  onClick={() => setRevealConfirmPwd((prevState) => !prevState)}
                  className="show-hide-icon"
                />
              )}
            </PasswordInputWrap>
            {errors['repassword'] && errors.repassword.type !== 'validate' && (
              <LoginFormError className="error">
                {' '}
                {t('error_empty_confirm_pin')}{' '}
              </LoginFormError>
            )}
            {errors.repassword && errors.repassword.type === 'validate' && (
              <LoginFormError className="error">
                {t('error_confirm_pin_mismatch')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>

          <RegisterFormInputGroup>
            <label>{t('mothers_maiden_name')}</label>
            <Input
              type="text"
              name="mothers_maiden_name"
              autoComplete="off"
              ref={register({
                required: t('error_empty_mothers_maiden_name'),
              })}
            />
            {errors['mothers_maiden_name'] && (
              <LoginFormError className="error">
                {' '}
                {t('error_empty_mothers_maiden_name')}{' '}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('mobile_number')}</label>
            <Input
              type="text"
              name="mobile_no"
              placeholder="07XXXXXXXX"
              onChange={async () => await trigger('mobile_no')}
              autoComplete="off"
              ref={register({
                required: true,
                pattern: /^07\d{8}$/m,
              })}
            />
            {errors.mobile_no && errors.mobile_no.type === 'required' && (
              <LoginFormError className="error">
                {t('error_empty_mobile_no')}
              </LoginFormError>
            )}
            {errors.mobile_no && errors.mobile_no.type === 'pattern' && (
              <LoginFormError className="error">
                {t('error_invalid_mobile_no')}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup
            onChange={(event) => {
              setIsCitiesLoading(true);
              setDistrict(event.target.value);
            }}
          >
            <label>{t('district')}</label>
            <RegistrationDropDown
              name="district"
              ref={register({
                required: t('error_empty_district'),
              })}
            >
              <option value="" />
              {DristrictList.map((districtObj) => (
                <option key={districtObj.id} value={districtObj.id}>
                  {districtObj.name}
                </option>
              ))}
            </RegistrationDropDown>
            {errors['district'] && (
              <LoginFormError className="error">
                {' '}
                {t('error_empty_district')}{' '}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('city')}</label>
            <RegistrationDropDown
              name="city"
              ref={register({
                required: t('error_empty_city'),
              })}
              disabled={isCitiesLoading}
            >
              <option value="" />
              {cities.map((cityObj) => (
                <option key={cityObj.id} value={cityObj.id}>
                  {cityObj.name}
                </option>
              ))}
            </RegistrationDropDown>
            {isCitiesLoading && (
              <Loading
                className={`loader ${
                  Object.entries(errors).length > 0 ? 'error-loader' : ''
                }`}
              />
            )}
            {errors['city'] && (
              <LoginFormError className="error">
                {' '}
                {t('error_empty_city')}{' '}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>

          {(parseInt(userType) === 19 ||
            parseInt(userType) === 20 ||
            parseInt(userType) === 21) && (
            <>
              <RegisterFormInputGroup>
                <label>{t('merchant_category')}</label>
                <RegistrationDropDown
                  name="merchant_category_code"
                  ref={register({
                    required: t('error_empty_merchant_category'),
                  })}
                  onChange={(v) => {
                    setMerchantCategory(v.target.value);
                  }}
                >
                  <option value="" />
                  {merchantCategories.map((merchantObj) => (
                    <option key={merchantObj.code} value={merchantObj.code}>
                      {merchantObj.description}
                    </option>
                  ))}
                </RegistrationDropDown>

                {errors['city'] && (
                  <LoginFormError className="error">
                    {' '}
                    {t('error_empty_city')}{' '}
                  </LoginFormError>
                )}
              </RegisterFormInputGroup>
              {merchant_category > 0 && (
                <RegisterFormInputGroup>
                  <label>
                    {parseInt(merchant_category) === 738 ||
                    parseInt(merchant_category) === 739
                      ? t('merchant_category_referance_threewheel')
                      : t('merchant_category_referance')}
                  </label>
                  <Input
                    type="text"
                    name="merchant_category_reference"
                    autoComplete="off"
                    ref={register()}
                  />
                </RegisterFormInputGroup>
              )}
              <RegisterFormInputGroup>
                <label>Longtitude</label>
                <Input
                  type="text"
                  name="lng"
                  value={currentLng}
                  onChange={(value) => {
                    setCurrentLng(value.target.value);
                  }}
                  autoComplete="off"
                  ref={register()}
                />
              </RegisterFormInputGroup>
              <RegisterFormInputGroup>
                <label>Latitude</label>
                <Input
                  type="text"
                  name="lat"
                  value={currentLat}
                  onChange={(value) => {
                    setCurrentLat(value.target.value);
                  }}
                  autoComplete="off"
                  ref={register()}
                />
              </RegisterFormInputGroup>
            </>
          )}

          <RegisterFormInputGroup>
            <label>{t('is_above_your_address')}</label>
            <RadioContainer>
              <CustomRadio
                checked={isResidential}
                label={t('yes')}
                name="isresidential"
                onChange={() => {
                  setIsResidential(true);
                }}
              />
              <CustomRadio
                checked={!isResidential}
                label={t('no')}
                name="isresidential"
                onChange={() => {
                  setIsResidential(false);
                }}
              />
            </RadioContainer>
          </RegisterFormInputGroup>

          {!isResidential && (
            <>
              <RegisterFormInputGroup
                onChange={(event) => {
                  setIsResidentialCitiesLoading(true);
                  setResidentialDistrict(event.target.value);
                }}
              >
                <label>{t('residential_district')}</label>
                <RegistrationDropDown
                  name="living_district"
                  ref={register({ required: isResidential })}
                >
                  <option value="" />
                  {DristrictList?.map((districtObj) => (
                    <option key={districtObj.code} value={districtObj.id}>
                      {districtObj.name}
                    </option>
                  ))}
                </RegistrationDropDown>
              </RegisterFormInputGroup>
              <RegisterFormInputGroup>
                <label>{t('residential_city')}</label>
                <RegistrationDropDown
                  name="livingCity"
                  ref={register({ required: isResidential })}
                  disabled={isCitiesLoading}
                >
                  <option value="" />
                  {residentialCities?.map((cityObj) => (
                    <option key={cityObj.id} value={cityObj.id + ''}>
                      {cityObj.name}
                    </option>
                  ))}
                </RegistrationDropDown>
                {isResidentialCitiesLoading && <Loading className="loader" />}
              </RegisterFormInputGroup>
            </>
          )}
          <RegisterFormInputGroup>
            <label>{t('select_branch_name')}</label>
            <RegistrationDropDown
              name="branch_code"
              ref={register()}
              onChange={(event) => {
                setSelectedBranch(event.target.value);
              }}
            >
              <option value="" />
              {branches &&
                branches.map((branch) => (
                  <option key={branch.code} value={branch.branchCode}>
                    {branch.name}
                  </option>
                ))}
            </RegistrationDropDown>
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>
              {t('introduce_office')} {selectedBranch ? '*' : ''}
            </label>
            <RegistrationDropDown
              name="officer"
              ref={register({
                required: selectedBranch ? t('error_empty_title') : false,
              })}
            >
              <option value="" />
              {officerList &&
                officerList.map((officer) => (
                  <option key={officer.userId} value={officer.userId}>
                    {officer.name}
                  </option>
                ))}
            </RegistrationDropDown>
            {errors['officer'] && (
              <LoginFormError className="error">
                {' '}
                {'Please select an Introducing Officer'}{' '}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('introduce_code_type')}</label>
            <RegistrationDropDown
              name="introducerCodeType"
              onChange={(d) => {
                setIntroduceCode(Number(d.target.value));
              }}
              ref={register()}
            >
              <option value={null} />
              <option key={1} value={1}>
                Mobile Number
              </option>
              <option key={2} value={2}>
                Wallet Account No
              </option>
              <option key={3} value={3}>
                Agent Code
              </option>
              <option key={4} value={4}>
                EPF No
              </option>
              <option key={5} value={5}>
                NIC
              </option>
              <option key={6} value={-1}>
                OTHER
              </option>
            </RegistrationDropDown>
            {errors['introducerCodeType'] && (
              <LoginFormError className="error">
                {' '}
                {t('error_invalid_home_no')}{' '}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('introduce_code')}</label>
            <Input
              type="text"
              name="introducerCode"
              autoComplete="off"
              placeholder={parseInt(introduceCode) === 1 && '07XXXXXXXX'}
              ref={register()}
            />
          </RegisterFormInputGroup>
          <RegisterFormSection>
            <RegistrationStepAction
              className={`push-right ${processing && 'processing'}`}
            >
              <p>
                <input
                  type="checkbox"
                  checked={isAcceptTnC}
                  onChange={() => {
                    setIsAcceptTnC(!isAcceptTnC);
                  }}
                />
                {t('agree_terms')}
                <span onClick={openModal} className="terms-link">
                  {t('terms_and_conditions')}
                </span>
              </p>
              {processing && (
                <RegSpinnerWrapper>
                  <Loading />
                </RegSpinnerWrapper>
              )}
              <SubmitButton
                colorTheme={props.colorTheme}
                type="submit"
                disabled={!isAcceptTnC}
                value={t('next')}
              />
              <CustomModal
                id="existingCustomerRegistration"
                open={isOpen}
                onClose={closeModal}
                onBackdropClick={closeModal}
                maxWidth="lg"
                dialogContent={
                  <div
                    style={{ padding: 'inherit' }}
                    dangerouslySetInnerHTML={{
                      __html:
                        props.terms.body &&
                        props.terms.body
                          .replace(
                            /(<ol )/g,
                            '<ol style="padding-left: inherit" '
                          )
                          .replace(
                            /(<li)/g,
                            '<li style="padding-left: inherit" '
                          ),
                    }}
                  />
                }
                dialogActions={
                  <CustomButton label="Close" onClick={() => closeModal()} />
                }
              />
            </RegistrationStepAction>
          </RegisterFormSection>
        </RegisterFormSection>
      </RegisterFormWrapper>
    </StepComponent>
  );
};
