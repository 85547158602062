// @ts-ignore
import OTPInput, { ResendOTP } from 'otp-input-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  FormAction,
  FormInputGroup,
  FormMessageBoxSuccess,
} from '../../containers/form/form';
import { useUser } from '../../context/user-context';
import { IApiResponse } from '../../interfaces/IApiResponse';
import ApiService from '../../services/api/api';
import Loading from '../loading/loading';

interface IEnterOtpProps {
  OtpText: string;
  unVerifiedEmail?: boolean;
  userEmail?: string;
  selectedOTPmethod: string;
  registrationConfirmation?: boolean;
  handleVerifyOTP?: (val: string) => void;
}

const EnterOTP = (props: IEnterOtpProps) => {
  const { t } = useTranslation();
  const { user, setUser } = useUser();
  const { handleSubmit } = useForm();
  const { addToast } = useToasts();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showVisitEmailText, setShowVisitEmailText] = useState(false);
  const firstTime = useRef(true);
  const [OTPValue, setOTPValue] = useState('');
  const isMounted = useRef(false);

  const otpSubmit = useCallback(async () => {
    setLoading(true);
    try {
      if (
        /.+@.+\.[A-Za-z]+$/.test(props?.selectedOTPmethod) &&
        props?.unVerifiedEmail === true &&
        showVisitEmailText
      ) {
        // Email OTP Verifications
        const verifyOTP: IApiResponse =
          await ApiService.verifyEmailMobileOTPVerify(
            user?.walletAccountNo,
            OTPValue
          );

        if (verifyOTP.kind === 'ok') {
          setShowVisitEmailText(true);
          setUser({ ...user, otp: true });
          setOTPValue('');
          setLoading(false);
          addToast(`Your email has been verified successfully`, {
            appearance: 'success',
            autoDismiss: true,
          });
          //history.push('/dashboard');
        } else {
          setOTPValue('');
          setLoading(false);
          addToast(verifyOTP.data?.message || verifyOTP.problem, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      } else if (
        /.+@.+\.[A-Za-z]+$/.test(props?.selectedOTPmethod) &&
        props?.unVerifiedEmail === true
      ) {
        // Email OTP Verifications
        // Non Verify Email Mobile OTP Verifications

        const verifyOTP: IApiResponse =
          await ApiService.nonVerifyEmailMobileOTPVerify(
            user?.walletAccountNo,
            OTPValue
          );

        if (verifyOTP.kind === 'ok') {
          setShowVisitEmailText(true);
          setOTPValue('');
          setLoading(false);
        } else {
          setOTPValue('');
          setLoading(false);
          addToast(verifyOTP.data?.message || verifyOTP.problem, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      } else if (props.registrationConfirmation) {
        setLoading(false);
        props?.handleVerifyOTP && props.handleVerifyOTP(OTPValue);
      } else {
        // mobile otp confirmation
        const verifyOTP: IApiResponse = await ApiService.verifyOTP(
          user?.walletAccountNo,
          OTPValue
        );

        if (verifyOTP.kind === 'ok') {
          setOTPValue('');
          if (props.unVerifiedEmail) {
            setLoading(false);
            setShowVisitEmailText(true);
          } else {
            setUser({ ...user, otp: true });
            addToast('Login Success', {
              appearance: 'success',
              autoDismiss: true,
            });
            history.push('/dashboard');
          }
        } else {
          setOTPValue('');
          setLoading(false);
          addToast(verifyOTP.data?.message || verifyOTP.problem, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    } catch (e: any) {
      setLoading(false);
      addToast(e?.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    } finally {
      firstTime.current = true;
    }
  }, [OTPValue, addToast, history, props, setUser, showVisitEmailText, user]);

  const resendOtp = async (otpMethod: string) => {
    setLoading(true);

    if (props.registrationConfirmation) {
      try {
        const response: IApiResponse = await ApiService.resendOtp(
          user?.registrationId
        );

        if (response.kind === 'ok') {
          addToast('Your new OTP was sent', {
            appearance: 'success',
            autoDismiss: true,
          });
        } else {
          addToast(response.data?.message || response.problem, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      } catch (e: any) {
        addToast(e?.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      } finally {
        setLoading(false);
        setOTPValue('');
      }
    } else {
      try {
        if (/.+@.+\.[A-Za-z]+$/.test(otpMethod)) {
          //if user selects email

          if (user?.emailVerified) {
            //verified email
            const emailResponse: IApiResponse = await ApiService.sendOTP(
              user?.walletAccountNo,
              '2',
              otpMethod
            );

            if (emailResponse.kind === 'ok') {
              addToast('Your new OTP was sent', {
                appearance: 'success',
                autoDismiss: true,
              });
            } else {
              addToast(emailResponse.data?.message || emailResponse.problem, {
                appearance: 'error',
                autoDismiss: true,
              });
            }
          } else {
            //un-verified email*

            const emailResponse: IApiResponse = await ApiService.sendOTP(
              user?.walletAccountNo,
              '1',
              user?.mobile
            );

            if (emailResponse.kind === 'ok') {
              addToast('Your new OTP was sent', {
                appearance: 'success',
                autoDismiss: true,
              });
            } else {
              addToast(emailResponse.data?.message || emailResponse.problem, {
                appearance: 'error',
                autoDismiss: true,
              });
            }
          }
        } else {
          //user selects phone number

          const mobileResponse: IApiResponse = await ApiService.sendOTP(
            user?.walletAccountNo,
            '1',
            otpMethod
          );

          if (mobileResponse.kind === 'ok') {
            addToast('Your new OTP was sent', {
              appearance: 'success',
              autoDismiss: true,
            });
          } else {
            addToast(mobileResponse.data?.message || mobileResponse.problem, {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }
      } catch (e: any) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      } finally {
        setLoading(false);
        setOTPValue('');
      }
    }
  };

  useEffect(() => {
    document
      .querySelectorAll('.otp-input-elm')
      .forEach((element) => element.setAttribute('inputmode', 'numeric'));
  }, []);

  useEffect(() => {
    if (firstTime.current) {
      if (OTPValue.length === 6) {
        firstTime.current = false;
        otpSubmit();
      }
    }
  }, [OTPValue]);

  return (
    <>
      {showVisitEmailText && (
        <FormMessageBoxSuccess>
          <h4>
            We have sent you an email with an OTP to verify your email account (
            {user?.maskEmail && user?.maskEmail}
            ).
          </h4>
        </FormMessageBoxSuccess>
      )}
      <form onSubmit={handleSubmit(otpSubmit)}>
        <FormInputGroup>
          <label>
            {showVisitEmailText
              ? 'Enter OTP sent to your email'
              : props.OtpText}
          </label>
          <OTPInput
            value={OTPValue}
            onChange={setOTPValue}
            maxTime={10}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={loading}
            isInputNum={true}
            secure
            className="otp-inputs-wrap"
            inputClassName="otp-input-elm"
          />
          <ResendOTP
            onResendClick={() => resendOtp(props.selectedOTPmethod)}
            className="resend-otp"
            renderTime={(remainingTime: number) => (
              <span>
                {t('waiting_sec', { waiting_seconds: remainingTime })}
              </span>
            )}
            renderButton={(buttonProps: any) => {
              delete buttonProps.remainingTime;
              return <button {...buttonProps}>{t('resend_opt')}</button>;
            }}
          />
        </FormInputGroup>

        {OTPValue.length === 6 && (
          <FormAction>{loading && <Loading />}</FormAction>
        )}
      </form>
    </>
  );
};

export default EnterOTP;
