import React, { lazy, Suspense } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import Loading from '../components/loading/loading';
import { useUser } from '../context/user-context';
import AuthLayout from '../pages/_layouts/auth';
import BoilerPlate from '../pages/_layouts/default/BoilerPlate';
import { REACT_SUSPENSE_INTERVAL } from '../utils/constants';
import {
  LOAN_REQUEST_LANDING,
  NEW_LOAN_DESC,
  NEW_LOAN_DIGITAL_ROLLER_DESC,
  NEW_LOAN_DIGITAL_ROLLER_REQUEST,
  NEW_LOAN_DIGITAL_ROLLER_STATUS,
  NEW_LOAN_LANDING,
  NEW_LOAN_REQUEST, TOP_UP_DESC,
  TOP_UP_LOAN_LANDING,
  TOP_UP_LOAN_REQUEST, TOP_UP_REQUEST,
} from '../utils/navigation-path';
import Route from './Route';
import TopUpTypes from "../pages/RequestLoan/TopUpTypes";
import Success from '../pages/Messages/Success';
import Error from '../pages/Messages/Error';

const lazyDelayed = (path: any, delay = REACT_SUSPENSE_INTERVAL) => {
  return lazy(() =>
    Promise.all([
      path,
      new Promise((resolve) => setTimeout(resolve, delay)), // ensures minimal delay
    ]).then(([module]) => module)
  );
};

const ModalElements = lazyDelayed(import('../pages/UIElements/ModalElements'));
const CheckboxElements = lazyDelayed(
  import('../pages/UIElements/CheckboxElements')
);
const ButtonElements = lazyDelayed(
  import('../pages/UIElements/ButtonElements')
);
const RadioElements = lazyDelayed(import('../pages/UIElements/RadioElements'));
const InputElements = lazyDelayed(import('../pages/UIElements/InputElements'));

const TopUpLoanRequest = lazyDelayed(
  import('../pages/RequestLoan/TopUpLoanRequest')
);
const TopUpRequest = lazyDelayed(
    import('../pages/RequestLoan/TopUpRequest')
);
const TopUpLoanTypes = lazyDelayed(
  import('../pages/RequestLoan/TopUpLoanTypes')
);

const LoansLanding = lazyDelayed(import('../pages/RequestLoan/LoansLanding'));

const WUMoneyTransfer = lazyDelayed(
  import('../pages/ERemittances/WUMoneyTransfer')
);

const ERemittances = lazyDelayed(import('../pages/ERemittances/ERemittances'));
const LoanTypesRequest = lazyDelayed(
  import('../pages/RequestLoan/LoanTypesRequest')
);
const MyContracts = lazyDelayed(import('../pages/MyContracts/MyContracts'));

const LoanTypesDescription = lazyDelayed(
  import('../pages/RequestLoan/LoanTypesDescription')
);

const TopUpDescription = lazyDelayed(
    import('../pages/RequestLoan/TopUpDescription')
);

const OwnAccount = lazyDelayed(import('../pages/FundTransfer/ownAccount'));
const MobileLogin = lazyDelayed(import('../pages/MobileLogin/MobileLogin'));

const LoanStatus = lazyDelayed(import('../pages/RequestLoan/LoanStatus'));

const LoanRequest = lazyDelayed(import('../pages/RequestLoan/LoanRequest'));

const LoanDescription = lazyDelayed(
  import('../pages/RequestLoan/LoanDescription')
);

const LoanTypes = lazyDelayed(import('../pages/RequestLoan/LoanTypes'));

const LoanRequestStep1 = lazyDelayed(
  import('../pages/ApplyLoan/loanRequestStep_1')
);

const WhatIsDigitalLoan = lazyDelayed(import('../pages/ApplyLoan/whatIsLoan'));

const FixedDepositDetail = lazyDelayed(
  import('../pages/FixedDeposits/fixedDepositDetail')
);

const FixedDeposits = lazyDelayed(
  import('../pages/FixedDeposits/fixedDeposits')
);
const NewFixedDeposits = lazyDelayed(
  import('../pages/FixedDepositsNew/FixedDepositsNew')
);
const EditFixedDeposits = lazyDelayed(
  import('../pages/FixedDepositsEdit/FixedDepositsEdit')
);

const TopUpFixedDeposits = lazyDelayed(
  import('../pages/FixedDepositsTopUp/FixedDepositsTopUp')
);

const FixedDepositsWithdrawal = lazyDelayed(
  import('../pages/FixedDepositWithdrawal/FixedDepositsWithdrawal')
);

const FixedDepositLoan = lazyDelayed(
  import('../pages/FixedDepositLoan/FixedDepositsLoan')
);

const SavingsDetails = lazyDelayed(import('../pages/Savings/savingsDetails'));

const Savings = lazyDelayed(import('../pages/Savings/Savings'));

const SuccessPage = lazyDelayed(import('../pages/SuccessPage/successPage'));

const BankAccount = lazyDelayed(import('../pages/FundTransfer/bankAccount'));

const CimWallet = lazyDelayed(import('../pages/FundTransfer/cimWallet'));

const Profile = lazyDelayed(import('../pages/Profile/Profile'));

const RequestTransactionLimit = lazyDelayed(import('../pages/Profile/RequestTransactionLimit'));

const UnderConstruction = lazyDelayed(import('../pages/UnderConstruction'));

const GoldLoanForm = lazyDelayed(import('../pages/GoldLoan/goldLoanForm'));

const GoldLoan = lazyDelayed(import('../pages/GoldLoan/goldLoan'));

const ThirdPartyLeaseForm = lazyDelayed(
  import('../pages/LeasePayments/thirdPartyLeaseForm')
);

const NewThirdPartyLeaseForm = lazyDelayed(
  import('../pages/LeasePayments/newThirdPartyLeaseForm')
);
const LimitRequestLanding = lazyDelayed(
  import('../pages/LimitRequest/LimitRequestLanding')
);
const LimitRequestDetail = lazyDelayed(
  import('../pages/LimitRequest/LimitRequestDetail')
);


const ThirdPartyLease = lazyDelayed(
  import('../pages/LeasePayments/thirdPartyLease')
);

const MyLeasePaymentForm = lazyDelayed(
  import('../pages/LeasePayments/myLeasePaymentForm')
);

const MyLeasePayments = lazyDelayed(
  import('../pages/LeasePayments/myLeasePayments')
);

const ApplyLoan = lazyDelayed(import('../pages/ApplyLoan/applyLoan'));

const LeasePayments = lazyDelayed(
  import('../pages/LeasePayments/leasePayments')
);

const billPaymentForm = lazyDelayed(
  import('../pages/BillPayments/billPaymentForm')
);

const Register = lazyDelayed(import('../pages/Register/Register'));

const FundTransfer = lazyDelayed(import('../pages/FundTransfer/fundTransfer'));

const BillPayments = lazyDelayed(import('../pages/BillPayments/billPayments'));

const BillersList = lazyDelayed(import('../pages/BillPayments/billersList'));
const ChangePassword = lazyDelayed(
  import('../pages/ChangePassword/ChangePassword')
);

const Dashboard = lazyDelayed(import('../pages/Dashboard'));

const SignIn = lazyDelayed(import('../pages/SignIn'));

const Routes = () => {
  const { user } = useUser();
  let location = useLocation();
  const signed = user?.otp ? true : false;

  return (
    <Suspense
      fallback={
        signed ? (
          <BoilerPlate showProfile={false}>
            <Loading />
          </BoilerPlate>
        ) : (
          <AuthLayout>
            <Loading />
          </AuthLayout>
        )
      }
    >
      <Switch location={location}>
        <Route path="/" exact component={SignIn} />
        <Route path="/mobile" component={MobileLogin} />
        <Route path="/register" component={Register} />
        <Route path="/change-password" component={ChangePassword} isPrivate />

        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route path="/fund-transfer" exact component={FundTransfer} isPrivate />
        <Route
          path="/fund-transfer/cim-wallet"
          exact
          component={CimWallet}
          isPrivate
        />
        <Route
          path="/fund-transfer/cim-wallet/success"
          exact
          component={SuccessPage}
          isPrivate
        />
        <Route
          path="/fund-transfer/bank-account"
          exact
          component={BankAccount}
          isPrivate
        />
        <Route
          path="/fund-transfer/bank-account/success"
          exact
          component={SuccessPage}
          isPrivate
        />
        <Route
          path="/fund-transfer/own-account"
          exact
          component={OwnAccount}
          isPrivate
        />
        <Route
          path="/fund-transfer/own-account/success"
          exact
          component={SuccessPage}
          isPrivate
        />

        <Route path="/bill-payment" exact component={BillPayments} isPrivate />
        <Route
          path="/bill-payment/:categoryName"
          component={BillersList}
          exact
          isPrivate
        />
        <Route
          path="/limit-request"
          exact
          component={LimitRequestLanding}
          isPrivate
        />
        <Route
          path="/limit-request/:mode"
          exact
          component={LimitRequestDetail}
          isPrivate
        />

        {/* <Route
            path="/bill-payment/utility-bill-payment"
            exact
            component={UtilityBillPayment}
            isPrivate
          /> */}
        <Route
          path="/bill-payment/**/**/pay"
          component={billPaymentForm}
          exact
          isPrivate
        />
        <Route path="/apply_loan" exact component={ApplyLoan} isPrivate />
        <Route
          path="/apply_loan/whatis"
          exact
          component={WhatIsDigitalLoan}
          isPrivate
        />
        <Route
          path="/apply_loan/loan-request-step-1"
          exact
          component={LoanRequestStep1}
          isPrivate
        />
        <Route
          path="/lease-payments"
          exact
          component={LeasePayments}
          isPrivate
        />

        <Route
          path="/lease-payments/my-lease-payments"
          exact
          component={MyLeasePayments}
          isPrivate
        />
        <Route
          path="/lease-payments/my-lease-payments/pay"
          exact
          component={MyLeasePaymentForm}
          isPrivate
        />
        <Route
          path="/lease-payments/third-party-lease"
          exact
          component={ThirdPartyLease}
          isPrivate
        />
        <Route
          path="/lease-payments/new-third-party-lease"
          exact
          component={NewThirdPartyLeaseForm}
          isPrivate
        />
        <Route
          path="/lease-payments/third-party-lease-pay"
          exact
          component={ThirdPartyLeaseForm}
          isPrivate
        />

        <Route path="/gold-loan" exact component={GoldLoan} isPrivate />
        <Route
          path="/gold-loan/gold-loan-pay"
          exact
          component={GoldLoanForm}
          isPrivate
        />

        <Route path="/savings" exact component={Savings} isPrivate />
        <Route
          path="/transaction-history"
          exact
          component={SavingsDetails}
          isPrivate
        />

        <Route path="/coming-soon" component={UnderConstruction} isPrivate />
        <Route path="/coming-soon-1" component={UnderConstruction} isPrivate />

        <Route path="/profile" component={Profile} isPrivate />
        <Route path="/request-transaction-timit" component={RequestTransactionLimit} isPrivate />

        <Route path="/fixed-deposits/:id?/:typeEnum?" component={FixedDeposits} isPrivate />
        <Route path="/new-fixed-deposits/:isMobile?" component={NewFixedDeposits} isPrivate />
        <Route path="/edit-fixed-deposits/:id?" component={EditFixedDeposits} isPrivate />
        <Route path="/fixed-deposits-withdrawal/:id?" component={FixedDepositsWithdrawal} isPrivate />
        <Route path="/fixed-deposits-topup/:id?" component={TopUpFixedDeposits} isPrivate />
        <Route path="/fixed-deposits-loan/:id?" component={FixedDepositLoan} isPrivate />
        <Route
          path="/fixed-deposit-details/:id?"
          component={FixedDepositDetail}
          isPrivate
        />

        {/* START --> REQUEST A LOAN ROUTES */}
        <Route
          path={NEW_LOAN_REQUEST()}
          component={LoanTypesRequest}
          isPrivate
        />
        <Route
          path={NEW_LOAN_DESC}
          component={LoanTypesDescription}
          isPrivate
        />
        <Route
          path={NEW_LOAN_DIGITAL_ROLLER_DESC}
          component={LoanDescription}
          isPrivate
        />
        <Route
          path={NEW_LOAN_DIGITAL_ROLLER_REQUEST}
          component={LoanRequest}
          isPrivate
        />
        <Route
          path={TOP_UP_LOAN_REQUEST}
          component={TopUpLoanRequest}
          isPrivate
        />
        <Route
            path={TOP_UP_DESC}
            component={TopUpDescription}
            isPrivate
        />
        <Route
            path={TOP_UP_REQUEST}
            component={TopUpRequest}
            isPrivate
        />
        <Route
          path={NEW_LOAN_DIGITAL_ROLLER_STATUS}
          component={LoanStatus}
          isPrivate
        />
        <Route path={NEW_LOAN_LANDING} component={LoanTypes} isPrivate />
        <Route
          path={TOP_UP_LOAN_LANDING}
          component={TopUpTypes}
          isPrivate
        />
        <Route path={LOAN_REQUEST_LANDING} component={LoansLanding} isPrivate />
        {/* END --> REQUEST A LOAN ROUTES */}

        <Route path="/my-contracts" component={MyContracts} isPrivate />

        {/*<Route path="/e-remittances" exact component={ERemittances} isPrivate />*/}
        {/*<Route*/}
        {/*  path="/e-remittances/western-union-money-transfer"*/}
        {/*  exact*/}
        {/*  component={WUMoneyTransfer}*/}
        {/*  isPrivate*/}
        {/*/>*/}

        <Route
          path="/ui-elements/button-elements"
          component={ButtonElements}
          exact
        />
        <Route
          path="/ui-elements/checkbox-elements"
          component={CheckboxElements}
          exact
        />
        <Route
          path="/ui-elements/modal-element"
          component={ModalElements}
          exact
        />
        <Route
          path="/ui-elements/radio-elements"
          component={RadioElements}
          exact
        />
        <Route
          path="/ui-elements/input-elements"
          component={InputElements}
          exact
        />
        <Route
          path="/success/:message?"
          component={Success}
          isPrivate
        />
        <Route
          path="/error/:message?"
          component={Error}
          isPrivate
        />

        {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
        <Route component={SignIn} />
        {/* Start Success And Error Messages */}
      </Switch>
    </Suspense>
  );
};

export default Routes;
