import { PropTypes } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardContent, CardH2 } from './cardStyle';

const Card = ({
  title,
  url,
  icon,
  id,
  minMax,
  extraData = null,
  subTitle = '',
}) => {
  const data = {
    title: title,
    url: url,
    id: id,
    minMax: minMax,
    extraData,
  };
  const { t } = useTranslation();

  return (
    <CardContent
      to={{
        pathname: extraData?.comingSoon ? '' : url,
        state: data,
      }}
    >
      {title && (
        <div className="card-content-row">
          <CardH2>{t(title)}</CardH2>
          {extraData?.comingSoon && (
            <div
              style={{
                paddingTop: '1rem',
                color: 'red',
                fontWeight: 'bold',
                textDecoration: 'none',
                fontSize: 'small',
                pointerEvents: 'none',
              }}
            >
              COMING SOON
            </div>
          )}
        </div>
      )}
      {subTitle && (
        <div className="card-content-row">
          <span className="sub-header">{subTitle}</span>
        </div>
      )}

      {icon && (
        <div className="card-content-row">
          <img src={icon} alt={title} />
        </div>
      )}
    </CardContent>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  url: PropTypes.string.isRequired,
  icon: PropTypes.string,
  id: PropTypes.string,
};

export default Card;
