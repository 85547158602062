import React from 'react';
import { useTranslation } from 'react-i18next';

import { FooterContent, FooterText } from './styles';

const Footer = () => {
  const { t } = useTranslation();

  const text = window?.location?.pathname === '/' && (
    <>
      Developed by Silverleap Technology Pvt Ltd <br /> UI/UX Design by Vibrant
      UIUX Pvt Ltd
    </>
  );

  const { REACT_APP_BUILD_VERSION, REACT_APP_ENV_TYPE } = process.env;

  return (
    <FooterContent>
      <FooterText>{t('footer_copyright')}</FooterText>
      {/* Version format: {major}.{minor}.{patch}.{git revision tag - 7 digits} */}
      <div
        data-build-version={REACT_APP_BUILD_VERSION}
        data-app-env={REACT_APP_ENV_TYPE}
      >
        {text}
      </div>
    </FooterContent>
  );
};

export default Footer;
