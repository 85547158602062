import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import tw from 'twin.macro';

export const LoanStepperComponent = styled.div`
  .MuiStepLabel-active,
  .MuiStepLabel-completed {
    margin-top: 0 !important;
    font-size: 14px !important;
    color: ${(props) =>
      props.colorTheme
        ? props.theme[props.colorTheme].primary
        : props.theme[props.theme.activeTheme].primary} !important;
  }

  .MuiStepConnector-root {
    top: 17.5px;

    ${breakpoint('md')`top: 25px;`}
    .MuiStepConnector-line {
      border-width: 3px;
      ${breakpoint('md')` margin: 0 8%;`}
      border-color: #dcdcdc;
    }
  }

  .MuiStepConnector-completed {
    .MuiStepConnector-line {
      border-color: ${(props) =>
        props.colorTheme
          ? props.theme[props.colorTheme].primary
          : props.theme[props.theme.activeTheme].primary} !important;
    }
  }

  .sticky-stepper {
    position: sticky;
    position: -webkit-sticky;
    top: ${(props) => (props.isMobile ? 0 : props.theme.header.heightSm)};
    z-index: 1;
    background: white;
    -webkit-backface-visibility: hidden;
    box-shadow: 0px 4px 4px 0px #0000000d;
    clip-path: inset(0px 0px -5px 0px);

    ${breakpoint('sm')`
    top: ${(props) => (props.isMobile ? 0 : props.theme.header.heightSm)};`}

    ${breakpoint('md')`
    top: ${(props) => (props.isMobile ? 0 : props.theme.header.heightMd)};`}

    ${breakpoint('lg')`
    top: ${(props) =>
      props.isMobile ? 0 : props.theme.header.heightLg} !important;`}
  }
`;

export const RequestLoanSectionWrap = styled.div`
  padding: 0.25em 1em;
  background-color: white;
`;

export const ValidateFieldWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  border: 1px solid #d1d1d1;
  margin-bottom: 0.5rem;

  & > input {
    position: relative;
    border: none !important;
    flex: 1 1 auto;
    width: 1% !important;
    min-width: 0;
    margin-bottom: 0 !important;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .in-valid {
    border-color: red !important;
  }
`;

export const ValidateButton = styled.button`
  ${tw`transition-all duration-200 ease-in-out`}
  opacity: ${(props) => (props.processing ? 0.8 : 1)};
  pointer-events: ${(props) => (props.processing ? 'none' : 'auto')};
  background-color: ${(props) =>
    props.colorTheme
      ? props.theme[props.colorTheme].primary
      : props.theme[props.theme.activeTheme].primary};
  color: #fff;
  border-radius: 300px;
  -webkit-appearance: none;
  overflow: hidden;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
  font-size: 14px;
  cursor: pointer;
  padding: 5px 1rem;

  :hover {
    ${tw`bg-pink-100`}
    background-color: ${(props) =>
      props.colorTheme
        ? props.theme[props.colorTheme].primary_hover
        : props.theme[props.theme.activeTheme].primary_hover};
  }
`;

export const CalenderWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  grid-gap: 1rem;

  ${breakpoint('md')`width: 100%;`}
  div {
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    color: black;
    border: 1px solid #d1d1d1;

    &.active {
      border: 1px solid
        ${(props) =>
          props.colorTheme
            ? props.theme[props.colorTheme].primary
            : props.theme[props.theme.activeTheme].primary};
      color: ${(props) =>
        props.colorTheme
          ? props.theme[props.colorTheme].primary
          : props.theme[props.theme.activeTheme].primary};
    }

    &:hover {
      border: 1px solid
        ${(props) =>
          props.colorTheme
            ? props.theme[props.colorTheme].primary
            : props.theme[props.theme.activeTheme].primary};
      color: ${(props) =>
        props.colorTheme
          ? props.theme[props.colorTheme].primary
          : props.theme[props.theme.activeTheme].primary};
    }
  }
`;

export const LoanRadioContainer = styled.div`
  label {
    float: left;
    ${tw`block text-base text-gray-400 mb-3`}
  }
`;

export const FormSubHeader = styled.div`
  margin-bottom: 1.5rem;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      white-space: nowrap;
      padding-right: 1rem;
    }
    h6 {
      white-space: nowrap;
      padding-right: 1rem;
      color: ${(props) => props.theme[props.theme.activeTheme].primary};

      ${breakpoint('md')`color: #131313;`}
    }

    .line {
      width: 100%;
      height: 1px;
      display: none;
      border-top: 1px solid #dedede;

      ${breakpoint('md')`display: block;`}
    }
  }

  small {
    font-size: 12px;
    line-height: 16px;
    color: #a19f9f;
  }
`;

export const TimelineWrapper = styled.div`
  .rs-timeline {
    direction: ltr;
  }

  .rs-timeline-item {
    text-align: left;
  }

  .rs-timeline-item:nth-child(2n + 1) {
    text-align: right;
  }

  .rs-timeline-item:nth-child(2n) {
    text-align: left;

    .rs-timeline-item-content {
      direction: rtl;
    }

    .rs-timeline-item-content > div {
      direction: ltr;
    }
  }

  .rs-timeline-item-tail {
    width: 5px;
    transform: translate(50%, 0);
    background-color: #c4c4c4;
  }

  .rs-timeline-item-dot:before {
    width: 16px;
    height: 16px;
    background-color: #c4c4c4;
  }

  .rs-timeline-item:first-child .rs-timeline-item-dot {
    display: none;
  }

  .custom {
    &:last-child {
      background-color: #000000;
    }

    .rs-timeline-item-dot:before {
      background-color: #e8308a;
    }

    .rs-timeline-item-tail {
      background-color: #e8308a;
    }
  }
`;

export const SaveButton = styled.button`
  width: 100%;
  color: ${(props) =>
    props.colorTheme
      ? props.theme[props.colorTheme].primary
      : props.theme[props.theme.activeTheme].primary};
  border-radius: 300px;
  -webkit-appearance: none;
  overflow: hidden;
  font-size: 18px;
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.colorTheme
        ? props.theme[props.colorTheme].primary
        : props.theme[props.theme.activeTheme].primary};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 7px;
  padding: 10px 0;

  &:disabled {
    color: #a3a3a3;
    border-color: #8e8e8e59;
  }

  ${breakpoint('md')` width: '100%'; min-width: 200px;`}

  ${breakpoint('lg')``}
`;

export const NextButton = styled.button`
  ${tw`transition-all duration-200 ease-in-out`}
  width: 100%;
  background-color: ${(props) =>
    props.colorTheme
      ? props.theme[props.colorTheme].primary
      : props.theme[props.theme.activeTheme].primary};
  color: #fff;
  border-radius: 300px;
  -webkit-appearance: none;
  overflow: hidden;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.35);
  font-size: 18px;
  padding: 10px 0;
  cursor: pointer;
  margin: 7px;

  :hover {
    ${tw`bg-pink-100`}
    background-color: ${(props) =>
      props.colorTheme
        ? props.theme[props.colorTheme].primary_hover
        : props.theme[props.theme.activeTheme].primary_hover};
  }

  ${breakpoint('md')`
  min-width: 200px;
  width: '100%'; 
  `}

  ${breakpoint('lg')``}
`;

export const ModalWrapper = styled.div`
  padding: 1rem;
  width: 100%;
  max-width: 600px;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    svg {
      cursor: pointer;
      display: none;

      ${breakpoint('md')`display: block;`}
    }

    ${breakpoint('md')`text-align: center; justify-content: space-between;`}
  }

  .otp-container {
    margin-bottom: 2rem;

    & > * + * {
      margin-left: 1rem;
    }
  }

  .otp-input {
    width: 36px !important;
    text-align: center;
    height: 64px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
    color: #222222;
  }

  .otp-input-focus {
    width: 44px !important;
    text-align: center;
    height: 64px;
    background: #ffffff;
    border: 1px solid #e8308a;
    box-sizing: border-box;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    font-weight: 600;
    font-size: 36px;
    line-height: 49px;
    color: #222222;
  }

  .text-muted {
    font-size: 14px;
    line-height: 16px;
    color: #a7a7a7;
  }

  .resend-otp {
    font-size: 16px;
    line-height: 16px;
    color: #da2129;
    cursor: pointer;
  }

  .action-btn {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;

    ${breakpoint('md')`justify-content: end;`}
  }
`;

export const RequestLoanMobileHeader = styled.h5`
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) =>
    props.colorTheme
      ? props.theme[props.colorTheme].primary
      : props.theme[props.theme.activeTheme].primary};
  padding-bottom: 0.5rem;
`;

export const RequestLoanLoader = styled.div`
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

export const LoanRequestModalWrapper = styled.div`
  ${tw`flex flex-col`}

  /* TITLE CONTAINER */
  .title-container {
    ${tw`flex flex-row justify-items-end items-center`}

    .title-wrapper {
      ${tw`flex-auto`}
    }
    .icon-wrapper {
      width: 50px;
      position: relative;
      ${tw`flex justify-end`}
      span {
        font-weight: bold;

        cursor: pointer;
        border: none;
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        text-decoration: none;
        color: crimson;
        background-color: inherit;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  /* DETAILS CONTAINER */
  .details-wrapper {
    ${tw`flex flex-col mt-6`}
    .detail-row {
      ${tw`flex flex-row justify-between mb-4`}
      .detail-col {
        p {
          font-size: 16px;
          color: #474747;
        }
        p.title {
          font-weight: 700;
        }
        p.text {
          font-weight: 400;
        }
      }
    }
  }

  /* INFO CONTAINER */
  .info-container {
    ${tw`flex flex-row mt-6 `}

    .info-wrapper {
      ${tw`mt-4 `}

      p {
        font-size: 18px;
        font-weight: 400;
      }
      p.error {
        color: red;
      }
    }
  }

  /* TERMS CONTAINER */
  .terms-container {
    ${tw`flex flex-row mt-6 `}
  }

  /* ACTIONS CONTAINER */
  .actions-container {
    ${tw`flex flex-row mt-6 justify-around`}

    .action-wrapper {
    }
    .action-left {
    }
    .action-right {
    }
  }
`;

export const TermsModalWrapper = styled.div`
  ${tw`flex flex-col`}
  height: 44rem;
  /* TITLE CONTAINER */
  .title-container {
    ${tw`flex flex-row justify-items-end items-end w-full justify-end mb-4`}

    .icon-wrapper {
      width: 50px;
      position: relative;
      ${tw`flex justify-end`}
      span {
        font-weight: bold;
        cursor: pointer;
        border: none;
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        text-decoration: none;
        color: crimson;
        background-color: inherit;
        text-align: center;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }

  .terms-body {
    ${tw`overflow-y-scroll`}
  }
`;
