import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import tw from 'twin.macro';

export const Wrapper = styled.div`
  ${tw`flex h-full flex-col`}
`;

export const ContentWrapper = styled.div`
  ${tw`flex w-full flex-1 flex-wrap md:flex-no-wrap`}
`;

export const SideBar = styled.div`
  ${tw`hidden lg:block bg-gray-100 relative`}

  &:after {
    ${tw`absolute h-full top-0 right-0`}
    content: '';
    right: -11px;
    content: '';
    box-shadow: -3px 0px 6px rgb(0 0 0 / 8%);
    width: 11px;
  }
`;

export const ContentAria = styled.div`
  ${tw`md:px-12 px-2 md:pt-8 pt-4 pb-4 w-full flex flex-col md:bg-gray-1000 `}

  [class*="/success"]
`;

export const SideBarRightWrap = styled.div`
  ${tw`relative hidden xl:flex`}
  min-width: 286px;

  @media (max-width: 1440px) {
    min-width: 156px;
  }

  min-height: 100%;

  .carousel {
    ${tw`sticky h-full w-full overflow-y-hidden`};
    top: 80px;
    max-height: calc(100vh - 80px);
  }

  .flickity-enabled:focus {
    ${tw`outline-none`}
  }

  .flickity-viewport {
    ${tw`overflow-hidden relative w-full`}
    height: 100% !important;
  }

  .flickity-slider {
    ${tw`absolute w-full h-full`}
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .flickity-page-dots {
    top: 20px;
    ${tw`absolute left-0 w-full m-0 p-0 list-none text-center`}
  }

  .flickity-rtl .flickity-page-dots {
    direction: rtl;
  }

  .flickity-page-dots .dot {
    width: 9px;
    height: 9px;
    margin: 0 5px;
    border-radius: 50%;
    opacity: 0.25;
    ${tw`inline-block bg-white cursor-pointer`}
  }

  .flickity-page-dots .dot.is-selected {
    opacity: 1;
  }
`;

export const SideBarRight = styled.div`
  ${tw`bg-gray-100 relative h-full w-full bg-no-repeat bg-contain bg-center`}
  ${'' /* ${tw`bg-gray-100 relative h-full w-full bg-no-repeat bg-cover`} */}
  background-image: url('../../../assets/images/slider/${(props) =>
    props.imgSrc}');

  &.is-selected {
    position: relative !important;
  }

  &:after {
    ${tw`absolute h-full top-0 right-0`}
    content: '';
    left: -11px;
    content: '';
    box-shadow: 3px 0px 6px rgb(0 0 0 / 8%);
    width: 11px;
  }
`;

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 20px 0 0;
  flex-grow: 1;

  ${breakpoint('sm')`padding: 20px 0`}

  ${breakpoint('md')`padding: 26px 0`}

  ${breakpoint('lg')`padding: 20px 0 0 0`}

  > div > div:first-child {
    max-width: 100%;

    ${breakpoint('sm')`max-width: 100%`}

    ${breakpoint('md')`max-width: 75%`}

    ${breakpoint('lg')`max-width: 75%`}
  }
`;
