export const Deposits = [
  {
    id: 1,
    code: '01',
    description: 'Less than Rs. 100,000',
    data: 1,
  },
  {
    id: 2,
    code: '02',
    description: 'Rs.100,001 to Rs.500,000',
    data: 2,
  },
  {
    id: 3,
    code: '03',
    description: 'Rs.500,001 to Rs.1,000,000',
    data: 3,
  },
  {
    id: 4,
    code: '04',
    description: 'Rs.1,000,001 to Rs.2,000,000',
    data: 4,
  },
  {
    id: 5,
    code: '05',
    description: 'Rs.2,000,001 to Rs.3,000,000',
    data: 5,
  },
  {
    id: 6,
    code: '06',
    description: 'Rs.5,000,001 to Rs.7,000,000',
    data: 6,
  },
  {
    id: 7,
    code: '06',
    description: 'Rs.7,000,001 to Rs.10,000,000',
    data: 7,
  },
  {
    id: 8,
    code: '06',
    description: 'Rs.10,000,001 to Rs.25,000,000',
    data: 8,
  },
  {
    id: 9,
    code: '06',
    description: 'More than Rs. 25,000,001',
    data: 9,
  },
  {
    id: 10,
    code: '06',
    description: 'Rs.3,000,001 to Rs.5,000,000',
    data: 10,
  },
];
