import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { useAppThemeContext } from '../context/appThemeContext';
import { ThemeTypes } from '../utils/enums';
import theme from '../styles/theme';

interface StyledComponentThemeProviderProps {
  children: ReactNode;
}

const StyledComponentThemeProvider = ({
  children,
}: StyledComponentThemeProviderProps) => {
  const { theme: appTheme } = useAppThemeContext();
  const userTheme =
    appTheme === ThemeTypes.CustomerTheme ? 'themePrimary' : 'themeSecondary';
  theme.activeTheme = userTheme;
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default StyledComponentThemeProvider;
