import {ApiResponse, ApisauceInstance, create} from 'apisauce';
import {CancelToken} from 'axios';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import {ITopUpRequestPayload} from '../../interfaces/ITopUpLoans';
import {X_LANGUAGE} from '../../utils/constants';
import {getGeneralApiProblem} from './api-problem';
import {
    API_VERSION,
    DEFAULT_API_CONFIG,
    REFRESH_TOKEN_NAME,
} from './api.config';

class Api {
    apisauce: ApisauceInstance;
    token: string | undefined = '';
    language: string = 'en';

    constructor(config = DEFAULT_API_CONFIG) {
        this.apisauce = create({
            baseURL: config.url,
            timeout: config.timeout,
            headers: config.headers,
        });
    }

    setLanguage(lang: string) {
        this.apisauce.setHeader(X_LANGUAGE, lang ?? 'en');
    }

    errorMessageFilter(errorMessages: any) {
        if (Number(errorMessages.length) === 2) {
            return errorMessages[1];
        } else {
            return errorMessages[0];
        }
    }

    /**
     * Customer Login Method
     * @param {string} username - username of the user.
     * @param {string} password - password of the user.
     */
    async customerLogin(username: string, password: string, isNic: boolean) {
        const response: ApiResponse<any> = await this.apisauce.post(
            `jwt/auth/lbwallet-web`,
            {
                username,
                password,
                appidentifier: `com.cashinweb.lb.customer`,
                usernametype: isNic ? 1 : 0,
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        } else if (response.data.status && response.data.status !== 0) {
            throw new Error(this.errorMessageFilter(response.data.errorMessages));
        }

        //update token
        if (response?.data?.auth?.access) {
            this.token = response?.data?.auth?.access;
        }

        // update refresh token
        if (response?.data?.auth?.refresh) {
            this.updateRefreshToken(response?.data?.auth?.refresh);
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Merchant Login Method
     * @param {string} username - username of the user.
     * @param {string} password - password of the user.
     */
    async merchantLogin(username: string, password: string, isNic: boolean) {
        const response: ApiResponse<any> = await this.apisauce.post(
            `jwt/auth/lbwallet-web`,
            {
                username,
                password,
                appidentifier: `com.cashinweb.lb.merchant`,
                usernametype: isNic ? 1 : 0,
            }
        );
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        } else if (response.data.status && response.data.status !== 0) {
            throw new Error(this.errorMessageFilter(response.data.errorMessages));
        }

        //update token
        if (response?.data?.auth?.access) {
            this.token = response?.data?.auth?.access;
        }

        // update refresh token
        if (response?.data?.auth?.refresh) {
            this.updateRefreshToken(response?.data?.auth?.refresh);
        }

        return {kind: 'ok', data: response.data};
    }

    async changePin(accNo: string, currentPin: string, newPin: string) {
        const response: ApiResponse<any> = await this.apisauce.post(
            `lbwallet/appapi/${API_VERSION}/accounts/${accNo}/pinchanges`,
            {
                currentPin: currentPin,
                newPin: newPin,
            },
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Refresh Token method
     */
    async refreshToken() {
        const res = await fetch(
            `${process.env.REACT_APP_API_URL}jwt/auth/refresh`,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache',
                    Authorization: `Bearer ${Cookies.get(REFRESH_TOKEN_NAME)}`,
                },
            }
        );

        const response = await res.json();

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        } else if (response.data.status && response.data.status !== 0) {
            throw new Error(this.errorMessageFilter(response.data.errorMessages));
        }

        this.updateRefreshToken(response.body.auth.refresh);
        this.token = response.body.auth.access;

        return {kind: 'ok', data: response.body};
    }

    /**
     * Logout user
     */
    async logout() {
        // const response = await this.apisauce.delete(
        //   `jwt/auth`,
        //   {},
        //   {
        //     headers: {
        //       Authorization: `${this.token}`,
        //       'Auth-Refresh': `${Cookies.get(REFRESH_TOKEN_NAME)}`,
        //       'Content-Type': 'application/json',
        //     },
        //   }
        // );

        // if (!response.ok) {
        //   const problem = getGeneralApiProblem(response);
        //   if (problem) return problem;
        // } else if (response.data.status && response.data.status !== 0) {
        //   throw new Error(response.data.errorMessages.join(' -- '));
        // }

        this.token = undefined;
        Cookies.remove(REFRESH_TOKEN_NAME);

        return {kind: 'ok', data: null};
    }

    /**
     * Method to get All Favourite Bill Payments
     * @param {string} accNo Account Number
     */
    async getAllFavouriteBillers(accNo: string | undefined) {
        const response = await this.apisauce.get(
            `lbwallet/appapi/${API_VERSION}/accounts/${accNo}/favourite/biller/billers`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Method to get All Favourite Bill Payments
     * @param {string} accNo Account Number
     */
    async getInfoService(accNo: string) {
        const response: ApiResponse<any> = await this.apisauce.get(
            `lbwallet/appapi/${API_VERSION}/accounts/${accNo}/true`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        // if (!response.ok) {
        //   const problem = getGeneralApiProblem(response);
        //   if (problem) return problem;
        // }

        return {kind: 'ok', data: response.data};
    }

    /**
     *
     * @param identifier String account number or mobile number
     * @param identifierType WALLET_ACC_NO(0), MOBILE_NO(1), QR_CODE(2), PAYMENT_INSTRUMENT(3), BILLER_CODE(4),
     LB_ACCOUNT(5), OTHER_BANK_ACC_NO(6), REFERENCE_NO(7), VIRTUAL_REFERENCE(8), CREDIT_CARD_NO(9),
     GOLD_LOAN_TOPUP_NO(10),WU_MONEY_TOPUP_ID(11)
     * @returns
     */
    async getAccountInfoByIdentifier(identifier: string, identifierType: string) {
        const response: ApiResponse<any> = await this.apisauce.get(
            `lbwallet/appapi/${API_VERSION}/accounts/info?identifier=${identifier}&identifierType=${identifierType}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        return {kind: 'ok', data: response.data};
    }

    /**
     * Get Biller Categories
     */
    async getBillerCategories() {
        const response: ApiResponse<any> = await this.apisauce.get(
            `lbwallet/appapi/${API_VERSION}/masterdatas/biller/categories`
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data.response};
    }

    /**
     * GetBiller by Categories
     */
    async getBillersByCategory(category: string) {
        const response: ApiResponse<any> = await this.apisauce.get(
            `lbwallet/appapi/${API_VERSION}/masterdatas/biller/categories/${category}/details`
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data.response};
    }

    /*
     * Get all Facilities
     */
    // async getAllFacilities(accNo: string, facilityId: number) {
    //     const response: ApiResponse<any> = await this.apisauce.post(
    //         `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/facility`,
    //         {
    //             facilityIds: [facilityId],
    //         },
    //         {
    //             headers: {
    //                 Authorization: `Bearer ${this.token}`,
    //             },
    //         }
    //     );

    //     if (!response.ok) {
    //         const problem = getGeneralApiProblem(response);
    //         if (problem) return problem;
    //     }

    //     return {kind: 'ok', data: response.data};
    // }
    getAllFacilities(accNo:string, facilityId:number) {
        return this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/facility`,
            {
                facilityIds: [facilityId],
            },
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        )
        .then(response => {
            if (!response.ok) {
                const problem = getGeneralApiProblem(response);
                if (problem) return problem;
            }
            return { kind: 'ok', data: response.data };
        })
        .catch(error => {
            // Handle error
            console.error('Error occurred:', error);
            // You may want to throw or return a meaningful error here
            return error;
        });
    }

    async getAllGoldLoans(accNo:string, facilityId:number) {
        return this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/accounts/facility/getLbContracts/${accNo}`,
            {
                facilityId: facilityId,
            },
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        )
        .then(response => {
            if (!response.ok) {
                const problem = getGeneralApiProblem(response);
                if (problem) return problem;
            }
            return { kind: 'ok', data: response.data };
        })
        .catch(error => {
            // Handle error
            console.error('Error occurred:', error);
            // You may want to throw or return a meaningful error here
            return error;
        });
    }

    /*
     * Get all Favourites
     */
    async getAllFavourites(accNo: string, transactionTypeId: any[]) {
        const response: ApiResponse<any> = await this.apisauce.post(
            `lbwallet/appapi/${API_VERSION}/accounts/${accNo}/favourites`,
            {
                transactionTypes: [...transactionTypeId],
            },
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /*
     * Get all Saving Accounts
     */
    async getAllSavingAccounts(accNo: string, status: any) {
        const response = await this.apisauce.get(
            `lbwallet/appapi/${API_VERSION}/accounts/${accNo}/saving/info?status=${status}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /*
     * Payment Instruments - Add
     */
    async addPaymentInstruments(
        accNo: string | undefined,
        name: any,
        accountNo: any,
        accountName: any,
        bank: string,
        branch: any,
        accountTypeId: number,
        consent: string,
        signature: string
    ) {
        const response = await this.apisauce.put(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/paymentinstruments`,
            {
                name: name,
                accountNo: accountNo,
                accountName: accountName,
                bank: bank,
                branch: branch,
                accountTypeId: accountTypeId,
                consent: consent,
                //signature: signature,
            },
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return response;
        }

        return {kind: 'ok', data: response.data};
    }

    /*
     * Payment Instruments - Verify
     */
    async verifyPaymentInstruments(
        accNo: string | undefined,
        paymentInstrumentId: undefined,
        amount: any
    ) {
        const response = await this.apisauce.put(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/paymentinstruments/${paymentInstrumentId}/verifications`,
            {
                amount: amount,
            },
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return response;
        }

        return {kind: 'ok', data: response.data};
    }

    /*
     * Payment Instruments - List
     */
    async listPaymentInstruments(accNo: string | undefined, piStatus: string) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/paymentinstruments?status=${piStatus}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /*
     * Payment Instruments - List(LB accounts)
     */
    async listInternalPaymentInstruments(accNo: string | undefined, piStatus: string) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/paymentinstruments/internal?status=${piStatus}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Get Profile Info
     */
    async getProfileInfoService(
        accNo: string | undefined,
        identityAvailability: boolean
    ) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/${identityAvailability}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Get Profile Picture
     */
    async getProfilePicture(accNo: string | undefined) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/profile/picture
      `,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Change profile nickname
     */
    async changeNickname(accNo: string | undefined, nickname: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/nickname/change
      `,
            {newNickname: nickname},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Change profile email
     */
    async changeEmail(accNo: string | undefined, email: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/email/change
      `,
            {newEmail: email},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Change user pin
     */
    async pinChange(accNo: any, currentPin: any, newPin: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/pinchanges
      `,
            {currentPin: currentPin, newPin: newPin},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Method to get all transaction history
     */
    async getTransactionHistory(
        accNo: any,
        fromDate: any,
        toDate: any,
        cancelToken: CancelToken
    ) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/transactions?accountNo=${accNo}`,
            {
                fromDate: fromDate,
                toDate: toDate,
            },

            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
                cancelToken: cancelToken,
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }
        return {kind: 'ok', data: response.data};
    }

    /**
     * Get Charge amount
     */

    async getChargeAmount(
        accNo: string | undefined,
        transactionTypeId: number,
        transactionAmount: any
    ) {
        const response = await this.apisauce.post(
            `lbwallet/appapi/${API_VERSION}/transactions/${accNo}/charges`,
            {
                trnTypeId: transactionTypeId, //transaction enum id
                amount: transactionAmount, //transaction amount
            },
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Get Charge amount for bank tranfer
     */

    async getBillPaymentChargeAmount() {
        const response = await this.apisauce.get(
            `lbwallet/appapi/${API_VERSION}/masterdatas/billpayment/charges`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Get Banks by Type
     */
    async getBanksType(bankType: string) {
        const response: any = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/banks?banktype=${bankType}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response?.data?.banks};
    }

    /**
     * Get Banks
     */
    async getBanks() {
        const response: any = await this.apisauce.get(
            `lbwallet/appapi/${API_VERSION}/masterdatas/banks`
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data.banks};
    }

    /**
     * Get Branches
     */
    async getBranches(bankCode: any) {
        const response = await this.apisauce.get(
            `lbwallet/appapi/${API_VERSION}/masterdatas/banks/${bankCode}`
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Get Third Party Leases
     */
    async getThirdPartyFacilities(
        accNo: string | undefined,
        facilityType: number,
        facilityNumber: any
    ) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/facility/${facilityType}/${facilityNumber}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Delete Favourite Biller
     */
    async deleteFavouriteBiller(accNo: string | undefined, billerId: any) {
        const response = await this.apisauce.delete(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accNo}/favourite/biller/services/billers/${billerId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Method to do all Transactions in the system
     * @param {string} accNo Account Number
     */
    async transaction(transactionData: any) {
        const response = await this.apisauce.put(
            `lbwallet/appapi/${API_VERSION}/transactions`,
            transactionData,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return response;
        }

        return {kind: 'ok', data: response.data};
    }

    /*
     * Send Transaction OTP
     */
    async sendTransactionOTP(transactionID: any) {
        const response = await this.apisauce.put(
            `lbwallet/appapi/${API_VERSION}/transactions/${transactionID}/otps`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return response;
        }

        return {kind: 'ok', data: response.data};
    }

    /*
     * Send Transaction OTP
     */
    async verifyTransactionOTP(transactionID: any, OTP: string) {
        const response = await this.apisauce.post(
            `lbwallet/appapi/${API_VERSION}/transactions/${transactionID}/otps/${OTP}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return response;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Send Login OTP
     */
    async sendOTP(
        accNo: string | undefined,
        type: string,
        contact: string | undefined
    ) {
        const response = await this.apisauce.post(
            `lbwallet/appapi/${API_VERSION}/accounts/${accNo}/send/otp/${type}/${contact}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return response;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * resend OTP
     */
    async resendOtp(regId: any) {
        const response = await this.apisauce.put(
            `lbwallet/appapi/${API_VERSION}/registrations/${regId}/mobile_otps`
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * resend OTP
     */

    /**
     * Verify Login OTP
     */
    async verifyOTP(accNo: string | undefined, otp: string) {
        const response = await this.apisauce.post(
            `lbwallet/appapi/${API_VERSION}/accounts/${accNo}/verifications/${otp}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return response;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Non Verify Email Mobile OTP Verifications
     */
    async nonVerifyEmailMobileOTPVerify(accNo: string | undefined, otp: string) {
        const response = await this.apisauce.post(
            `lbwallet/appapi/${API_VERSION}/accounts/${accNo}/verify-email/${otp}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return response;
        }

        return {kind: 'ok'};
    }

    /**
     * Email OTP Verifications
     */
    async verifyEmailMobileOTPVerify(accNo: string | undefined, otp: string) {
        const response = await this.apisauce.post(
            `lbwallet/appapi/${API_VERSION}/accounts/${accNo}/verify-email/confirm/${otp}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return response;
        }

        return {kind: 'ok', data: response.data};
    }

    /*
     * Encrypt Text
     */
    async encryptText(card: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/encipher_text`,
            {
                text: card,
            },
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return response;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Get Easy Loan Products
     */
    async getEasyLoanProducts() {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/easy-loan/masterdata/products`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Get Easy Loan Products description
     */
    async getEasyLoanProductDescription(
        accNo: string | undefined,
        productId: any
    ) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/products/${productId}/product-description`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Get Easy Loan Products master data
     */
    async getEasyLoanMasterData(masterRequest: {
        productEnum: any;
        dataType: string;
        filterCriteria:
            | {}
            | {}
            | { employmentCategory: any }
            | { employmentCategory: any };
    }) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/easy-loan/masterdata`,
            masterRequest,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan Check Eligibility
     */
    async easyLoanCheckEligibility(
        accNo: string | undefined,
        identificationType: any,
        identification: string | undefined,
        productEnum: any
    ) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accNo}/loan-request/request/eligibility/${identificationType}/${identification}/${productEnum}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan Save FormData
     */
    async easyLoanLoanRequestSubmit(accNo: string | undefined, formData: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accNo}/loan-request`,
            {...formData},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan Save FormData
     */
    async easyLoanFormDataSave(accNo: string | undefined, formData: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accNo}/loan-request/form-data`,
            {...formData},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan Update FormData
     */
    async easyLoanFormDataUpdate(accNo: string | undefined, formData: any) {
        const response = await this.apisauce.put(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accNo}/loan-request/form-data`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan GET FormData
     */
    async getEasyLoanFormData(accNo: string | undefined, productId: any) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accNo}/loan-request/${productId}/form-data`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan GET Status
     */
    async easyLoanStatus(
        accNo: string | undefined,
        productId: any,
        identificationType: any,
        identification: string | undefined
    ) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accNo}/loan-request/status/${identificationType}/${identification}/${productId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan GET Provinces
     */
    async getProvinces() {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/easy-loan/provinces`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan GET Districts
     */
    async getDistrictsByProvinceId(provinceId: any) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/easy-loan/${provinceId}/districts`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan GET Provinces
     */
    async getCitiesByDistrictId(districtId: any) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/easy-loan/${districtId}/cities`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan GET BSP DATA
     */
    async getBSPData(
        accountNo: string | undefined,
        identificationType: number,
        identificationNumber: string | undefined
    ) {
        const response: any = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accountNo}/bspdata/${identificationType}/${identificationNumber}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response?.data?.businessPartnerData};
    }

    /**
     * Easy Loan GET BANKS
     */
    async getEasyLoanBanks() {
        const response: any = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/easy-loan/banks`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data.banks};
    }

    /**
     * Easy Loan GET BANK BRANCHES
     */
    async getEasyLoanBankBranches(bankId: any) {
        const response: any = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/easy-loan/${bankId}/branches`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response?.data?.branches};
    }

    async uploadAttachment(accountNumber: any, attachment: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accountNumber}/loan-request/attachment`,
            attachment,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    async deleteAttachment(accountNumber: any, attachmentSaveId: any) {
        const response = await this.apisauce.delete(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accountNumber}/loan-request/attachment/${attachmentSaveId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan SEND OTP
     */
    async easyLoanSendOTP(
        accountNo: string | undefined,
        type: any,
        contact: any
    ) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accountNo}/loan-request/send/otp/${type}/${contact}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan SEND OTP
     */
    async easyLoanVerifyOTP(accountNo: string | undefined, otp: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accountNo}/loan-request/verifications/${otp}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan Rental amount
     */
    async easyLoanRentalAmount(
        accountNo: string | undefined,
        prodID: any,
        period: any,
        amount: string | number
    ) {
        const amountParsed = Number.parseInt(String(amount));
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accountNo}/loan-request/products/${prodID}/rental-amount/${period}/${amountParsed}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Easy Loan Rental amount
     */
    async getEasyLoanFacilityInfo(accountNo: string | undefined, facNum: any) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accountNo}/loan-request/facility-details/${facNum}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * LOAN REQUEST - Get All Loan types with districts.
     */
    async getAllLoanTypesWithDistricts() {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/loan/loantypes-with-districts`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * LOAN REQUEST - Get All districts by product id.
     * @param {string} productId
     */
    async getProductDistricts(productId: any) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/loan/product-district/${productId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * LOAN REQUEST - Get All branches by districtProductId.
     */
    async getProductBranches(districtProductId: any) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/loan/product-branches/${districtProductId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * LOAN REQUEST - Get loan terms and conditions
     */
    async getTermsAnsConditionForLoan() {
        const response = await this.apisauce.get(
            `lbwallet/appapi/${API_VERSION}/masterdatas/termsAndConditions/loan`
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }
        return {kind: 'ok', data: response.data};
    }

    /**
     * LOAN REQUEST - Submit a loan request
     */
    async submitLoanRequest(accountNumber: any, payload: any) {
        const response = await this.apisauce.post(
            `lbwallet/appapi/${API_VERSION}/accounts/${accountNumber}/create-loan-request`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }
        return {kind: 'ok', data: response.data};
    }

    //#region LOAN TOP-UP SERVICES.
    /**
     * LOAN TOP-UP - Get all loan top-up product types.
     */
    async getLoanTopUpProductTypes() {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/loan-topup/products`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }
        return {kind: 'ok', data: response.data};
    }

    /**
     * LOAN TOP-UP - Loan top-up eligibility check.
     */
    async getTopUpEligibility(accountNumber: any) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accountNumber}/loan-topup/eligibility`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }
        return {kind: 'ok', data: response.data};
    }

    /**
     * LOAN TOP-UP - Loan top-up master data.
     */
    async getTopUpMasterData(accountNumber: any, facility_no: any) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accountNumber}/loan-topup/${facility_no}/master-data`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }
        return {kind: 'ok', data: response.data};
    }

    /**
     * LOAN TOP-UP - Get terms and condition data.
     */
    async getTopUpLoanTermsAndConditions() {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/loan-topup/terms-and-condition`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }
        return {kind: 'ok', data: response.data};
    }

    /**
     * LOAN TOP-UP - Loan top-up eligibility check.
     */
    async postTopUpRequest(accountNumber: string, payload: ITopUpRequestPayload) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accountNumber}/loan-topup`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }
        return {kind: 'ok', data: response.data};
    }

    /**
     * Top up Loan Save FormData
     */
    async topUpLoanRequestSubmit(accNo: string | undefined, data: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/easy-loan/${accNo}/loan-topup`,
            {...data},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    //#endregion LOAN TOP-UP SERVICES.

    /**
     * WU Master Data
     */
    async getWUMasterData(accountNo: string | undefined) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/accounts/${accountNo}/western-union/masterdata`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * WU Transaction History
     */
    async getWUTransactionHistory(data: undefined) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/transactions/western-union/transaction-history`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * WU Submit
     */
    async saveWU(data: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/transactions/western-union/money-transfer-request`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * WU Submit
     */
    async getWUTerms(data: undefined) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/masterdatas/western-union/termsAndConditions`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * New FIX DEPOSIT
     */
    async getFDMasterData() {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/master-data`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    async getFDStatus(fdAccountNumber: any) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/status/${fdAccountNumber}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    async getFDInterestRate(data: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/interest-rate`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }


    async fDNomineeSearch(referenceNo: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/nominee-search`,
            {
                nomineeSearch: {
                    referenceNo
                }
            },
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    async fDCreateNominee(data: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/nominee`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    async fDCreate(data: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    async getFDDetailsByReference(fdAccountNumber: any) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/certificate/${fdAccountNumber}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }
        return {kind: 'ok', data: response.data};
    }
    /**
     * Fixed Deposit - Get terms and condition data.
     */
     async getFDTermsAndConditions() {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/toc`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }
        return {kind: 'ok', data: response.data};
    }
    async getFDLoanTermsAndConditions() {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/loan/toc`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );
        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }
        return {kind: 'ok', data: response.data};
    }

    getFDWithdrawalInfo(fdAccountNumber: any) {
        return this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/withdrawal/${fdAccountNumber}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        ).then((response)=>{
            if (!response.ok) {
                const problem = getGeneralApiProblem(response);
                if (problem) return problem;
            }
    
            return {kind: 'ok', data: response.data};
        });

        
    }
    async fDWithdraw(data: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/withdrawal`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }
    getFDEditInfo(fdAccountNumber: any) {
        return this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/edit/${fdAccountNumber}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        ).then((response)=>{
            if (!response.ok) {
                const problem = getGeneralApiProblem(response);
                if (problem) return problem;
            }
    
            return {kind: 'ok', data: response.data};
        });

        
    }
    async fDEdit(data: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/edit`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }
    async fDEditNextCycle(data: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/next-renewal`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }
    async fDTopUp(data: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/top-up`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }
    async getFDLoanInfo(fdAccountNumber: any) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/get-loan-details/${fdAccountNumber}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }
    async fDLoan(data: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/credit-account-leads`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }
    async getFDLoanAdditionalInfo(loanAmount: any) {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/fixed-deposit/get-additional-charges-by-basic-details?productEnum=31&assetTypeEnum=0&facilityAmount=${loanAmount}&netReceivable=0&isAssetChanged=false&isFacilityAmountChanged=true&isNetReceivableChanged=false`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    /**
     * Customer Limit Request
     */
    async getLimitRequestMasterData() {
        const response = await this.apisauce.get(
            `/lbwallet/appapi/${API_VERSION}/limit-request/master-data`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }
    async submitLimitRequest(data: any) {
        const response = await this.apisauce.post(
            `/lbwallet/appapi/${API_VERSION}/limit-request`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            }
        );

        if (!response.ok) {
            const problem = getGeneralApiProblem(response);
            if (problem) return problem;
        }

        return {kind: 'ok', data: response.data};
    }

    register() {
        // Register
    }

    /**
     * This method is used to update Refresh Token which is in Cookies
     * @param {string} token - Refresh Token to update
     */
    updateRefreshToken(token: string) {
        try {
            const decodeData: any = jwtDecode(token);
            Cookies.set(REFRESH_TOKEN_NAME, token, {expires: decodeData?.exp});
        } catch (error) {
        }
    }

    /**
     * This method is used to verify refresh token validity.
     * It will check and let us know if it is expired or not for now.
     *
     * @param {string} token Refresh Token to verify
     */
    verifyRefreshToken(token: string) {
        const {exp}: any = jwtDecode(token);
        return Date.now() >= exp * 1000 ? false : true;
    }
}

export default new Api();
