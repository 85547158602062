import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { IPaletteOptions } from '../../../interfaces/ITheme';

interface ButtonProps {
  label: string;
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  color?: 'gray';
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  style?: {};
  disabled?: boolean;
  onClick?: () => void;
}

const CustomButton = ({
  label,
  type,
  loading,
  color,
  variant,
  style,
  disabled,
  onClick,
}: ButtonProps) => {
  const buttonVariant = variant ? variant : 'contained';

  const buttonType = type ? type : 'button';

  const activeTheme = useTheme();

  const activePalette = activeTheme.palette as unknown as IPaletteOptions;

  let primaryColor = activePalette.primary_default;

  if (color && color === 'gray') {
    primaryColor = activePalette.gray;
  }

  let primaryHoverColor = activePalette.primary_hover;

  if (color && color === 'gray') {
    primaryHoverColor = activePalette.gray_hover;
  }

  const buttonStyles = {
    '&.MuiButton-root': {
      display: 'flex',
      boxShadow: '0 0 6px 0 rgb(0 0 0 / 35%)',
      fontSize: 16,
      border: '1px solid',
      borderRadius: '300px',
      lineHeight: 1.5,
      minWidth: '150px',
      minHeight: '50px',
      fontFamily: '"Open Sans", sans-serif',
    },
    '&.MuiButton-contained': {
      backgroundColor: primaryColor,
      borderColor: primaryColor,
      color: loading ? 'transparent' : `${activePalette.primary_text}`,
    },
    '&.MuiButton-outlined': {
      backgroundColor: 'transparent',
      borderColor: primaryColor,
      color: loading ? 'transparent' : primaryColor,
    },
    '&.MuiButton-text': {
      backgroundColor: 'transparent',
      borderColor: primaryColor,
      color: loading ? 'transparent' : primaryColor,
      border: '0px',
      boxShadow: 'none',
    },
    '&:hover': {
      backgroundColor: primaryHoverColor,
      borderColor: primaryHoverColor,
      boxShadow: 'none',
      color: `${activePalette.primary_text}`,
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: primaryColor,
      borderColor: primaryColor,
    },
    '& .MuiLoadingButton-loadingIndicator': {
      color:
        buttonVariant === 'contained'
          ? `${activePalette.primary_text}`
          : primaryColor,
    },
  };

  return (
    <LoadingButton
      type={buttonType}
      style={style}
      sx={buttonStyles}
      variant={buttonVariant}
      onClick={onClick && (() => onClick())}
      loading={loading}
      disabled={disabled}
    >
      {label}
    </LoadingButton>
  );
};

export default CustomButton;
