import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Swal from 'sweetalert2';
import { HidePassword, ShowPassword } from '../../../components/icons';
import Loading from '../../../components/loading/loading';
import CustomButton from '../../../components/shared/button/CustomButton';
import CustomModal from '../../../components/shared/dialog-modal/CustomModal';
import CustomRadio from '../../../components/shared/radio/CustomRadio';
import { FormInputGroup, Input } from '../../../containers/form/form';
import { NicInputWrap } from '../../../containers/signIn/stylesSignIn';
import {
  AppThemeContextType,
  useAppThemeContext,
} from '../../../context/appThemeContext';
import { IPaletteOptions } from '../../../interfaces/ITheme';
import RegistrationApiService from '../../../services/api/registration-api';
import { ThemeTypes } from '../../../utils/enums';
import {
  AgreementMsgDev,
  ValidateError,
  ValidateFormAction,
  ValidateFormWrapper,
} from './validateRegistrationStyles';

const IDENTIFIER_TYPE = '1';

interface ValidateRegistrationProps {
  show: boolean;
  onClose: () => void;
}

export const ValidateRegistrationModal = ({
  show,
  onClose,
}: ValidateRegistrationProps) => {
  const activePalette = useTheme().palette as unknown as IPaletteOptions;
  const { setTheme }: AppThemeContextType = useAppThemeContext();

  const { register, handleSubmit, errors, getValues, trigger, control } =
    useForm<{
      nic: string;
      confirmNic: string;
      otpOption: string;
    }>();

  const [isProcessing, setIsProcessing] = useState(false);
  const [isOpen, setIsOpen] = useState(show);
  const { addToast } = useToasts();
  let history = useHistory();
  const { t } = useTranslation();

  const [showNIC, setShowNIC] = useState(false);
  const [showAccountSwitch, setShowAccountSwitch] = useState(true);
  const [accountType, setAccountType] = useState('');
  const [isRevealNIC, setIsRevealNIC] = useState(false);
  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const closeModal = () => {
    if (onClose) {
      setShowAccountSwitch(true);
      setShowNIC(false);
      onClose();
    }
  };

  const validateNIC = async (data: { nic: string }) => {
    setIsProcessing(true);
    let response;
    setIsProcessing(true);
    try {
      response = await RegistrationApiService.identifierVerification(
        IDENTIFIER_TYPE,
        data.nic
      );

      if (response.kind === 'ok') {
        /**
         * Wallet Exists for identifier hence
         * will cancel the process.
         */
        if (response.data.isWalletExist) {
          Swal.fire({
            icon: 'error',
            title: t('wallet_already_exist'),
            confirmButtonColor: `${activePalette.primary_default}`,
          }).then(() => {
            window.location.reload();
          });

          return true;
        } else if (
          response.data.isLbAccountExist &&
          response.data.isSavingAccountExist
        ) {
          response.data.registrationTypeId = 13;
        } else if (
          response.data.isLbAccountExist &&
          !response.data.isSavingAccountExist
        ) {
          response.data.registrationTypeId = 13;
        } else {
          response.data.registrationTypeId = 13;
        }

        /**
         * Account type selection will be done in this
         * Check.
         *
         * Account Type
         *  "1" - Customer
         *  "2" - Merchant
         *
         * Registration Type ID will carry the account
         * type for the registration.
         */
        if (accountType === '1') {
          if (
            response.data.isLbAccountExist &&
            response.data.isSavingAccountExist
          ) {
            response.data.registrationTypeId = 14; // Existing customer flow
          } else if (
            response.data.isLbAccountExist &&
            !response.data.isSavingAccountExist
          ) {
            response.data.registrationTypeId = 15; // Existing customer / Non saving
          } else {
            response.data.registrationTypeId = 13; // New customer
          }
        } else if (accountType === '2') {
          if (
            response.data.isLbAccountExist &&
            response.data.isSavingAccountExist
          ) {
            response.data.registrationTypeId = 20; // Existing merchant flow
          } else if (
            response.data.isLbAccountExist &&
            !response.data.isSavingAccountExist
          ) {
            response.data.registrationTypeId = 21; // Existing merchant / Non saving
          } else {
            response.data.registrationTypeId = 19; // New merchant
          }
        }
        
        setShowAccountSwitch(true);
        setShowNIC(false);

        response.data.nic = data.nic;
        response.data.accountType = accountType;
        setIsProcessing(false);
        onClose();
        history.push({
          pathname: '/register',
          state: response.data,
        });
      }
    } catch (error: unknown) {
      setIsProcessing(false);
      setIsOpen(false);
      if (error instanceof Error) {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
    setIsProcessing(false);
  };

  return (
    <CustomModal
      id="validateRegistrationModal"
      open={isOpen}
      title={
        <>
          {showAccountSwitch && `${t('lb_services_accounttype')}`}
          {showNIC && t('identifier_verification')}
        </>
      }
      onClose={closeModal}
      maxWidth="xs"
      dialogContent={
        <>
          <ValidateFormWrapper style={{ maxWidth: '100%' }}>
            {showAccountSwitch && (
              <>
                <Controller
                  name="otpOption"
                  control={control}
                  defaultValue={undefined}
                  rules={{ required: true }}
                  render={({ name, onChange }) => (
                    <>
                      <div className="otp-option-item">
                        <CustomRadio
                          checked={getValues('otpOption') === '1'}
                          value="1"
                          label={t('cim_customer')}
                          name={name}
                          onChange={(e) => {
                            onChange(e);
                            setTheme!(ThemeTypes.CustomerTheme);
                          }}
                        />
                      </div>
                      <div className="otp-option-item">
                        <CustomRadio
                          checked={getValues('otpOption') === '2'}
                          value="2"
                          label={t('cim_merchant')}
                          name={name}
                          onChange={(e) => {
                            onChange(e);
                            setTheme!(ThemeTypes.MerchantTheme);
                          }}
                        />
                      </div>
                    </>
                  )}
                />
                {errors.otpOption && errors.otpOption.type === 'required' && (
                  <ValidateError>{t('error_empty_account_type')}</ValidateError>
                )}
                <ValidateFormAction>
                  <CustomButton
                    label={t('next')}
                    onClick={handleSubmit((data) => {
                      if (showAccountSwitch) {
                        setAccountType(data?.otpOption);
                        setShowAccountSwitch(false);
                        setShowNIC(true);
                      } else if (showNIC) {
                        validateNIC(data);
                      }
                    })}
                  />
                </ValidateFormAction>
              </>
            )}
            {showNIC && (
              <>
                <FormInputGroup>
                  <Input
                    type="password"
                    name="nic"
                    placeholder={t('nic_number')}
                    autoComplete="off"
                    onChange={async () => await trigger('confirmNic')}
                    ref={register({
                      required: true,
                      pattern: /^([0-9]{9}[x|X|v|V]|[0-9]{12}|[0-9]{15})$/m,
                      validate: (value) =>
                        value.toUpperCase() ===
                        getValues().confirmNic.toUpperCase(),
                    })}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                  {errors.nic && errors.nic.type === 'required' && (
                    <ValidateError>{t('error_empty_nic')}</ValidateError>
                  )}
                  {errors.nic && errors.nic.type === 'pattern' && (
                    <ValidateError>{t('error_invalid_nic')}</ValidateError>
                  )}
                </FormInputGroup>
                <NicInputWrap>
                  <Input
                    type={isRevealNIC ? 'text' : 'password'}
                    name="confirmNic"
                    placeholder={t('re_nic_number')}
                    autoComplete="off"
                    ref={register({
                      required: true,
                      pattern: /^([0-9]{9}[x|X|v|V]|[0-9]{12}|[0-9]{15})$/m,
                      validate: (value) =>
                        value.toUpperCase() === getValues().nic.toUpperCase(),
                    })}
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                  />
                  {isRevealNIC ? (
                    <HidePassword
                      onClick={() => setIsRevealNIC((prevState) => !prevState)}
                      className="show-hide-icon"
                    />
                  ) : (
                    <ShowPassword
                      onClick={() => setIsRevealNIC((prevState) => !prevState)}
                      className="show-hide-icon"
                    />
                  )}
                  {errors.confirmNic &&
                    errors.confirmNic.type === 'required' && (
                      <ValidateError>
                        {t('error_empty_confirmNic')}
                      </ValidateError>
                    )}
                  {errors.confirmNic &&
                    errors.confirmNic.type === 'pattern' && (
                      <ValidateError>
                        {t('error_invalid_confirmNic')}
                      </ValidateError>
                    )}
                  {errors.confirmNic &&
                    errors.confirmNic.type === 'validate' && (
                      <ValidateError>
                        {t('error_nic_doesnotmatch')}
                      </ValidateError>
                    )}
                </NicInputWrap>
              </>
            )}
          </ValidateFormWrapper>
          <AgreementMsgDev>
            {showNIC && t('identity_verify_agreement')}
          </AgreementMsgDev>
        </>
      }
      dialogActions={
        showNIC && (
          <>
            {isProcessing ? (
              <Loading />
            ) : (
              <>
                <CustomButton
                  label={t('identity_verify_agreement_agree')}
                  onClick={handleSubmit((data) => {
                    if (showAccountSwitch) {
                      setAccountType(data?.otpOption);
                      setShowAccountSwitch(false);
                      setShowNIC(true);
                    } else if (showNIC) {
                      setShowAccountSwitch(false);
                      validateNIC(data);
                    }
                  })}
                />
                <CustomButton
                  label={t('identity_verify_agreement_cancel')}
                  onClick={closeModal}
                />
              </>
            )}
          </>
        )
      }
    />
  );
};
