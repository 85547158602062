import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import tw from 'twin.macro';

export const SubHeaderWrapper = styled.div`
  ${tw`flex w-full justify-between items-center border-b border-gray-200 border-solid`};

  margin-top: ${(props) => props.theme.header.heightXs};
  color: #4a4a4a;
  padding: 8px 18px;
  height: 88px;

  ${breakpoint('sm')`
    margin-top: ${(props) => props.theme.header.heightSm};
  `}

  ${breakpoint('md')`
      margin-top: ${(props) => props.theme.header.heightMd};
      padding: 8px 32px;
  `}

  ${breakpoint('lg')`
      margin-top: ${(props) => props.theme.header.heightLg};
      padding: 8px 64px;
  `}

  > div {
    flex: 1;

    img {
      max-width: 80px;
    }

    .business {
      max-width: 100% !important;
      width: 125px !important;
    }
  }
`;

export const SubHeaderMiddleContent = styled.div`
  ${tw`text-center`};

  @media (max-width: 767px) {
    flex: 30% !important;
  }
`;
