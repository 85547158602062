import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    min-height: 100%;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400;
  }

  html {
    height: 100%;
  }

  body, #root {
    display: flex;
  }

  #root {
    display: flex;
    width: 100%;
    flex-direction: column;

    /* > div {
      flex-grow: 1;
    } */
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, select {
    font: 18px 'Open Sans', sans-serif; !important
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }
`;
