import { FormControlLabel, Radio, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { Ref } from 'react';
import { IPaletteOptions } from '../../../interfaces/ITheme';

interface RadioProps {
  checked: boolean;
  value: any;
  name?: string;
  fontStyle?: 'italic' | 'normal' | undefined;
  label?: string;
  labelPlacement?: 'top' | 'bottom' | 'start' | 'end' | undefined;
  disabled?: boolean;
  inputRef?: Ref<any>;
  style?: {};
  inline?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomRadio = ({
  checked,
  value,
  name,
  fontStyle,
  label,
  labelPlacement,
  disabled,
  inputRef,
  style,
  inline,
  onChange,
}: RadioProps) => {
  const labelPosition = labelPlacement ? labelPlacement : 'end';

  const fontFaceStyle = fontStyle ? fontStyle : 'normal';

  const activeTheme = useTheme();

  const activePalette = activeTheme.palette as unknown as IPaletteOptions;

  const formControlStyles = {
    '&.MuiFormControlLabel-root': {
      display: 'inline-flex',
    },
  };

  const labelStyles = {
    display: 'inline-block',
    fontSize: '1.125rem',
    fontStyle: `${fontFaceStyle}`,
    fontFamily: '"Open Sans", sans-serif',
    fontWeight: '400',
    margin: '0 0 0 0',
  };

  const radioStyles = {
    '&.MuiRadio-root': {
      color: `${activePalette.secondary_default}`,
      padding: 1,
    },
    '&.Mui-checked': {
      color: `${activePalette.primary_default}`,
    },
    '&.Mui-disabled': {
      color: `${activePalette.secondary_default}`,
    },
  };

  return (
    <FormControlLabel
      sx={formControlStyles}
      control={
        <Radio
          sx={radioStyles}
          checked={checked}
          onChange={onChange}
          value={value}
          name={name && name}
          inputRef={inputRef}
          style={style}
        />
      }
      disabled={disabled}
      label={<Typography style={labelStyles}>{label ?? ''}</Typography>}
      labelPlacement={labelPosition}
    />
  );
};

export default CustomRadio;
