import { create } from 'apisauce';
import { X_LANGUAGE } from '../../utils/constants';

import { getGeneralApiProblem } from './api-problem';
import { DEFAULT_API_CONFIG, API_VERSION } from './api.config';

class RegistrationApi {
  apisauce = null;
  config = null;
  token = '';

  constructor(config = DEFAULT_API_CONFIG) {
    this.config = config;
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: this.config.headers,
    });
  }

  setLanguage(lang) {
    this.apisauce.setHeader(X_LANGUAGE, lang ?? 'en');
  }

  errorMessageFilter(errorMessages) {
    if (errorMessages.length === 2) {
      return errorMessages[1];
    } else {
      return errorMessages[0];
    }
  }

  /**
   * Methos to verify the NIC is used previously in the
   * system.
   *
   * @param {string} identifierTypeId identifier type
   * @param {string} nic NIC Number
   */
  async identifierVerification(identifierTypeId, nic) {
    const response = await this.apisauce.get(
      `lbwallet/appapi/${API_VERSION}/registrations/identifiertypes/${identifierTypeId}/verifications/${nic}/web`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', data: response.data };
  }

  /**
   * Method to retrieve cities based on the selected
   * district.
   *
   * @param {string} districtId selected district id
   * @returns cities for selected district
   */
  async getCitiesForDistrict(districtId) {
    const response = await this.apisauce.get(
      `lbwallet/appapi/${API_VERSION}/masterdatas/districts/${districtId}/cities`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', data: response.data };
  }

  /**
   * Method to retrieve all branches list along with the
   * officers list.
   *
   * @returns all branches with oficcer list
   */
  async getBranches() {
    const response = await this.apisauce.get(
      `lbwallet/appapi/${API_VERSION}/masterdatas/lb/branches`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', data: response.data };
  }

  /**
   * Method to save registration information.
   *
   * @param {object} saveObject Registration object
   * @returns response
   */
  async saveRegistration(saveObject) {
    const response = await this.apisauce.put(
      `lbwallet/appapi/${API_VERSION}/registrations`,
      saveObject,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', data: response.data };
  }

  /**
   * Method to trigger OTP verification flow
   * @param {string} regId registration ID
   * @returns response
   */
  async sendOTPVerification(regId) {
    const response = await this.apisauce.put(
      `lbwallet/appapi/${API_VERSION}/registrations/${regId}/mobile_otps`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', data: response.data };
  }

  /**
   * This will verify the OTP code sent to mobile
   * while the registration process.
   *
   * @param {string} regId Registration ID
   * @param {string} otpId OTP ID
   * @param {string} otp OTP
   * @returns status
   */
  async verifyRegistrationOTP(regId, otpId, otp) {
    const response = await this.apisauce.get(
      `lbwallet/appapi/${API_VERSION}/registrations/${regId}/mobile_otps/${otpId}/otp/${otp}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', data: response.data };
  }

  /**
   * Method to confirm the registration of the user.
   *
   * @param {number} regId registration id
   * @param {object} pinObject pin
   * @returns response
   */
  async confirmRegistration(regId, pinObject) {
    const response = await this.apisauce.post(
      `lbwallet/appapi/${API_VERSION}/registrations/${regId}/confirmations`,
      pinObject,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', data: response.data };
  }

  /**
   * Method to perofm a verification transaction for registration
   * process.
   *
   * @param {string} regiId registrations ID
   * @returns response
   */
  async performAccountVerificationTransaction(regId) {
    const response = await this.apisauce.put(
      `lbwallet/appapi/${API_VERSION}/registrations/${regId}/accounts/DEFAULTS/verifications`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', data: response.data };
  }

  /**
   * Method to verify the verification transaction of a
   * registration process.
   *
   * @param {string} regId registration ID
   * @param {string} verificationId verification ID
   * @param {number} amount transaction amount
   * @returns response
   */
  async verifyAccountTransaction(regId, verificationId, amount) {
    const response = await this.apisauce.get(
      `lbwallet/appapi/${API_VERSION}/registrations/${regId}/accounts/DEFAULTS/verifications/${verificationId}/amount/${amount}`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return { kind: 'ok', data: response.data };
  }

  async getMerchantCategories() {
    const response = await this.apisauce.get(
      `lbwallet/appapi/${API_VERSION}/masterdatas/merchantcategorycodes`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    return { kind: 'ok', data: response.data };
  }

  async getTermsAndCondition() {
    const response = await this.apisauce.get(
      `lbwallet/appapi/${API_VERSION}/masterdatas/termsAndConditions`
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }
    return { kind: 'ok', data: response.data };
  }
}

export default new RegistrationApi();
