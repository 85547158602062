import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Steps } from 'rsuite';
//Stepper Styles
import 'rsuite/dist/styles/rsuite-default.css';
import Swal from 'sweetalert2';
import { BackButton } from '../../components/icons';
import OTPMethod from '../../components/loginOTP/OTPmethod';
import { useUser } from '../../context/user-context';
import RegistrationApiService from '../../services/api/registration-api';
import {
  RegisterFormContainer,
  RegisterFormTitle,
  RegisterSubHeader,
  RegisterSubHeaderMidContent,
  RegisterWrapper,
  RegistrationFormBack,
  RegistrationFormContent,
  RegistrationFormHeader,
  StepperComponent,
} from './registerStyles';
import {
  CIMRegistration,
  RegisterExistingCustomer,
  RegisterStepOne,
  RegisterStepThree,
  RegisterStepTwo,
} from './registration-steps';
import { VerifyTransactionModal } from './registration-steps/verifyTransactionModal';

const Register = (props) => {
  const { setUser } = useUser();
  const { addToast } = useToasts();
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [identifierInfo, setIdentifierInfo] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [otpId, setOtpId] = useState();
  const [regId, setRegId] = useState();
  const [verificationTranStatus, setVerificationTranStatus] = useState();
  const firstTime = useRef(true);
  const firstTime2 = useRef(true);
  const { t } = useTranslation();

  const [showOTPmethod, setShowOTPmethod] = useState(false);
  const [showOTPmethodExisitng, setShowOTPmethodExisiting] = useState(false);
  const [showTransactionConfirm, setShowTransactionConfirm] = useState(false);

  const { contactInfoDtos } = props.location.state;
  const title = props.location.state.title;

  const [merchantCategories, setMerchantCategories] = useState([]);

  // Assuming each contactInfoDto object has a contactTypeId of 1 for mobile number
  var mobileContact;
  if (contactInfoDtos?.length >= 1) {
    mobileContact = contactInfoDtos.find((dto) => dto.contactType === 'Mobile');
  }
  const mobile_no = mobileContact ? mobileContact.contact : '';

  // Assuming you need to extract the email address
  var emailContact;
  if (contactInfoDtos?.length >= 0) {
    emailContact = contactInfoDtos.find((dto) => dto.contactType === 'Email');
  }
  const email = emailContact ? emailContact.contact : '';

  let history = useHistory();

  const getMerchantCategories = useCallback(async () => {
    let response;
    try {
      response = await RegistrationApiService.getMerchantCategories();
      if (response.kind === 'ok') {
        setMerchantCategories(response.data.merchantCategoryCodeDtos);
      } else if (response.kind === 'rejected') {
        addToast(response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } catch (error) {
      setIsProcessing(false);
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast]);

  const getTermsAndCondition = useCallback(async () => {
    let response;
    try {
      response = await RegistrationApiService.getTermsAndCondition();
      if (response.kind === 'ok') {
        setTermsAndConditions(response.data);
      } else if (response.kind === 'rejected') {
        addToast(response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } catch (error) {
      setIsProcessing(false);
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [addToast]);

  useEffect(() => {
    if (firstTime.current) {
      firstTime.current = false;
      getTermsAndCondition();
    }
  }, [getTermsAndCondition]);

  useEffect(() => {
    if (firstTime2.current) {
      firstTime2.current = false;
      if (!props?.location?.state) {
        history.push('/');
      } else {
        if (
          parseInt(props.location.state.registrationTypeId) === 15 ||
          parseInt(props.location.state.registrationTypeId) === 21
        ) {
          let temp = {};
          temp.full_name = props.location.state.fullName;
          temp.nick_name = props.location.state.nickName;
          temp.address_home = props.location.state.houseNo;
          temp.street = `${props.location.state.streetRoad}`.replace(/%/g, ',');
          temp.district = props.location.state.district;
          temp.city = props.location.state.city;
          temp.title = props.location.state.title;
          temp.dob = props.location.state.dob;
          temp.email = email;
          temp.mobile_no = mobile_no;
          setFormData({ ...temp });
        }
        setIdentifierInfo(props.location.state);
      }
      getMerchantCategories();
    }
  }, [email, getMerchantCategories, history, mobile_no, props.location]);

  const setDateFormat = (data) => {
    /*format date*/
    const selectedDate = new Date(data.dob);
    let selectedMonth = selectedDate.getMonth() + 1;
    selectedMonth = selectedMonth < 10 ? '0' + selectedMonth : selectedMonth;
    let selectedDay = selectedDate.getDate();
    selectedDay = selectedDay < 10 ? '0' + selectedDay : selectedDay;

    data.dob = `${selectedDate.getFullYear()}-${selectedMonth}-${selectedDay}`;
  };

  const mergeFormData = (data) => {
    setFormData(data);
  };

  const submitForm = async (data) => {
    setIsProcessing(true);

    let response;
    let regData = buildRegistrationObject(data, identifierInfo);
    setDateFormat(regData.holderInfo);
    try {
      response = await RegistrationApiService.saveRegistration(regData);
      setUser(response.data);

      if (response.kind === 'ok') {
        setRegId(response.data.registrationId);
        let otpResponse = await RegistrationApiService.sendOTPVerification(
          response.data.registrationId
        );

        if (otpResponse.kind === 'ok') {
          setIsProcessing(false);
          setOtpId(otpResponse.data.otpId);
          setShowOTPmethod(true);
        } else if (response.kind === 'rejected') {
          setIsProcessing(false);
          if (response.data.message) {
            addToast(response.data.message, {
              appearance: 'error',
              autoDismiss: true,
            });
          } else {
            addToast('Invalid OTP', {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }
      } else if (response.kind === 'rejected') {
        setIsProcessing(false);
        if (response.data.message) {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        } else {
          addToast('Registration failed', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    } catch (error) {
      setIsProcessing(false);
      if (error.message) {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        addToast('Something went wrong', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };

  const submitExistingCustomer = async (data) => {
    setIsProcessing(true);

    let response;
    let regData = buildExistingCustomerRegObject(data, identifierInfo);

    try {
      response = await RegistrationApiService.saveRegistration(regData);

      if (response.kind === 'ok') {
        setRegId(response.data.registrationId);
        setUser(response.data);
        let otpResponse = await RegistrationApiService.sendOTPVerification(
          response.data.registrationId
        );

        if (otpResponse.kind === 'ok') {
          setIsProcessing(false);
          setOtpId(otpResponse.data.otpId);
          setShowOTPmethodExisiting(true);
        } else if (otpResponse.kind === 'rejected') {
          setIsProcessing(false);
          if (otpResponse.data.message) {
            addToast(otpResponse.data.message, {
              appearance: 'error',
              autoDismiss: true,
            });
          } else {
            addToast('Invalid OTP', {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }
      } else if (response.kind === 'rejected') {
        setIsProcessing(false);
        if (response.data.message) {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        } else {
          addToast('Registration failed', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    } catch (error) {
      setIsProcessing(false);
      if (error.message) {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        addToast('Something went wrong', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };

  const handleVerifyTransaction = async (transactionAmount) => {
    let response;
    try {
      setIsProcessing(true);
      response = await RegistrationApiService.verifyAccountTransaction(
        regId,
        verificationTranStatus.verificationId,
        transactionAmount.transaction
      );

      if (response.kind === 'ok') {
        let confirmResponse;
        confirmResponse = await RegistrationApiService.confirmRegistration(
          regId,
          {
            pin: formData?.password || -1,
          }
        );
        setShowOTPmethodExisiting(false);
        if (confirmResponse.kind === 'ok') {
          setIsProcessing(false);
          /* Swal.fire({
            icon: 'success',
            title: t('successful_registration'),
            text: t('successful_registration_content'),
            confirmButtonColor:
              identifierInfo.accountType === '1' ? '#E62C7B' : '#3db5e7',
          }).finally(() => {
            history.push('/');
          });*/
          addToast('Registerd successfully', {
            appearance: 'success',
            autoDismiss: true,
          }).finally(() => {
            history.push('/');
          });
        } else if (confirmResponse.kind === 'rejected') {
          setIsProcessing(false);
          addToast('Registration failed', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      } else if (response.kind === 'rejected') {
        setIsProcessing(false);
        if (response.data.message) {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        } else {
          addToast('Registration failed', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    } catch (error) {
      setIsProcessing(false);
      if (error.message) {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        addToast('Something went wrong', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };

  const handleExistingVerifyOTP = async (otpValue) => {
    let response;

    try {
      setIsProcessing(true);
      response = await RegistrationApiService.verifyRegistrationOTP(
        regId,
        otpId,
        otpValue
      );

      if (response.kind === 'ok') {
        setIsProcessing(false);
        setShowOTPmethodExisiting(false);
        let verificationTransaction =
          await RegistrationApiService.performAccountVerificationTransaction(
            regId
          );
        if (verificationTransaction.kind === 'ok') {
          setIsProcessing(false);
          setVerificationTranStatus(verificationTransaction.data);
          setShowTransactionConfirm(true);
        } else if (verificationTransaction.kind === 'rejected') {
          setIsProcessing(false);
          addToast(verificationTransaction.data.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      } else if (response.kind === 'rejected') {
        setIsProcessing(false);
        addToast(response.data.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    } catch (error) {
      setIsProcessing(false);
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const handleVerifyOTP = async (otpValue) => {
    let response;
    try {
      setIsProcessing(true);
      response = await RegistrationApiService.verifyRegistrationOTP(
        regId,
        otpId,
        otpValue
      );

      if (response.kind === 'ok') {
        setShowOTPmethod(false);
        setIsProcessing(false);
        let confirmResponse;
        confirmResponse = await RegistrationApiService.confirmRegistration(
          regId,
          {
            pin: formData?.password || -1,
          }
        );

        if (
          confirmResponse.kind === 'ok' &&
          confirmResponse?.data?.walletId !== null
        ) {
          setShowOTPmethod(false);
          Swal.fire({
            icon: 'success',
            title: t('successful_registration'),
            text: t('successful_registration_content'),
            confirmButtonColor:
              identifierInfo.accountType === '1' ? '#E62C7B' : '#3db5e7',
          }).finally(() => {
            history.push('/');
          });
        } else {
          setShowOTPmethod(false);
          if (confirmResponse.data.message) {
            addToast(confirmResponse.data.message, {
              appearance: 'error',
              autoDismiss: true,
            });
          } else {
            addToast('Registration Failed', {
              appearance: 'error',
              autoDismiss: true,
            });
          }
        }
      } else if (response.kind === 'rejected') {
        setIsProcessing(false);
        //setShowOTPmethod(false);
        setShowOTPmethod(true);
        if (response.data.message) {
          addToast(response.data.message, {
            appearance: 'error',
            autoDismiss: true,
          });
        } else {
          addToast('Registration Failed', {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    } catch (error) {
      setIsProcessing(false);
      if (error.message) {
        addToast(error.message, {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        addToast('Something went wrong', {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    }
  };

  /**
   * Util function to build exisiting customer registration
   * request object.
   *
   * @param {object} formData form data
   * @param {object} identifierInfo identifier information
   * @returns Object for posting
   */
  const buildExistingCustomerRegObject = (formData, identifierInfo) => {
    let regObject = {};
    let holderInfo = {};

    regObject.deviceIdentifier = null;
    regObject.registrationTypeId = identifierInfo.registrationTypeId;

    holderInfo.mothers_maiden_name = formData?.mothers_maiden_name || null;
    holderInfo.livingCity = formData.livingCity;
    holderInfo.city = formData.city;
    holderInfo.identifier = identifierInfo.nic;
    holderInfo.nick_name = formData.nick_name;
    holderInfo.identifier_type = '1';
    holderInfo.email = formData.email;
    holderInfo.mobile_no = formData.mobile_no;
    holderInfo.marketing_officer = formData.officer;

    if (
      parseInt(identifierInfo.registrationTypeId) === 19 ||
      parseInt(identifierInfo.registrationTypeId) === 20 ||
      parseInt(identifierInfo.registrationTypeId) === 21
    ) {
      holderInfo.merchant_category_code = formData.merchant_category_code;
      holderInfo.merchant_category_reference =
        formData.merchant_category_reference;
      holderInfo.lat = formData.lat;
      holderInfo.lng = formData.lng;
    }

    regObject.holderInfo = holderInfo;
    regObject.introducerCode = formData.introducerCode;
    regObject.introducerCodeType = formData.introducerCodeType;

    return regObject;
  };

  const buildRegistrationObject = (formData, identifierInfo) => {
    let regObject = {};
    let holderInfo = {};

    regObject.deviceIdentifier = null;
    regObject.registrationTypeId = identifierInfo.registrationTypeId;

    holderInfo.title = formData.title;
    holderInfo.monthly_income = formData.monthly_income;
    holderInfo.dob = formData.dob;
    holderInfo.mothers_maiden_name = formData?.mothers_maiden_name || null;
    holderInfo.source_of_fund = formData.source_of_funds;
    holderInfo.exp_turn_over = formData.exp_turn_over;
    holderInfo.civil_status = formData.civil_status;
    holderInfo.preferred_language = '1';
    holderInfo.livingCity = formData.livingCity
      ? formData.livingCity
      : formData.city;
    holderInfo.full_name = formData.full_name;
    holderInfo.city = formData.city;
    holderInfo.nationality = formData.nationality;
    holderInfo.other_info = formData.other_info;
    holderInfo.occupation = formData.occupation;
    holderInfo.branch_code = formData.branch_code;
    holderInfo.purpose = formData.purpose;
    holderInfo.identifier = identifierInfo.nic;
    holderInfo.nick_name = formData.nick_name;
    holderInfo.identifier_type = '1';
    holderInfo.email = formData.email;
    // binding missing spouse info to the registration api
    holderInfo.spouse_name = formData.spouse_name;
    holderInfo.spouse_nic = formData.spouse_nic;
    holderInfo.spouse_occupation = formData.spouse_occupation
      ? formData.spouse_occupation
      : null;
    holderInfo.marketing_officer = formData.officer;
    holderInfo.mobile_no = formData.mobile_no;
    holderInfo.home_no = formData.home_no;
    holderInfo.pep = formData.pep === 'true' ? true : false;
    holderInfo.address = `${formData.address_home} ${formData.street}`;
    holderInfo.nic_front_image = formData.nic_front_image.replace(
      /^data:image\/[a-z]+;base64,/,
      ''
    );
    holderInfo.nic_back_image = formData.nic_back_image.replace(
      /^data:image\/[a-z]+;base64,/,
      ''
    );
    holderInfo.selfie = formData.selfie.replace(
      /^data:image\/[a-z]+;base64,/,
      ''
    );
    holderInfo.selfie_image_type = formData.selfie_image_type;
    holderInfo.nic_back_image_type = formData.nic_back_image_type;
    holderInfo.nic_front_image_type = formData.nic_front_image_type;

    if (
      parseInt(identifierInfo.registrationTypeId) === 19 ||
      parseInt(identifierInfo.registrationTypeId) === 20 ||
      parseInt(identifierInfo.registrationTypeId) === 21
    ) {
      holderInfo.merchant_category_code = formData.merchant_category_code;
      holderInfo.merchant_category_reference =
        formData.merchant_category_reference;
      holderInfo.lat = formData.lat;
      holderInfo.lng = formData.lng;
    }

    regObject.holderInfo = holderInfo;

    regObject.introducerCode = formData.introducerCode;
    regObject.introducerCodeType = formData.introducerCodeType;

    return regObject;
  };

  return (
    <>
      <Helmet>
        <title>CIM - LB Finance</title>
        <meta
          name="description"
          content="Experience our service with the web portal to make it even easier and safer for you to manage your day to day transactions anytime anywhere in a simple way."
        />
        <link
          rel="icon"
          type="image/png"
          href="../../assets/images/CIM-e-connect-logo.png"
        />
      </Helmet>

      <RegisterWrapper>
        <RegisterSubHeader>
          <RegisterSubHeaderMidContent>
            {parseInt(identifierInfo.registrationTypeId) === 19 ||
            parseInt(identifierInfo.registrationTypeId) === 20 ||
            parseInt(identifierInfo.registrationTypeId) === 21 ? (
              <img src="../../assets/images/CIM-Businees-logo.svg" alt="" />
            ) : (
              <img src="../../assets/images/CIM-e-connect-logo.png" alt="" />
            )}
          </RegisterSubHeaderMidContent>
        </RegisterSubHeader>
        <RegisterFormContainer>
          <RegistrationFormHeader>
            <RegistrationFormBack
              onClick={() => {
                switch (step) {
                  case 2:
                    setStep(1);
                    break;
                  case 1:
                    setStep(0);
                    break;
                  case 0:
                    history.push('/');
                    break;
                  default:
                    break;
                }
              }}
            >
              <BackButton width={'24px'} height={'24px'} />
            </RegistrationFormBack>
            <RegisterFormTitle
              colorTheme={
                identifierInfo.accountType === '1'
                  ? 'themePrimary'
                  : 'themeSecondary'
              }
            >
              <h1>
                {identifierInfo.accountType === '1'
                  ? t('title_customer_registration')
                  : t('title_merchant_registration')}
              </h1>
            </RegisterFormTitle>
          </RegistrationFormHeader>
          {(parseInt(identifierInfo.registrationTypeId) === 14 ||
            parseInt(identifierInfo.registrationTypeId) === 20) && (
            <RegisterExistingCustomer
              colorTheme={
                identifierInfo.accountType === '1'
                  ? 'themePrimary'
                  : 'themeSecondary'
              }
              formData={formData}
              setFormData={mergeFormData}
              setStep={setStep}
              submitData={submitExistingCustomer}
              userType={identifierInfo.registrationTypeId}
              registrationType={identifierInfo.registrationTypeId}
              merchantCategories={merchantCategories}
              terms={termsAndConditions}
            />
          )}
          {!(
            parseInt(identifierInfo.registrationTypeId) === 14 ||
            parseInt(identifierInfo.registrationTypeId) === 20
          ) && (
            <>
              <RegistrationFormContent>
                {step !== 3 && (
                  <StepperComponent
                    colorTheme={
                      identifierInfo.accountType === '1'
                        ? 'themePrimary'
                        : 'themeSecondary'
                    }
                  >
                    <Steps current={step}>
                      <Steps.Item />
                      <Steps.Item />
                      <Steps.Item />
                    </Steps>
                  </StepperComponent>
                )}
              </RegistrationFormContent>
              {step === 0 && (
                <RegisterStepOne
                  colorTheme={
                    identifierInfo.accountType === '1'
                      ? 'themePrimary'
                      : 'themeSecondary'
                  }
                  formData={formData}
                  setFormData={mergeFormData}
                  setStep={(data) => {
                    setStep(data);
                  }}
                  registrationType={identifierInfo.registrationTypeId}
                  merchantCategories={merchantCategories}
                  userType={identifierInfo.registrationTypeId}
                />
              )}
              {step === 1 && (
                <RegisterStepTwo
                  colorTheme={
                    identifierInfo.accountType === '1'
                      ? 'themePrimary'
                      : 'themeSecondary'
                  }
                  formData={formData}
                  setFormData={mergeFormData}
                  setStep={setStep}
                  merchantCategories={merchantCategories}
                  userType={identifierInfo.registrationTypeId}
                />
              )}
              {step === 2 && (
                <RegisterStepThree
                  colorTheme={
                    identifierInfo.accountType === '1'
                      ? 'themePrimary'
                      : 'themeSecondary'
                  }
                  formData={formData}
                  setFormData={mergeFormData}
                  setStep={setStep}
                  terms={termsAndConditions}
                  submitData={submitForm}
                  processing={isProcessing}
                  merchantCategories={merchantCategories}
                  userType={identifierInfo.registrationTypeId}
                />
              )}
              {step === 3 && (
                <CIMRegistration
                  colorTheme={
                    identifierInfo.accountType === '1'
                      ? 'themePrimary'
                      : 'themeSecondary'
                  }
                  formData={formData}
                  setFormData={mergeFormData}
                  setStep={setStep}
                  terms={termsAndConditions}
                  submitData={submitForm}
                  processing={isProcessing}
                  merchantCategories={merchantCategories}
                  userType={identifierInfo.registrationTypeId}
                />
              )}
            </>
          )}
        </RegisterFormContainer>
      </RegisterWrapper>
      {showOTPmethod && (
        <OTPMethod
          isProcessing={isProcessing}
          show={showOTPmethod}
          registrationConfirmation={true}
          handleOTP={handleVerifyOTP}
          otpNumber={formData.mobile_no}
          onClose={() => {
            setShowOTPmethod(false);
            setIsProcessing(false);
          }}
        />
      )}
      {(showOTPmethodExisitng || !isProcessing) && (
        <OTPMethod
          isProcessing={isProcessing}
          show={showOTPmethodExisitng}
          registrationConfirmation
          handleOTP={handleExistingVerifyOTP}
          otpNumber={formData.mobile_no}
          onClose={() => {
            setShowOTPmethodExisiting(false);
            setIsProcessing(false);
          }}
        />
      )}
      {showTransactionConfirm && (
        <VerifyTransactionModal
          colorTheme={
            identifierInfo.accountType === '1'
              ? 'themePrimary'
              : 'themeSecondary'
          }
          isProcessing={isProcessing}
          show={showTransactionConfirm}
          onSubmit={handleVerifyTransaction}
          onClose={() => {
            setShowTransactionConfirm(false);
          }}
        />
      )}
    </>
  );
};

export default Register;
