export enum ThemeTypes {
  CustomerTheme = 'CUSTOMER_THEME',
  MerchantTheme = 'MERCHANT_THEME',
}

export enum MobileLoginActionTypes {
  RequestLoan = 'REQ_LOAN',
  WesternUnion = 'WU',
  TopUpLoan = 'TOPUP_LOAN',
  FIXED_DEPOSIT = 'FIXED_DEPOSIT',
  FIXED_DEPOSIT_LISTING = 'FIXED_DEPOSIT_LISTING',
  CUSTOMER_LIMIT_REQUEST = 'CUSTOMER_LIMIT_REQUEST',
  FIXED_DEPOSIT_DETAILS = 'FIXED_DEPOSIT_DETAILS',
  FIXED_DEPOSIT_WITHDRAW = 'FIXED_DEPOSIT_WITHDRAW',
  FIXED_DEPOSIT_TOPUP = 'FIXED_DEPOSIT_TOPUP',
  FIXED_DEPOSIT_EDIT = 'FIXED_DEPOSIT_EDIT',
  FIXED_DEPOSIT_LOAN = 'FIXED_DEPOSIT_LOAN',
  FIXED_DEPOSIT_CERTIFICATE = 'FIXED_DEPOSIT_CERTIFICATE',
}
export enum LimitRequestModes {
  Permanent = 'permanent',
  OneTime = 'one-time',
}
