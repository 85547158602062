import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import tw from 'twin.macro';

import { Link } from 'react-router-dom';

export const HeaderWrapper = styled.div`
  ${tw`flex items-start flex-wrap justify-between transition-all duration-300 ease-in-out`}
  background: ${(props) => props.theme.colorsBrand.primary};
  height: ${(props) => props.theme.header.heightXs};
  box-shadow: 0px 2px 3px 0 rgb(0 0 0 / 30%);
  width: 100%;
  position: fixed;
  top: 0;
  padding: 18px 18px;
  z-index: 10;

  ${breakpoint('sm')`
    height: ${(props) => props.theme.header.heightSm};
  `}

  ${breakpoint('md')`
    height: ${(props) => props.theme.header.heightMd};
    padding: 20px 32px;
`}

${breakpoint('lg')`
    height: ${(props) => props.theme.header.heightLg} !important;
    padding: 26px 64px;
`}

  &.active {
    ${tw`h-screen lg:h-auto overflow-y-auto lg:overflow-hidden`}

    + .PageContentWrapper {
      ${tw`flex h-screen lg:h-auto overflow-hidden lg:overflow-auto`};
    }
  }
`;

export const Logo = styled.div`
  max-width: 107px;
  display: block;

  ${breakpoint('md')`
  max-width: 135px;
  `}

  ${breakpoint('lg')`
  max-width: 175px;
  `}

  img {
    ${tw`w-full`};
  }
`;

export const HeaderRight = styled.div`
  ${tw`relative hidden lg:flex`};

  > div {
    ${tw`flex relative items-center`};
    margin-left: 36px;
    margin-right: 36px;

    &:after {
      content: '';
      height: 30px;
      ${tw`absolute xl:border-0 xl:border-r-2 border-gray-100 border-solid divide-opacity-25`};
      right: -36px;
      opacity: 0.4;
      border-width: 1px;
    }
  }

  > a {
    margin-left: 36px;
  }
`;

export const Hotline = styled.div`
  ${tw`text-white`}

  a {
    ${tw`text-white`}
  }
`;

export const RegiterLink = styled.div`
  ${tw`text-white cursor-pointer`}

  a {
    ${tw`text-white`}
  }
`;

export const Logout = styled(Link)`
  ${tw`flex text-white cursor-pointer relative`};

  &:before {
    ${tw`block w-5 h-5 mr-2 transition duration-200 ease-in-out`};
    content: '';
    background: url('../../../assets/images/logout-icon.png') no-repeat center;
  }

  &:hover {
    &:after {
      transform: rotateZ(180deg);
    }
  }
`;

export const MobileMenu = styled.div`
  ${tw`flex lg:hidden items-center cursor-pointer absolute transition-all duration-200 ease-in-out`};
  right: 16px;
  top: 13px;

  &.active {
    ${tw`rotate-90`};
  }

  svg {
    ${tw`w-6 h-6`};
  }
`;

export const MobileHeaderDropdown = styled.div`
  ${tw`flex relative hidden w-full opacity-0 transition-all duration-1000 ease-in-out delay-1000`};

  /* Mobile menu activate styles */
  ${HeaderWrapper}.active & {
    /* top: 60px; */
    ${tw`flex relative pt-4 opacity-100 lg:relative lg:hidden flex-col lg:flex-row`}
  }
`;

export const MobileMenuBottom = styled.div`
  ${tw`flex flex-wrap relative w-full justify-center`}

  > a {
    ${tw`flex w-full justify-center p-4 mt-4`}
  }

  > div {
    ${tw`flex  w-full justify-center p-3`}

    img {
      ${tw`px-4`}
    }
  }
`;
