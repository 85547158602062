import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { LoginOTPFormWrap } from '../../containers/signIn/stylesSignIn';
import { useUser } from '../../context/user-context';
import { IApiResponse } from '../../interfaces/IApiResponse';
import ApiService from '../../services/api/api';
import CustomButton from '../shared/button/CustomButton';
import CustomModal from '../shared/dialog-modal/CustomModal';
import CustomRadio from '../shared/radio/CustomRadio';
import EnterOTP from './enterOTP';

interface OtpMethodProps {
  show: boolean;
  onClose: () => void;
  registrationConfirmation?: boolean;
  isProcessing?: boolean;
  otpNumber?: string;
  handleOTP?: (val: string) => void;
}

const OTPMethod = ({
  show,
  onClose,
  registrationConfirmation,
  isProcessing,
  otpNumber,
  handleOTP,
}: OtpMethodProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control, getValues } = useForm();
  const [isOpen, setIsOpen] = useState(show);
  const { user } = useUser();
  const { addToast } = useToasts();
  const [showEnterOTP, setShowEnterOTP] = useState(true);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [otpLabel, setOtpLabel] = useState('');
  const [unVerifiedEmail, setUnVerifiedEmail] = useState(false);
  const [selectedOTPmethod, setSelectedOTPmethod] = useState<string>();
  const [selectedAnMethod, setSelectedAnMethod] = useState(false);

  function closeModal() {
    onClose();
    setShowVerifyOTP(false);
    setShowEnterOTP(true);
    setSelectedAnMethod(false);
  }

  useEffect(() => {
    setIsOpen(show);
  }, [show]);

  const optMethodSubmit = async (data: { otpOption: string }) => {
    setSelectedOTPmethod(data.otpOption);

    try {
      if (/.+@.+\.[A-Za-z]+$/.test(data.otpOption)) {
        //if user selects email

        if (user?.emailVerified) {
          //verified email

          const emailResponse: IApiResponse = await ApiService.sendOTP(
            user.walletAccountNo,
            '2',
            data.otpOption
          );

          if (emailResponse.kind === 'ok') {
            setOtpLabel('login_otp_email');
            setShowVerifyOTP(true);
            setShowEnterOTP(false);
          } else {
            setShowVerifyOTP(false);
            addToast(
              emailResponse.data?.message ||
                emailResponse.problem ||
                'Timeout Error, Try again later',
              {
                appearance: 'error',
                autoDismiss: true,
              }
            );
          }
        } else {
          //un-verified email*

          const emailResponse: IApiResponse = await ApiService.sendOTP(
            user?.walletAccountNo,
            '1',
            user?.mobile
          );

          if (emailResponse.kind === 'ok') {
            setUnVerifiedEmail(true);
            setOtpLabel('login_otp_mobile');
            setShowVerifyOTP(true);
            setShowEnterOTP(false);
          } else {
            setShowVerifyOTP(false);
            addToast(
              emailResponse.data?.message ||
                emailResponse.problem ||
                'Timeout Error, Try again later',
              {
                appearance: 'error',
              }
            );
          }
        }
      } else {
        //user selects phone number
        setUnVerifiedEmail(false);

        const mobileResponse: IApiResponse = await ApiService.sendOTP(
          user?.walletAccountNo,
          '1',
          data.otpOption
        );

        if (mobileResponse.kind === 'ok') {
          setOtpLabel('login_otp_mobile');
          setShowVerifyOTP(true);
          setShowEnterOTP(false);
        } else {
          setShowVerifyOTP(false);
          addToast(mobileResponse.data?.message || mobileResponse.problem, {
            appearance: 'error',
          });
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error)
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        });
    } finally {
    }
  };

  return (
    <CustomModal
      id="otpMethod"
      open={isOpen && !isProcessing}
      onClose={closeModal}
      maxWidth="xs"
      title={showEnterOTP && t('receive_login_otp_to')}
      dialogContent={
        <>
          {registrationConfirmation && handleOTP && otpNumber && (
            <LoginOTPFormWrap>
              <EnterOTP
                OtpText={otpLabel ? t(otpLabel) : ''}
                registrationConfirmation
                selectedOTPmethod={otpNumber}
                handleVerifyOTP={handleOTP}
              />
            </LoginOTPFormWrap>
          )}
          {!registrationConfirmation && (
            <LoginOTPFormWrap>
              {showEnterOTP && (
                <Controller
                  name="otpOption"
                  control={control}
                  defaultValue={''}
                  render={({ name, onChange }) => (
                    <>
                      {user?.mobile && (
                        <div className="otp-option-item">
                          <CustomRadio
                            checked={getValues('otpOption') === user.mobile}
                            label={user.maskMobile}
                            name={name}
                            value={user.mobile}
                            onChange={(e) => {
                              onChange(e);
                              setSelectedAnMethod(true);
                            }}
                          />
                        </div>
                      )}
                      {user?.email && (
                        <div className="otp-option-item">
                          <CustomRadio
                            checked={getValues('otpOption') === user.email}
                            name={name}
                            label={user.maskEmail}
                            value={user.email}
                            onChange={(e) => {
                              onChange(e);
                              setSelectedAnMethod(true);
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                />
              )}
              {showVerifyOTP && selectedOTPmethod && (
                <EnterOTP
                  OtpText={otpLabel ? t(otpLabel) : ''}
                  unVerifiedEmail={unVerifiedEmail}
                  userEmail={user?.email}
                  selectedOTPmethod={selectedOTPmethod}
                />
              )}
            </LoginOTPFormWrap>
          )}
        </>
      }
      dialogActions={
        showEnterOTP &&
        selectedAnMethod && (
          <CustomButton
            loading={isProcessing}
            label={t('next', { defaultValue: 'Next' })}
            onClick={handleSubmit((data: { otpOption: string }) =>
              optMethodSubmit(data)
            )}
          />
        )
      }
    />
  );
};

export default OTPMethod;
