import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import tw from 'twin.macro';
import { LoginFormError } from '../../../containers/form/form';

export const ValidateFormWrapper = styled.form`
  max-width: 300px;
  margin: 0 auto;
  width: 100%;
  padding: 1em 1em 0em 1em;
  justify-content: center;

  .otp-option-item {
    background-color: #efefef;
    ${tw`mb-6 p-3`}

    :last-child {
      ${tw`mb-0`}
    }

    label {
      ${tw`flex items-center cursor-pointer w-full`}
    }

    input[type='radio'] {
      ${tw`mr-2 p-0`}
    }
  }

  ${breakpoint('md')`
    padding: 1em 1em 0em 1em;
  `}

  ${breakpoint('lg')`
    padding: 1em 1em 0em 1em;
  `}
`;

export const ValidateError = styled(LoginFormError)`
  width: 100%;
`;

export const ValidateFormAction = styled.div`
  ${tw`flex items-center justify-center p-6`}

  > svg {
    margin-left: auto !important;
  }
`;

export const ValidationFormHeader = styled.h3`
  font-size: 18px;
`;

export const AgreementDev = styled.div`
  ${tw`flex items-center justify-center`}

  > svg {
    margin-left: auto !important;
  }
`;

export const AgreementMsgDev = styled.div`
  ${tw`flex items-center justify-center pt-3 text-base px-5 text-justify max-w-md`}

  > svg {
    margin-left: auto !important;
  }
`;

export const Row1Col2 = styled.div`
  ${tw`flex justify-between`}

  > svg {
    margin-left: auto !important;
  }
`;
