import React from 'react';
import { useUser } from '../../context/user-context';
import UserProfile from '../headerUserProfile/headerUserProfile';
import { SubHeaderMiddleContent, SubHeaderWrapper } from './subHeaderStyle';

interface ISubHeaderProps {
  showProfile?: boolean;
}

const SubHeader = ({ showProfile = true }: ISubHeaderProps) => {
  const { user } = useUser();

  return (
    <SubHeaderWrapper>
      <div className="md:block"></div>
      <SubHeaderMiddleContent>
        {user?.accountType === 2 ? (
          <img src="../../assets/images/CIM-e-connect-logo.svg" alt="" />
        ) : (
          <img
            className="business"
            src="../../assets/images/CIM-Businees-logo.svg"
            alt=""
          />
        )}
      </SubHeaderMiddleContent>
      <UserProfile fetchProfile={showProfile} />
    </SubHeaderWrapper>
  );
};

export default SubHeader;
