import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/loading/loading';
import CustomButton from '../../../components/shared/button/CustomButton';
import CustomModal from '../../../components/shared/dialog-modal/CustomModal';
import {
  Button,
  Input,
  LoginFormError,
  OutlinedButton,
  SubmitButton,
} from '../../../containers/form/form';
import { Marital, Occupation } from '../../../dataset';
import RegistrationApiService from '../../../services/api/registration-api';
import {
  RegisterFormInputGroup,
  RegisterFormWrapper,
  RegistrationDropDown,
  StepComponent,
} from '../registerStyles';
import {
  RegCIMDialog,
  RegisterFormSection,
  RegistrationStepAction,
  RegSpinnerWrapper,
} from './registrationStrepStyles';
import { NextButton, SaveButton } from '../../RequestLoan/LoanRequestStyle';

export const RegisterStepThree = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [lastWatch, setLastWatch] = useState({});
  const firstTime = useRef(true);
  const { t } = useTranslation();
  const { register, errors, reset, handleSubmit, trigger, watch } = useForm({
    mode: 'all',
    reValidateMode: 'all',
  });

  const activePalette = useTheme().palette;

  const {
    formData,
    setFormData,
    setStep,
    submitData,
    processing,
    merchantCategories,
    userType,
  } = props;

  const [maritalStatus, setMaritalStatus] = useState('S');
  const [branches, setBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [officerList, setOfficerList] = useState();
  const [showAdditionalInfo, setShowAdditionalInfo] = useState(false);
  const [isAcceptTnC, setIsAcceptTnC] = useState(false);
  const [introduceCode, setIntroduceCode] = useState(0);
  const [merchant_category, setMerchantCategory] = useState(
    formData.merchant_category_code
  );

  useEffect(() => {
    const selectedBranchDetail = branches.filter(
      (branch) => branch.branchCode === selectedBranch
    );

    if (selectedBranchDetail.length > 0) {
      setOfficerList(selectedBranchDetail[0].lbMarketingOfficerList);
    }
  }, [branches, selectedBranch]);

  const generateMobile = (mobileNum) => {
    if (mobileNum) {
      return `+94${mobileNum.substring(1)}`;
    }
  };
  const reConvertMobile = (mobileNum) => {
    if (mobileNum && mobileNum.length === 12) {
      return `0${mobileNum.slice(3)}`;
    } else {
      return mobileNum;
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const setDefault = async () => {
      const temp = { ...formData };
      if (temp?.pep) {
        setShowAdditionalInfo(true);
      }
      if (temp?.civil_status && temp?.civil_status === '1') {
        setMaritalStatus(temp?.civil_status);
      }

      const res = await RegistrationApiService.getBranches();
      if (res.kind === 'ok') {
        setBranches(res.data.lbBranchDtoList);
      }

      if (temp?.branch_code) {
        setSelectedBranch(temp?.branch_code);
        const selectedBranchDetail = res.data.lbBranchDtoList.filter(
          (branch) => branch.branchCode === temp?.branch_code
        );

        if (selectedBranchDetail.length > 0) {
          setOfficerList(selectedBranchDetail[0].lbMarketingOfficerList);
        }
      }
      if (temp.home_no) {
        temp.home_no = reConvertMobile(temp.home_no);
      }
      reset(temp);
    };
    if (firstTime.current) {
      firstTime.current = false;
      setDefault();
    }
  });

  useEffect(() => {
    const currentWatch = watch();
    if (JSON.stringify(lastWatch) !== JSON.stringify(currentWatch)) {
      setLastWatch({ ...currentWatch });
      setFormData({ ...formData, ...watch() });
    }
  }, [formData, lastWatch, setFormData, watch]);

  return (
    <StepComponent>
      <RegisterFormWrapper
        autocomplete="off"
        onSubmit={handleSubmit((data) => {
          setIsOpen(true);
        })}
      >
        <p>{t('additional_info')}</p>
        <RegisterFormSection>
          <RegisterFormInputGroup>
            <label>{t('occupation')}</label>
            <RegistrationDropDown
              name="occupation"
              ref={register({
                required: t('error_empty_occupation'),
              })}
            >
              <option value="" />
              {Occupation.map((occupation) => (
                <option key={occupation.id} value={occupation.code}>
                  {occupation.description}
                </option>
              ))}
            </RegistrationDropDown>
            {errors['occupation'] && (
              <LoginFormError className="error">
                {' '}
                {t('error_empty_occupation')}{' '}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          <RegisterFormInputGroup>
            <label>{t('marital_status')}</label>
            <RegistrationDropDown
              name="civil_status"
              ref={register({
                required: t('error_empty_nationality'),
              })}
              onChange={(value) => {
                setMaritalStatus(value.target.value);
              }}
            >
              <option value="" />
              {Marital.map((marital) => (
                <option key={marital.id} value={marital.data}>
                  {marital.description}
                </option>
              ))}
            </RegistrationDropDown>
            {errors['civil_status'] && (
              <LoginFormError className="error">
                {' '}
                {t('error_empty_martal_status')}{' '}
              </LoginFormError>
            )}
          </RegisterFormInputGroup>
          {parseInt(maritalStatus) === 1 && (
            <>
              <RegisterFormInputGroup>
                <label>{t('spouse_name')}</label>
                <Input
                  type="text"
                  name="spouse_name"
                  autoComplete="off"
                  ref={register({
                    minLength: 2,
                    maxLength: 100,
                    pattern: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/m,
                    required: t('error_empty_spouse_name'),
                  })}
                />
                {errors['spouse_name'] &&
                  errors['spouse_name'].type === 'pattern' && (
                    <LoginFormError className="error">
                      {' '}
                      {'Invalid Input'}{' '}
                    </LoginFormError>
                  )}

                {errors['spouse_name'] &&
                  errors['spouse_name'].type !== 'pattern' && (
                    <LoginFormError className="error">
                      {' '}
                      {t('error_empty_spouse_name')}{' '}
                    </LoginFormError>
                  )}
              </RegisterFormInputGroup>
              <RegisterFormInputGroup>
                <label>{t('spouse_nic')}</label>
                <Input
                  type="text"
                  name="spouse_nic"
                  autoComplete="off"
                  // ref={register({
                  //   required: t('error_empty_spouse_nic'),
                  //   pattern: /^([0-9]{9}[x|X|v|V]|[0-9]{12}|[0-9]{15})$/m,
                  //   message: 'Alphanumeric , . - _ only',
                  // })}
                />
                {/* {errors['spouce_nic'] && (
                  <LoginFormError className="error">
                    {' '}
                    {t('error_empty_spouse_nic')}{' '}
                  </LoginFormError>
                )} */}
              </RegisterFormInputGroup>
              <RegisterFormInputGroup>
                <label>{t('spouse_occupation')}</label>
                <RegistrationDropDown
                  name="spouse_occupation"
                  ref={register({
                    required: t('error_empty_spouse_occupation'),
                  })}
                >
                  <option value="" />
                  {Occupation.map((occupation) => (
                    <option key={occupation.id} value={occupation.code}>
                      {occupation.description}
                    </option>
                  ))}
                </RegistrationDropDown>
                {errors['spouse_occupation'] && (
                  <LoginFormError className="error">
                    {' '}
                    {t('error_empty_spouse_occupation')}{' '}
                  </LoginFormError>
                )}
              </RegisterFormInputGroup>
            </>
          )}
          {(parseInt(userType) === 19 ||
            parseInt(userType) === 20 ||
            parseInt(userType) === 21) && (
            <>
              <RegisterFormInputGroup>
                <label>{t('merchant_category')}</label>
                <RegistrationDropDown
                  name="merchant_category_code"
                  ref={register({
                    required: t('error_empty_merchant_category'),
                  })}
                  onChange={(v) => {
                    setMerchantCategory(v.target.value);
                  }}
                >
                  <option value="" />
                  {merchantCategories.map((merchantObj) => (
                    <option key={merchantObj.code} value={merchantObj.code}>
                      {merchantObj.description}
                    </option>
                  ))}
                </RegistrationDropDown>

                {errors['city'] && (
                  <LoginFormError className="error">
                    {' '}
                    {t('error_empty_city')}{' '}
                  </LoginFormError>
                )}
              </RegisterFormInputGroup>
              {merchant_category > 0 && (
                <RegisterFormInputGroup>
                  <label>
                    {parseInt(merchant_category) === 738 ||
                    parseInt(merchant_category) === 739
                      ? t('merchant_category_referance_threewheel')
                      : t('merchant_category_referance')}
                  </label>
                  <Input
                    type="text"
                    name="merchant_category_reference"
                    autoComplete="off"
                    ref={register()}
                  />
                </RegisterFormInputGroup>
              )}
            </>
          )}
          <RegisterFormInputGroup>
            <label>{t('pep')}</label>
            <RegistrationDropDown name="pep" ref={register()}>
              <option value="false">{t('no')}</option>
              <option value="true">{t('yes')}</option>
            </RegistrationDropDown>
          </RegisterFormInputGroup>
        </RegisterFormSection>

        <p>
          {t('additional_info')} (Cont.){' '}
          {!showAdditionalInfo ? (
            <IconButton
              sx={{ color: activePalette.primary_default }}
              onClick={() => setShowAdditionalInfo(true)}
            >
              <AddCircleOutlinedIcon />
            </IconButton>
          ) : (
            <IconButton
              sx={{ color: activePalette.primary_default }}
              onClick={() => setShowAdditionalInfo(false)}
            >
              <RemoveCircleOutlinedIcon />
            </IconButton>
          )}
        </p>
        {showAdditionalInfo && (
          <RegisterFormSection>
            <RegisterFormInputGroup>
              <label>{t('home_tep')}</label>
              <Input
                type="text"
                name="home_no"
                placeholder="0XX XXXXXXX"
                autoComplete="off"
                ref={register({
                  required: false,
                  pattern: /^0\d{9}$/m,
                })}
              />
              {errors.home_no && errors.home_no.type === 'required' && (
                <LoginFormError className="error">
                  {t('error_empty_home_no')}
                </LoginFormError>
              )}
              {errors.home_no && errors.home_no.type === 'pattern' && (
                <LoginFormError className="error">
                  {t('error_invalid_home_no')}
                </LoginFormError>
              )}
            </RegisterFormInputGroup>
            <RegisterFormInputGroup>
              <label>{t('select_branch_name')}</label>
              <RegistrationDropDown
                name="branch_code"
                ref={register()}
                onChange={(event) => {
                  setSelectedBranch(event.target.value);
                }}
              >
                <option value="" />
                {branches &&
                  branches.map((branch) => (
                    <option key={branch.code} value={branch.branchCode}>
                      {branch.name}
                    </option>
                  ))}
              </RegistrationDropDown>
            </RegisterFormInputGroup>
            <RegisterFormInputGroup>
              <label>
                {t('introduce_office')} {selectedBranch ? '*' : ''}{' '}
              </label>
              <RegistrationDropDown
                name="officer"
                ref={register({
                  required: selectedBranch ? t('error_empty_title') : false,
                })}
              >
                <option value="" />
                {officerList &&
                  officerList.map((officer) => (
                    <option key={officer.userId} value={officer.userId}>
                      {officer.name}
                    </option>
                  ))}
              </RegistrationDropDown>
              {errors['officer'] && (
                <LoginFormError className="error">
                  {' '}
                  {'Please select an Introducing Officer'}{' '}
                </LoginFormError>
              )}
            </RegisterFormInputGroup>
            <RegisterFormInputGroup>
              <label>{t('introduce_code')}</label>
              <Input
                type="text"
                name="introducerCode"
                placeholder={parseInt(introduceCode) === 1 && '07XXXXXXXX'}
                autoComplete="off"
                onChange={async () => await trigger('introducerCode')}
                ref={register({
                  pattern: parseInt(introduceCode) === 1 && /^07\d{8}$/m,
                })}
              />
              {errors['introducerCode'] && parseInt(introduceCode) === 1 && (
                <LoginFormError className="error">
                  {' '}
                  {t('error_invalid_home_no')}{' '}
                </LoginFormError>
              )}
            </RegisterFormInputGroup>
          </RegisterFormSection>
        )}
        <RegisterFormSection>
          <RegistrationStepAction
            className={`push-right ${processing && 'processing'}`}
          >
            {processing && (
              <RegSpinnerWrapper>
                <Loading />
              </RegSpinnerWrapper>
            )}
            <SubmitButton
              colorTheme={props.colorTheme}
              type="submit"
              value={t('send')}
            />

            <Modal
              open={isOpen}
              onClose={() => setIsOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <RegCIMDialog>
                <h6>{t('cim_reg_dialog_content')}</h6>
                <div className="dialog-actions">
                  {/*<SubmitButton type="button">CIM Registration</SubmitButton>*/}

                  <NextButton
                    type="button"
                    onClick={handleSubmit((data) => {
                      if (parseInt(introduceCode) === 1) {
                        data.introducerCode = generateMobile(
                          data.introducerCode
                        );
                      }
                      data.home_no = generateMobile(data.home_no);
                      setFormData({ ...formData, ...data });
                      setStep(3);
                      setIsOpen(false);
                    })}
                  >
                    {t('cim_reg_btn')}
                  </NextButton>

                  <SaveButton
                    type="button"
                    onClick={handleSubmit((data) => {
                      if (parseInt(introduceCode) === 1) {
                        data.introducerCode = generateMobile(
                          data.introducerCode
                        );
                      }
                      console.log('info : ', data);
                      data.home_no = generateMobile(data.home_no);
                      setFormData({ ...formData, ...data });
                      submitData({ ...formData, ...data });
                      setIsOpen(false);
                    })}
                  >
                    {t('Done')}
                  </SaveButton>
                </div>
              </RegCIMDialog>
            </Modal>
          </RegistrationStepAction>
        </RegisterFormSection>
      </RegisterFormWrapper>
    </StepComponent>
  );
};
