export const Occupation = [
  {
    id: 620,
    code: '15',
    description: 'Accountancy Professionals',
    data: 15,
  },
  {
    id: 621,
    code: '59',
    description: 'Accountant',
    data: 59,
  },
  {
    id: 622,
    code: '60',
    description: 'Administrative employees',
    data: 60,
  },
  {
    id: 623,
    code: '16',
    description: 'Administrative Service',
    data: 16,
  },
  {
    id: 624,
    code: '17',
    description: 'Architect',
    data: 17,
  },
  {
    id: 625,
    code: '18',
    description: 'Artist',
    data: 18,
  },
  {
    id: 626,
    code: '19',
    description: 'Assistant Manager-Corporate',
    data: 19,
  },
  {
    id: 627,
    code: '73',
    description: 'Auctioneer ',
    data: 73,
  },
  {
    id: 628,
    code: '63',
    description: 'Auditor ',
    data: 63,
  },
  {
    id: 629,
    code: '64',
    description: 'Author',
    data: 64,
  },
  {
    id: 630,
    code: '58',
    description: 'Baker ',
    data: 58,
  },
  {
    id: 631,
    code: '08',
    description: 'Bank/Finance /Insurance Employee',
    data: 8,
  },
  {
    id: 632,
    code: '20',
    description: 'Beautician',
    data: 20,
  },
  {
    id: 633,
    code: '54',
    description: 'Broker ',
    data: 54,
  },
  {
    id: 634,
    code: '21',
    description: 'Businessman',
    data: 21,
  },
  {
    id: 635,
    code: '22',
    description: 'Carpenter',
    data: 22,
  },
  {
    id: 636,
    code: '66',
    description: 'Chef ',
    data: 66,
  },
  {
    id: 637,
    code: '56',
    description: 'Cleaner',
    data: 56,
  },
  {
    id: 638,
    code: '53',
    description: 'Contractor ',
    data: 53,
  },
  {
    id: 639,
    code: '23',
    description: 'Director-Private Sector',
    data: 23,
  },
  {
    id: 640,
    code: '24',
    description: 'Director-Public Sector',
    data: 24,
  },
  {
    id: 641,
    code: '25',
    description: 'Doctor',
    data: 25,
  },
  {
    id: 642,
    code: '26',
    description: 'Driver',
    data: 26,
  },
  {
    id: 643,
    code: '04',
    description: 'Education Sector Employee',
    data: 4,
  },
  {
    id: 644,
    code: '61',
    description: 'Electrician',
    data: 61,
  },
  {
    id: 645,
    code: '27',
    description: 'Engineer',
    data: 27,
  },
  {
    id: 646,
    code: '11',
    description: 'Estate Workers',
    data: 11,
  },
  {
    id: 647,
    code: '03',
    description: 'Factory worker',
    data: 3,
  },
  {
    id: 648,
    code: '28',
    description: 'Farmer',
    data: 28,
  },
  {
    id: 649,
    code: '10',
    description: 'Farmer/Fisherman',
    data: 10,
  },
  {
    id: 650,
    code: '68',
    description: 'Fashion designer',
    data: 68,
  },
  {
    id: 651,
    code: '29',
    description: 'Fisherman',
    data: 29,
  },
  {
    id: 652,
    code: '72',
    description: 'Goldsmith ',
    data: 72,
  },
  {
    id: 653,
    code: '71',
    description: 'Guide ',
    data: 71,
  },
  {
    id: 654,
    code: '30',
    description: 'Hair Dresser',
    data: 30,
  },
  {
    id: 655,
    code: '06',
    description: 'Health Sector Employee',
    data: 6,
  },
  {
    id: 656,
    code: '09',
    description: 'Hotel Sector Employee',
    data: 9,
  },
  {
    id: 657,
    code: '57',
    description: 'Housemaid',
    data: 57,
  },
  {
    id: 658,
    code: '31',
    description: 'IT Professional',
    data: 31,
  },
  {
    id: 659,
    code: '69',
    description: 'Jailer ',
    data: 69,
  },
  {
    id: 660,
    code: '32',
    description: 'Journalist',
    data: 32,
  },
  {
    id: 661,
    code: '33',
    description: 'Labourer',
    data: 33,
  },
  {
    id: 662,
    code: '34',
    description: 'Lawyer',
    data: 34,
  },
  {
    id: 663,
    code: '35',
    description: 'Lecturer',
    data: 35,
  },
  {
    id: 664,
    code: '36',
    description: 'Manager-Corporate',
    data: 36,
  },
  {
    id: 665,
    code: '37',
    description: 'Military Officer',
    data: 37,
  },
  {
    id: 666,
    code: '05',
    description: 'Military/Police',
    data: 5,
  },
  {
    id: 667,
    code: '52',
    description: 'Miner ',
    data: 52,
  },
  {
    id: 668,
    code: '70',
    description: 'Model ',
    data: 70,
  },
  {
    id: 669,
    code: '12',
    description: 'Non employee',
    data: 12,
  },
  {
    id: 670,
    code: '38',
    description: 'Nurse',
    data: 38,
  },
  {
    id: 671,
    code: '39',
    description: 'Office Assistants',
    data: 39,
  },
  {
    id: 672,
    code: '01',
    description: 'Other government Employee',
    data: 1,
  },
  {
    id: 673,
    code: '02',
    description: 'Other Private sector Employee',
    data: 2,
  },
  {
    id: 674,
    code: '40',
    description: 'Peons',
    data: 40,
  },
  {
    id: 675,
    code: '67',
    description: 'Plumber ',
    data: 67,
  },
  {
    id: 676,
    code: '41',
    description: 'Police Officer',
    data: 41,
  },
  {
    id: 677,
    code: '42',
    description: 'Politician',
    data: 42,
  },
  {
    id: 678,
    code: '43',
    description: 'Principal',
    data: 43,
  },
  {
    id: 679,
    code: '65',
    description: 'Scientist/chemist/psychologist',
    data: 65,
  },
  {
    id: 680,
    code: '55',
    description: 'Secretary ',
    data: 55,
  },
  {
    id: 681,
    code: '44',
    description: 'Security Officer-Private',
    data: 44,
  },
  {
    id: 682,
    code: '07',
    description: 'Self-employee',
    data: 7,
  },
  {
    id: 683,
    code: '45',
    description: 'Senior Manager-Corporate',
    data: 45,
  },
  {
    id: 684,
    code: '46',
    description: 'Shop-Owner',
    data: 46,
  },
  {
    id: 685,
    code: '51',
    description: 'Sportsman',
    data: 51,
  },
  {
    id: 686,
    code: '47',
    description: 'Tailor',
    data: 47,
  },
  {
    id: 687,
    code: '48',
    description: 'Teacher',
    data: 48,
  },
  {
    id: 688,
    code: '49',
    description: 'Technician & Mechanic',
    data: 49,
  },
  {
    id: 689,
    code: '50',
    description: 'Three Wheel Hirer',
    data: 50,
  },
  {
    id: 690,
    code: '62',
    description: 'Valuation officer',
    data: 62,
  },
];
